<template>
  <div>
    <v-card v-show="!pageLoading">
      <v-card-title>
        Search Team members
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pr-0">
        <v-divider></v-divider>
        <v-row class="mt-2">
          <v-col class="nameField pr-8" cols="12" md="3" sm="6">
            <TextField dense outlined class="mt-md-3" label="Teammember" type="text" hide-details v-model="teammemberName" />
          </v-col>
          <v-col cols="12" md="3" sm="6" class="pr-6">
            <TextField dense outlined v-model="jobtitleName" class="mt-md-3" label="Job Title" hide-details type="text" />
          </v-col>
          <v-col class="searchFields pr-8" cols="12" md="3" sm="6">
            <ComboBoxField
              hide-details
              class="mt-md-3 mb-md-8"
              label="Skills"
              :items="skills"
              v-model="selectedSkills"
              item-title="Text"
              item-value="id"
              :clearable="true"
              outlined
              dense
              :loading="skillLoading"
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar class="accent text_white" left v-text="data.item.Text.slice(0, 1).toUpperCase()"></v-avatar>
                  {{ data.item.Text }}
                </v-chip>
              </template>
            </ComboBoxField>
          </v-col>
          <v-col cols="12" md="3" sm="6" class="pr-6">
            <TextField hide-details dense outlined class="mt-md-3" v-model="totalExperienceNumber" label="Total experience" type="text" />
          </v-col>
        </v-row>
        <v-col cols="12" class="filter text-right">
          <v-btn @click="clearFilter">
            <v-icon left>mdi-close</v-icon>
            clear Filter
          </v-btn>
          <v-btn color="primary" class="ml-0" @click="getTeamMemberData">
            <v-icon left>mdi-check</v-icon>
            Filter
          </v-btn>
        </v-col>
        <v-row>
          <v-col>
            <v-data-table-server
              class="pr-5"
              :page="page"
              :items-per-page="numberOfPages"
              :items-length="totalItemsCount"
              :headers="headers"
              :items="teammembers"
              @update:options="(event: any) => tableOptions = event"
            >
              <template v-slot:item.FullName="{ item }"> {{ item.FullName }}</template>
              <template v-slot:item.JobTitleName="{ item }"> {{ item.JobTitleName }}</template>
              <template v-slot:item.Skills="{ item }">{{ getSkillsNames(item) }}</template>
              <template v-slot:item.TotalExperience="{ item }"> {{ item.TotalExperience }} </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-20" @click="getTeammember(item.Id)">mdi-pencil</v-icon>
                <v-icon small class="mr-20">mdi-account</v-icon>
              </template>
            </v-data-table-server>
          </v-col>
        </v-row>

        <v-dialog v-if="profileDialog" v-model="profileDialog" fullscreen hide-overlay transition="dialog-bottom-transiton">
          <v-card>
            <div class="close-profile-dialog">
              <v-btn elevation="2" icon @click="profileDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <Information :selectedTeammemberModel="selectedTeammemberModel" :isHiringmanager="isHiringmanager"> </Information>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <loading-component v-if="pageLoading"></loading-component>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Teammember, TeammemberSkill } from "shared-components/src/models/Teammember";
import CareerHistories from "shared-components/src/models/CareerHistories";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Utils from "shared-components/src/utils/Utils";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { Traits } from "shared-components/src/models/Traits";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import TraitsService from "shared-components/src/services/TraitsService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "@/services/CoreSkillService";
import AppHelper from "shared-components/src/utils/AppHelper";
import Information from "./Information.vue";
import "shared-components/assets/main.scss";
import "shared-components/assets/style/app.scss";
import Details from "shared-components/src/models/Details";
import DetailsService from "shared-components/src/services/DetailsService";
import ApiService from "@/services/ApiService";
import { PaginationRequestModel, TeammemberApi } from "shared-components/src/services/openApi/api";
import store from "@/store";
import ComboBoxField from "shared-components/src/components/Fields/ComboBoxField.vue";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import { TeammemberByRateResponseModel } from "shared-components/src/services/openApi";

const teammemberApi = new TeammemberApi();

export default defineComponent({
  components: {
    ConfirmDialog,
    LoadingComponent,
    Information,
  },
  data() {
    return {
      skillLoading: true,
      pageLoading: true,
      jobtitleName: "",
      skills: [] as CoreSkill[],
      selectedSkills: null as string[] | null,
      teammemberName: "",
      teammembers: [] as TeammemberByRateResponseModel[],
      selectedTeammember: {},
      totalItemsCount: 0 as number | undefined,
      totalExperienceNumber: null as number | null,
      selectedTeammemberModel: {
        CV: "",
        id: "",
        fullName: "",
        nickName: "",
        city: "",
        email: "",
        timeZone: "",
        memberSince: "",
        linkedinAddress: "",
        education: "",
        description: "",
        skills: [] as TeammemberSkill[] | null,
        phone: "",
        englishSpokenLevel: false,
        englishWritetenLevel: false,
        photoUrl: "",
        careerHistories: [] as CareerHistories[] | null,
      },
      confirmModel: {
        title: "",
        text: "",
      },
      page: 1,
      numberOfPages: 10,
      careerCollapsed: false,
      experiencedCollapsed: false,
      languageCollapsed: false,
      headers: [
        { title: "TeamMember", value: "FullName", sortable: false },
        { title: "Job Title", value: "JobTitleName", sortable: false },
        { title: "Skills", value: "Skills", sortable: false },
        { title: "Total Experience", value: "TotalExperience", sortable: false },
        { title: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      jobTitles: [] as Details[],
      details: [] as Details[],
      tableData: [] as any,
      profileDialog: false,
      tableOptions: {} as any,
      sortOrder: "asc",
      sortProp: "FullName",
      filters: {} as any,
    };
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
  },
  methods: {
    async getTeamMemberData() {
      const { page, itemsPerPage } = this.tableOptions;
      const paginationModel = {
        PageNumber: page,
        PageSize: itemsPerPage,
        SortOrder: this.sortOrder,
        SortProp: this.sortProp,
        filters: this.filters,
      } as PaginationRequestModel;

      const response = await teammemberApi.getTeammemberListByRate(paginationModel);
      const listItems = response.data as any[];
      if (response.data.Items) {
        this.teammembers = response.data.Items;
        this.totalItemsCount = response.data.Total;
      }
    },
    findSkillName(id: any) {
      return this.getSkillName(id);
    },
    getSkillName(id: any) {
      const item = this.skills.find((c) => c.id === id);
      if (item) {
        return item.Text;
      } else {
        return "";
      }
    },
    getSkillsNames(teamMember: any) {
      const skillNames = [] as string[];
      if (teamMember.Skills) {
        teamMember.Skills.forEach((element: any) => {
          skillNames.push(this.getSkillName(element.SkillId));
        });
      }
      return skillNames.join(", ");
    },
    clearFilter() {
      this.teammemberName = "";
      this.selectedSkills = null;
      this.jobtitleName = "";
      this.totalExperienceNumber = null;
      delete this.filters["FullName"];
      delete this.filters["Skill"];
      delete this.filters["teammemberprofessionaldetail.JobTitle.Name"];
      delete this.filters["teammemberprofessionaldetail.ExperienceYear"];

      this.getTeamMemberData();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails(ApiService);
    },
    async cvDownload() {
      const downloadLink = await TeamMemberService.getResumeDownloadLink(this.selectedTeammemberModel.id);
      window.open(downloadLink, "_blank");
    },
    async getTeammember(id: any) {
      const teammemberItem = this.teammembers.find((obj: any) => obj.Id === id);
      if (teammemberItem) {
        this.selectedTeammemberModel.id = teammemberItem.Id ?? "";
        this.selectedTeammemberModel.fullName = teammemberItem.FullName ?? "";
        this.profileDialog = true;
      }
      this.selectedTeammember = id;
    },

    formatArray(items: any) {
      if (items && items.length > 0) {
        return items.join(", ");
      } else {
        return "";
      }
    },
    formatPlatform(items: any) {
      return items.join(", ");
    },
    getTraits(weak: any) {
      if (weak) {
        return weak.Name;
      }
      return "";
    },
  },
  watch: {
    tableOptions(newVal: any) {
      if (newVal.sortBy && newVal.sortBy.length) {
        this.sortProp = newVal.sortBy[0].key;
        this.sortOrder = newVal.sortBy[0].order;
      }
      this.getTeamMemberData();
    },
    teammemberName(newVal: any) {
      if (newVal && newVal.length > 0) {
        this.filters["FullName"] = newVal;
      } else {
        delete this.filters["FullName"];
      }
    },
    jobtitleName(newVal: any) {
      if (newVal) {
        this.filters["teammemberprofessionaldetail.JobTitle.Name"] = newVal;
      } else {
        delete this.filters["teammemberprofessionaldetail.JobTitle.Name"];
      }
    },
    selectedSkills(newVal) {
      if (newVal) {
        this.filters["Skill"] = newVal.id;
      } else {
        delete this.filters["Skill"];
      }
    },
    totalExperienceNumber(newVal) {
      if (newVal) {
        this.filters["teammemberprofessionaldetail.ExperienceYear"] = newVal;
      } else {
        delete this.filters["teammemberprofessionaldetail.ExperienceYear"];
      }
    },
  },
  async mounted() {
    try {
      await this.getTeamMemberData();
      await this.loadDetailsList();
      this.skills = await CoreSkillService.getList();
    } finally {
      this.skillLoading = false;
      this.pageLoading = false;
    }
  },
});
</script>
<style scoped>
.actionButs {
  padding-left: 2px;
  padding-right: 2px;
}

.assessmentBut {
  float: left !important;
}

.teammemberMenu {
  height: 100vh;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid black;
  margin-left: 12px;
  margin-bottom: 10px;
}

.teammemberMenu h2 {
  text-align: center;
  border-bottom: 1px solid #dadada;
  height: 37px;
}

.teammemberList ul {
  padding-left: 0px;
}

.teammemberList ul li {
  height: 40px;
  list-style-type: none;
  line-height: 40px;
}

.teammemberList ul li a {
  padding-left: 20px;
  display: block;
  color: black;
}

.teammemberList ul li a:hover {
  background: rgb(226 226 226);
}

.teammemberDetail {
  margin-left: 12px;
  padding-top: 0px;
}

.teammemberDetail div {
  padding-bottom: 0px;
  max-width: 100%;
}

.teammemberDetail div p {
  margin-bottom: 5px;
}

.searchFields {
  padding-bottom: 0px;
}

.searchFields div {
  margin-bottom: 0px !important;
}

.fullHeight {
  margin-bottom: 0px !important;
}

.nameField {
  padding-bottom: 0px !important;
}

.profile-image {
  width: 120px;
  height: 120px;
  border: 3px solid gray;
  border-radius: 6px;
}

.addAssessmentBut {
  direction: rtl;
}

.close-profile-dialog {
  padding-left: 15px;
  padding-top: 10px;
}

@media screen and (max-width: 539px) {
  .assessmentBut {
    width: 100%;
  }
}
</style>
