<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="mt-3">Account Ledger Report for {{ accountName }}</span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3" sm="4">
            <DateRangeInputField
              v-model="selectedDateRange"
              label="Report Date Range"
              first-day-of-week="1"
              hide-actions
              placeholder="Select Report Date Range"
              prepend-icon=""
            />
          </v-col>
          <v-col cols="12" md="1" sm="1" class="mt-9">
            <v-menu offset-y left attach>
              <template v-slot:activator="{ props }">
                <v-btn small icon light v-bind="props">
                  <v-icon icon cir> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="pa-6">
                  <v-list flat>
                    Options
                    <v-list-item v-for="item in dateRanges" :key="item" :value="item" :title="item" @click="selectedRange(item)" />
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <v-col md="2" sm="4" cols="12">
            <v-btn class="accent action-button mt-9" color="primary" :loading="loading" @click="createReport">
              <v-icon>mdi-sync</v-icon> Process
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table-server
          :items="items"
          :headers="headers"
          :loading="loading"
          :page="page"
          :items-per-page="numberOfPages"
          @update:options="(event: any) => tableOptions = event"
          :items-length="totalItemsCount"
          hide-default-footer
        >
          <template v-slot:item.date="{ item }">
            <span class="font-weight-light">{{ formatDate(item.date) }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            <span class="font-weight-light">{{ item.description }}</span>
          </template>
          <template v-slot:item.referenceNumber="{ item }">
            <span class="font-weight-light">{{ item.referenceNumber }}</span>
          </template>
          <template v-slot:item.debit="{ item }">
            <span class="font-weight-light">{{ GetFormatTwoDecimal(item.debit) }}</span>
          </template>
          <template v-slot:item.credit="{ item }">
            <span class="font-weight-light">{{ GetFormatTwoDecimal(item.credit) }}</span>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { ReportApi, AccountLedgerReportItem, AccountLedgerRequest } from "shared-components/src/services/openApi";
import moment from "moment";
import Utils from "shared-components/src/utils/Utils";
const reportApi = new ReportApi();

export default defineComponent({
  props: {
    accountId: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedDateRange: [] as any[],
      dateRanges: ["This Week", "Last Week", "Last Month", "This Month", "Last Year", "This Year"],
      items: [] as AccountLedgerReportItem[],
      loading: true,
      headers: [
        { title: "Date", sortable: true, align: "start", value: "date" },
        { title: "Desciption", sortable: true, align: "start", value: "description" },
        { title: "Reference Number", sortable: true, align: "start", value: "referenceNumber" },
        { title: "Debit", sortable: true, align: "end", value: "debit" },
        { title: "Credit", sortable: true, align: "end", value: "credit" },
      ],
      page: 1,
      numberOfPages: 10,
      sortProp: "date",
      sortOrder: "desc",
      tableOptions: {} as any,
      totalItemsCount: 0,
    };
  },
  methods: {
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    GetFormatTwoDecimal(number: number): string {
      return Utils.accountingMoneyFormat(number);
    },
    async createReport() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;
        if (!this.selectedDateRange || this.selectedDateRange.length == 0) {
          this.items = [];
          return;
        }
        const model = {
          startDate: Utils.toVsDateFormat(this.selectedDateRange[0]),
          endDate: Utils.toVsDateFormat(this.selectedDateRange[1]),
          sortOrder: this.sortOrder,
          sortProperty: this.sortProp,
          pageNumber: page,
          pageSize: itemsPerPage,
          currency: this.currency,
        } as AccountLedgerRequest;
        const result = await reportApi.getAccountLedger(this.accountId, model);
        this.items = result.data;
      } finally {
        this.loading = false;
      }
    },
    selectedRange(newVal: string) {
      let startDate = new Date();
      let endDate = new Date();
      switch (newVal) {
        case "This Week":
          startDate = moment().startOf("week").toDate();
          endDate = moment().endOf("week").toDate();
          break;
        case "Last Week":
          startDate = moment().subtract(1, "weeks").startOf("week").toDate();
          endDate = moment().subtract(1, "weeks").endOf("week").toDate();
          break;
        case "Last Month":
          startDate = moment().subtract(1, "months").startOf("month").toDate();
          endDate = moment().subtract(1, "months").endOf("month").toDate();
          break;
        case "This Month":
          startDate = moment().startOf("month").toDate();
          endDate = moment().endOf("month").toDate();
          break;
        case "Last Year":
          startDate = moment().subtract(1, "years").startOf("year").toDate();
          endDate = moment().subtract(1, "years").endOf("year").toDate();
          break;
        case "This Year":
          startDate = moment().startOf("year").toDate();
          endDate = moment().endOf("year").toDate();
          break;
      }
      if (startDate < endDate) {
        (this.selectedDateRange as any) = [startDate, endDate];
      }
    },
  },

  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
          this.sortOrder = newVal.sortBy[0].order;
        }

        this.createReport();
      }
    },
  },
  async mounted(): Promise<void> {
    this.selectedDateRange = this.dateRange as any[];
    await this.createReport();
  },
});
</script>
<style scoped>
.small {
  font-size: 90%;
}
</style>
