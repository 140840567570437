import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, "Changing VM status to " + _toDisplayString(_ctx.status), 1),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "",
                dark: "",
                onClick: _ctx.cancel,
                fab: "",
                class: "error",
                "x-small": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "pt-0 pb-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmVmStatus",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "m-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "pt-0",
                    md: "12",
                    sm: "12",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DateInputField, {
                        rules: [_ctx.validations.required],
                        label: "Effective date",
                        "first-day-of-week": "1",
                        modelValue: _ctx.statusModel.EffectiveDate,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statusModel.EffectiveDate) = $event)),
                        "hide-actions": "",
                        placeholder: "Effective date",
                        "prepend-icon": ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "pt-0 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "secondary",
                class: "mr-10",
                onClick: _ctx.changeStatus,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("mdi-check")
                    ])),
                    _: 1
                  }),
                  _cache[4] || (_cache[4] = _createTextVNode(" Save"))
                ]),
                _: 1
              }, 8, ["onClick", "loading"]),
              _createVNode(_component_v_btn, {
                variant: "text",
                color: "primary",
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" cancel")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}