<template>
  <GeneralError @goHome="goHome"></GeneralError>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import GeneralError from "shared-components/src/components/GeneralError.vue";

export default defineComponent({
  components: {
    GeneralError
  },
  methods: {
    async goHome(): Promise<void> {
      await this.$router.push(`/`);
    }
  }
});
</script>
