import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_activity_watch_report = _resolveComponent("activity-watch-report")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_activity_watch_report, {
      vmId: _ctx.vmId,
      onClose: _ctx.goBack
    }, null, 8, ["vmId", "onClose"])
  ]))
}