<template>
  <v-card :loading="pageLoading" :disabled="pageLoading">
    <div v-if="!pageLoading && reviewModel">
      <v-card-title>
        <v-row no-gutters>
          <span>Review Commitment</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="action-container" v-if="reviewModel?.FromCommitment">
          <span>Applied Actions:</span>
          <b v-for="(action, index) in reviewModel?.Actions?.ActionItems" :key="index">
            {{ action.DisplayName }}
          </b>
        </div>
        <div class="review-container">
          <ReviewCommitmentItem class="review-item" :reviewModel="reviewModel" v-if="reviewModel?.FromCommitment" />
          <div class="review-arrow" v-if="reviewModel?.FromCommitment">=></div>
          <ReviewCommitmentItem class="review-item" :reviewModel="reviewModel" :isNewCommitment="true" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-col md="12" class="text-right">
          <v-btn class="primary_btn mr-5" dark @click="showUpdateStatus(true)">Reject</v-btn>
          <v-btn class="secondary_btn mr-5" dark @click="showUpdateStatus(false)">Approve</v-btn>
        </v-col>
      </v-card-actions>
    </div>
    <loading-component v-if="pageLoading"></loading-component>
    <v-dialog v-model="showUpdateStatusPopup" persistent max-width="400px">
      <v-card>
        <v-card-title>
          {{ !rejectMode ? "Approve Commitment" : "Reject Commitment" }}
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <span>Are you sure about this action ?</span>
          </v-col>

          <TextAreaField
            v-if="rejectMode"
            dense
            outlined
            v-model="rejectMessage"
            showRequired
            label="Reason of Rejecting"
            placeholder="Reason of Rejecting"
          />
        </v-card-text>
        <v-card-actions>
          <v-col md="12" class="d-flex justify-end">
            <v-btn :loading="loading" class="primary_btn mr-5" dark @click="showUpdateStatusPopup = false">Cancel</v-btn>
            <v-btn :loading="loading" class="secondary_btn" dark @click="updateCommitmentStatus">{{
              !rejectMode ? "Yes! Approve it" : "Yes! Reject it"
            }}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Commitment from "shared-components/src/models/Commitment";
import CommitmentService from "shared-components/src/services/CommitmentService";
import { OfficeSpaceModel, UpdateCommitmentStatusRequestModel } from "shared-components/src/services/openApi/api";
import moment from "moment";
import { GetReviewCommitmentModel } from "shared-components/src/services/openApi";
import ReviewCommitmentItem from "./ReviewCommitmentItem.vue";
import { CommitmentApi } from "shared-components/src/services/openApi";
import store from "@/store";
var momentTz = require("moment-timezone");

export default defineComponent({
  components: {
    ReviewCommitmentItem,
  },
  props: ["commitmentId"],
  data() {
    return {
      showUpdateStatusPopup: false,
      rejectMode: false,
      rejectMessage: "",
      ramValues: {
        0: "8",
        1: "16",
        2: "24",
        3: "32",
      },
      cpuValues: {
        0: "2",
        1: "4",
        2: "6",
      },
      diskValues: {
        0: "128",
        1: "256",
        2: "384",
        3: "512",
      },
      daysTemplate: [
        { title: "Flexible", value: "Flexible", props: { disabled: false } },
        { title: "SUN", value: "SUN", props: { disabled: false } },
        { title: "MON", value: "MON", props: { disabled: false } },
        { title: "TUE", value: "TUE", props: { disabled: false } },
        { title: "WED", value: "WED", props: { disabled: false } },
        { title: "THR", value: "THR", props: { disabled: false } },
        { title: "FRI", value: "FRI", props: { disabled: false } },
        { title: "SAT", value: "SAT", props: { disabled: false } },
      ],
      workingDays: [{ text: "Flexible", value: "Flexible", props: { disabled: false } }],
      dayInOffice: [{ text: "Flexible", value: "Flexible", props: { disabled: false } }],
      hoursInDay: ["Flexible"],
      NoOfficeDaysPerWeek: ["Flexible", 1, 2, 3, 4, 5, 6, 7],
      hasTimesheet: false,
      pageLoading: true,
      loading: false,
      valid: true,
      showRating: false,
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      projectLoading: false,
      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      lineManagers: [] as Array<{ id: string; Name: string }>,
      projectLeads: [] as Array<{ id: string; Name: string }>,
      portfolioManagers: [] as Array<{ id: string; Name: string }>,
      tdms: [] as Array<{ id: string; Name: string }>,
      projects: [] as Array<{ id: string; Name: string }>,
      customers: [] as Array<{ id: string; Name: string }>,
      teammembers: [] as Array<{ id: string; Name: string }>,
      model: { HoursPerWeekRadio: true } as Commitment,
      selectedCustomerId: null as string | null,
      selectedTeamMemberId: null as string | null,
      selectedProjectId: null as string | null,
      timeZones: [] as Array<{ title: string; value: string }>,
      showAddOfficeLocation: false,
      StartDate: null as Date | null,
      EndDate: null as Date | null,
      officeSpaces: [] as OfficeSpaceModel[],
      isCasual: false,
      reviewModel: null as GetReviewCommitmentModel | null,
      selectedHoursPerWeekMax: 0,
    };
  },
  async mounted() {
    this.pageLoading = true;
    await this.fetchCommitmentReview();
    this.pageLoading = false;
  },
  methods: {
    async fetchCommitmentReview() {
      this.reviewModel = (await new CommitmentApi().getReviewCommitment(this.commitmentId)).data;
    },
    async updateCommitmentStatus() {
      if (this.reviewModel) {
        try {
          this.loading = true;
          let request;
          if (this.rejectMode) {
            request = { message: this.rejectMessage, status: "Rejected" } as UpdateCommitmentStatusRequestModel;
          } else {
            request = { status: "Approved" } as UpdateCommitmentStatusRequestModel;
          }
          await CommitmentService.adminUpdateStatus(this.reviewModel.ToCommitment?.id ?? "", request);
          store.dispatch("showSuccessMessage", `Commitment ${this.rejectMode ? "Rejected" : "Approved"} Successfully`);
          this.showUpdateStatusPopup = false;
          this.rejectMessage = "";
          this.$emit("updated");
        } finally {
          this.loading = false;
        }
      }
    },
    showUpdateStatus(rejectMode: boolean) {
      this.rejectMode = rejectMode;
      this.showUpdateStatusPopup = true;
    },
    fillHoursInDay() {
      this.hoursInDay = ["Flexible"];
      this.hoursInDay = this.hoursInDay.concat(
        ...Array.from(Array(24), (_, hour) => [moment({ hour }).format("h:mm A"), moment({ hour, minute: 30 }).format("h:mm A")])
      );
    },
    loadTimeZones() {
      var timeZones = momentTz.tz.names();

      for (var i in timeZones) {
        this.timeZones.push({
          title: timeZones[i] + " (GMT" + momentTz.tz(timeZones[i]).format("Z") + ")",
          value: timeZones[i],
        });
      }
    },
    cancel() {
      this.loading = false;
      this.$emit("close");
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";
.action-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  b {
    color: $c_red;
  }
}
.review-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .review-item {
    flex: 1;
  }
  .review-arrow {
    background-color: $c_orange;
    padding: 5px;
    border-radius: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
  }
}
</style>
