<template>
  <v-card>
    <div v-show="!pageLoading">
      <v-card-title>
        <v-row no-gutters>
          <span> {{ notificationItem.PushText }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <p dense label="Short Description" outlined>
              {{ notificationItem.ShortDesc }}
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <p dense label="Text" outlined>
              {{ notificationItem.Text }}
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <p dense label="Sms" outlined>
              {{ notificationItem.SmsText }}
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <div v-html="notificationItem.EmailText"></div>
          </v-col>
          <v-col cols="12" md="12">
            <SelectField :items="users" item-title="fullName" item-value="id" v-model="selectedUser" label="Users" outlined dense />
          </v-col>

          <v-col cols="12" md="12">
            <v-data-table :items="tempItems" :headers="headers" :loading="loading">
              <template v-slot:item.UserId="{ item }">
                {{ getUserName(item.UserId) }}
              </template>
              <template v-slot:item.Status="{ item }">
                <span>{{ item.Status }}</span>
              </template>
              <template v-slot:item.SeenDate="{ item }">
                {{ formatDate(item.SeenDate) }}
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="2">
            <strong>Type: </strong><span>{{ notificationItem.Type }}</span>
          </v-col>
          <v-col cols="12" md="3">
            <strong>Section: </strong><span>{{ notificationItem.Section }}</span>
          </v-col>
          <v-col cols="12" md="4">
            <strong>Creation Date: </strong><span>{{ formatDate(notificationItem.CreationDate) }}</span>
          </v-col>
          <v-col cols="12" md="3">
            <strong>Seen Date: </strong><span>{{ formatDate(notificationItem.SeenDate) }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <loading-component v-if="pageLoading"></loading-component>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";

import LoadingComponent from "shared-components/src/components/Loading.vue";
import NotificationService from "../../services/NotificationService";
import Utils from "shared-components/src/utils/Utils";
import { UserModel,NotificationItem } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    LoadingComponent,
  },
  props: ["notificationItem", "users"],
  data() {
    return {
      loading: false,
      pageLoading: false,
      userList: [] as UserModel[],
      selectedUser: "",
      items: [] as NotificationItem[],
      tempItems: [] as NotificationItem[],
      headers: [
        { title: "User", value: "UserId" },
        { title: "Status", value: "Status" },
        { title: "Seen Date", value: "SeenDate" },
      ],
    };
  },
  methods: {
    getUserName(id: any) {
      var userItem = this.users.find((c: any) => c.id === id);
      if (userItem) {
        return userItem.fullName;
      } else {
        return "";
      }
    },
    filterNotificationItems() {
      return this.items.filter((item) => {
        let exist = true;
        if (this.selectedUser) {
          exist = item.UserId === this.selectedUser;
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "";
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    async fetchNotificationItems() {
      this.items = await NotificationService.GetByNotificationId(this.notificationItem.id);
      this.tempItems = this.items;
    },
  },
  watch: {
    async selectedUser(newVal) {
      this.tempItems = this.filterNotificationItems();
    },
  },
  async mounted() {
    this.userList = [];
    this.userList = this.users;

    if (!this.userList.some((x) => x.fullName === "All")) {
      this.userList.splice(0, 0, { id: "", fullName: "All" } as UserModel);
    }

    await this.fetchNotificationItems();
  },
});
</script>
