<template>
  <div v-if="loggingOut">Loging out...</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserService from "@/services/UserService";
import store from "@/store";
import UserInfo from "shared-components/src/models/UserInfo";
import { defaultUser } from "@/store/state";
import LoadingComponent from "shared-components/src/components/Loading.vue";

export default defineComponent({
  components: {
    LoadingComponent,
  },
  data() {
    return {
      loggingOut: false,
    };
  },
  async mounted() {
    await this.logout();
  },
  methods: {
    setUserInfo(userInfo: UserInfo): void {
      store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    async logout() {
      try {
        store.state.hasFirebaseAuth = false;
        localStorage.idToken = "";
        localStorage.refreshToken = "";
        this.loggingOut = true;
        await UserService.logout();
        this.setUserInfo({ ...defaultUser });
        this.loggingOut = false;
        this.$router.push("/login");
      } catch (error) {
        this.loggingOut = false;
        store.dispatch("showErrorMessage", error)
      }
    },
  },
});
</script>
