<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">{{ billableItem != null ? "Update Billable Item" : "New Billable Item" }}</div>
        <div class="card-title-right">
          <v-btn variant="text" size="small" icon="mdi-close" @click="close"></v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid" ref="frmBilling">
        <v-row>
          <v-col cols="6">
            <TextField :rules="[rules.required]" v-model="model.Name" label="Name" placeholder="Name" />
          </v-col>
          <v-col cols="6">
            <SelectField :items="billableItemCodes" :rules="[rules.required]" v-model="model.Code" label="Code" placeholder="Code" />
          </v-col>
          <v-col cols="6">
            <SelectField :items="billableItemIntervals" :rules="[rules.required]" v-model="model.Interval" label="Interval" placeholder="Interval" />
          </v-col>
          <v-col cols="6">
            <TextField :rules="[rules.required, rules.minNumber(0)]" v-model="model.Fee" type="number" min="0" label="Fee" placeholder="Fee" />
          </v-col>
          <v-col cols="6">
            <SelectField :rules="[rules.required]" :items="billableItemTypes" v-model="model.Type" label="Type" placeholder="Type" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <div class="v-card-actions">
      <v-col cols="12">
        <v-btn class="right" color="secondary" @click="save" prepend-icon="mdi-check">{{ billableItem != null ? "Update" : "Add" }}</v-btn>
        <v-btn variant="text" color="accent" class="right mr-5" @click="close">cancel</v-btn>
      </v-col>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BillableItemApi, BillableItemModel, BillableItemModelCodeEnum, BillableItemModelTypeEnum } from "shared-components/src/services/openApi";
import rules from "shared-components/src/utils/validations";
import SelectField from "shared-components/src/components/Fields/SelectField.vue";

export default defineComponent({
  props: ["billableItem"],
  data() {
    return {
      valid: false,
      rules,
      loading: false,
      model: {} as BillableItemModel,
      billableItemCodes: Object.values(BillableItemModelCodeEnum),
      billableItemIntervals: ["Hourly", "Daily", "Monthly"],
      billableItemTypes: Object.values(BillableItemModelTypeEnum),
    };
  },
  methods: {
    close() {
      this.$emit("Close");
    },
    async save() {
      const isValid = (await (this.$refs.frmBilling as any).validate()).valid;
      if (isValid) {
        this.loading = true;
        try {
          const result = (await new BillableItemApi().createBillableItem(this.model)).data;
          this.$emit("Save", result);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  async mounted() {
    if (this.billableItem) {
      this.model = this.billableItem;
    }
  },
});
</script>
