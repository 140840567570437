<template>
  <v-card :loading="loading" :disabled="loading">
    <div v-show="!pageLoading">
      <v-card-title>
        <v-row no-gutters>
          <span>{{ commitmentModel && commitmentModel.id ? "Edit" : "Add" }} Commitment</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="frmCommitment" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <TextField :rules="[rules.required, rules.exist]" dense v-model="model.Name" label="Name" outlined />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <AutoCompleteField
                :rules="[rules.required]"
                v-model="model.SeniorityLevel"
                label="Seniority Level"
                :items="[
                  1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15,
                ]"
                outlined
                :readonly="hasTimesheet"
                dense
              />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <DateInputField
                :rules="[rules.required]"
                label="Start date"
                first-day-of-week="1"
                v-model="model.StartDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <DateInputField
                :rules="[rules.required]"
                label="End date"
                first-day-of-week="1"
                v-model="model.EndDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <SelectField
                :rules="[rules.required]"
                label="Public Holiday Zone"
                :items="publicHolidayZones"
                v-model="selectedPublicHolidayZone"
                item-title="Name"
                item-value="id"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-switch :readonly="hasTimesheet" class="mt-7" label="Is active" v-model="model.IsActive" color="success"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <SelectField
                label="Type"
                :items="[
                  { value: true, title: 'Hours per week' },
                  { value: false, title: 'Casual' },
                ]"
                v-model="model.HoursPerWeekRadio"
                item-title="title"
                item-value="value"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <TextField
                :disabled="!model.HoursPerWeekRadio"
                :rules="[minMax, rules.float]"
                v-model="selectedHoursPerWeekMin"
                label="Min"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <TextField
                :disabled="!model.HoursPerWeekRadio"
                :rules="[minMax, rules.float]"
                v-model="selectedHoursPerWeekMax"
                label="Max"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="12" md="2">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Line Manager"
                v-model="selectedLineManager"
                :items="lineManagers"
                item-title="Name"
                item-value="id"
                dense
                outlined
                chips
                :readonly="hasTimesheet"
                return-object
              />
            </v-col>
            <v-col cols="12" md="2">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Project Lead"
                v-model="selectedProjectLead"
                :items="projectLeads"
                item-title="Name"
                item-value="id"
                outlined
                chips
                dense
                :readonly="hasTimesheet"
                return-object
              />
            </v-col>
            <v-col cols="12" md="2">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Portfolio Manager"
                v-model="selectedPortfolioManager"
                :items="portfolioManagers"
                item-title="Name"
                item-value="id"
                outlined
                chips
                :readonly="hasTimesheet"
                dense
                return-object
              />
            </v-col>
            <v-col cols="12" md="2">
              <AutoCompleteField
                :rules="[rules.required]"
                label="TDM"
                v-model="selectedTDM"
                :items="tdms"
                item-title="Name"
                item-value="id"
                outlined
                chips
                :readonly="hasTimesheet"
                dense
                return-object
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-btn :disabled="hasTimesheet" class="primary mt-3 ml-3" @click="openRate()" dark>Rate</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Customer"
                v-model="selectedCustomer"
                :items="customers"
                item-title="Name"
                item-value="id"
                outlined
                chips
                dense
                :readonly="hasTimesheet"
                @update:modelValue="onChangeCustomer"
                return-object
              />
            </v-col>
            <v-col cols="12" md="6">
              <AutoCompleteField
                :loading="projectLoading"
                :rules="[rules.required]"
                label="Project"
                v-model="selectedProject"
                :items="projects"
                item-title="Name"
                item-value="id"
                outlined
                chips
                :readonly="hasTimesheet"
                dense
                return-object
              />
            </v-col>
            <v-col cols="12" md="6">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Teammember"
                v-model="selectedTeamMember"
                :items="teammembers"
                item-title="Name"
                item-value="id"
                outlined
                chips
                dense
                :readonly="hasTimesheet"
                return-object
              />
            </v-col>
            <v-col cols="12" md="6">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Working Days"
                v-model="model.WokingDays"
                :items="['SUN', 'MON', 'TUE', 'WED', 'THR', 'FRI', 'SAT']"
                multiple
                outlined
                chips
                dense
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col md="12">
          <v-btn color="primary" class="mr-5" variant="elevated" @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
          <v-btn class="secondary_btn" variant="elevated" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
        </v-col>
      </v-card-actions>
    </div>
    <loading-component v-if="pageLoading"></loading-component>
    <v-dialog v-if="showRating" v-model="showRating" persistent max-width="500" @keydown.esc="showRating = false">
      <rate ref="newRate" :rateModel="model.Rate" @cancelRating="showRating = false" @saveRating="saveRating"></rate>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import PublicHolidayService from "shared-components/src/services/PublicHolidayService";
import ProjectService from "shared-components/src/services/ProjectService";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Commitment, { TeamMenberOfCommitment } from "shared-components/src/models/Commitment";
import CustomerService from "shared-components/src/services/CustomerService";
import LineManagerService from "shared-components/src/services/LineManagerService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import ProjectLeadService from "shared-components/src/services/ProjectLeadService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import PortfolioManagerService from "../../services/PortfolioManagerService";
import TDMService from "../../services/TDMService";
import Utils from "shared-components/src/utils/Utils";
import Rate from "./Rate.vue";
import AppHelper from "shared-components/src/utils/AppHelper";

export default defineComponent({
  components: {
    LoadingComponent,
    Rate,
  },
  props: ["commitmentModel", "commitments"],
  data() {
    return {
      hasTimesheet: false,
      pageLoading: true,
      loading: false,
      valid: true,
      showRating: false,
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      projectLoading: false,
      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      lineManagers: [] as Array<{ id: string; Name: string }>,
      projectLeads: [] as Array<{ id: string; Name: string }>,
      portfolioManagers: [] as Array<{ id: string; Name: string }>,
      tdms: [] as Array<{ id: string; Name: string }>,
      projects: [] as Array<{ id: string; Name: string }>,
      customers: [] as Array<{ id: string; Name: string }>,
      teammembers: [] as Array<{ id: string; Name: string }>,
      model: {
        IsActive: true,
        Name: "",
        SeniorityLevel: 3,
        StartDate: null,
        EndDate: null,
        HoursPerWeekMax: null as number | null,
        HoursPerWeekMin: null as number | null,
        HoursPerWeekRadio: false,
        WokingDays: [] as string[],
        LineManagerId: "",
        ProjectLeadId: "",
        PortfolioManagerId: "",
        TDMId: "",
        Rate: {
          TeamMemberRate: 0,
          LineManagerRate: 1,
          TDMRate: 0,
          ProjectLeadRate: 0,
          PortfolioManagerRate: 0,
          HrFinanceRate: 0.5,
          CharityRate: 0,
          OffshoreEntityRate: 0.2,
          ToolsRate: 0.2,
          FunctionalManagerRate: 1,
          BusinessManagerRate: 1,
          SubsidyRate: 0,
          MiscRate: 0,
        },
      },
      selectedCustomer: null as { id: string; Name: string } | null,
      selectedTeamMember: null as { id: string; Name: string } | null,
      selectedProject: null as { id: string; Name: string } | null,
      selectedLineManager: null as { Name: string; id: string } | null,
      selectedProjectLead: null as { Name: string; id: string } | null,
      selectedPortfolioManager: null as { Name: string; id: string } | null,
      selectedTDM: null as { Name: string; id: string } | null,
      selectedPublicHolidayZone: "",
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
        exist: (v: any) =>
          !this.commitments.some(
            (c: any) => c.Name && v && c.Name.toLowerCase() === v.toLowerCase() && !(this.commitmentModel && c.id === this.commitmentModel.id)
          ) || "Already exist",
      },
    };
  },
  async mounted() {
    await this.fetchPublicHolidays();
    await this.fetchCustomers();
    await this.fetchTeammembers();
    this.pageLoading = false;
  },
  methods: {
    async fetchPublicHolidays(): Promise<void> {
      this.publicHolidayZones = (await PublicHolidayService.getList(true)).map((item: any) => {
        return { id: item.Id, Name: item.Title };
      });
    },

    async fetchLineManager(): Promise<void> {
      this.lineManagers = (await LineManagerService.getList(true))
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchProjectLeads(): Promise<void> {
      this.projectLeads = (await ProjectLeadService.getList(true))
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchPortfolioManagers(): Promise<void> {
      this.portfolioManagers = (await PortfolioManagerService.getList())
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchTDMs(): Promise<void> {
      this.tdms = (await TDMService.getList())
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchCustomers(): Promise<void> {
      this.customers = (await CustomerService.getList(true))
        .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },
    async fetchTeammembers(): Promise<void> {
      if (AppHelper.IsAzureEnv()) {
        this.teammembers = (await TeamMemberService.getListWithSummaryDetails())
          .filter((c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
          .map((item: any) => {
            return { id: item.Id, Name: item.FirstName + " " + item.LastName };
          });
      } else {
        this.teammembers = (await TeamMemberService.getList(true))
          .filter((c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
          .map((item: any) => {
            return { id: item.Id, Name: item.FirstName + " " + item.LastName };
          });
      }
    },
    saveRating(rating: any): void {
      this.model.Rate = rating;
      this.showRating = false;
    },
    openRate() {
      this.showRating = true;
    },
    cancel() {
      (this.$refs.frmCommitment as any).reset();
      this.model = {
        IsActive: true,
        Name: "",
        SeniorityLevel: 0,
        StartDate: null,
        EndDate: null,
        HoursPerWeekMax: null,
        HoursPerWeekMin: null,
        HoursPerWeekRadio: false,
        WokingDays: [],
        LineManagerId: "",
        ProjectLeadId: "",
        PortfolioManagerId: "",
        TDMId: "",
        Rate: {
          TeamMemberRate: 0,
          LineManagerRate: 1,
          TDMRate: 0,
          ProjectLeadRate: 0,
          PortfolioManagerRate: 0,
          HrFinanceRate: 0.5,
          CharityRate: 0,
          OffshoreEntityRate: 0.2,
          ToolsRate: 0.2,
          FunctionalManagerRate: 1,
          BusinessManagerRate: 1,
          SubsidyRate: 0,
          MiscRate: 0,
        },
      };
      this.loading = false;
      this.$emit("cancel");
    },
    async save() {
      const isValid = await (this.$refs.frmCommitment as any).validate();
      if (isValid.valid) {
        this.loading = true;
        var commitmentByName = await CommitmentService.getByName(this.model.Name);
        if (!commitmentByName || (commitmentByName && (!commitmentByName.id || commitmentByName.id === this.commitmentModel.id))) {
          const model = {
            id: this.commitmentModel ? this.commitmentModel.id : "",
            ...this.model,
          } as Commitment;

          model.LineManagerId = this.selectedLineManager ? this.selectedLineManager.id : "";
          model.ProjectLeadId = this.selectedProjectLead ? this.selectedProjectLead.id : "";
          model.PortfolioManagerId = this.selectedPortfolioManager ? this.selectedPortfolioManager.id : "";
          model.TDMId = this.selectedTDM ? this.selectedTDM.id : "";
          if (this.selectedTeamMember) {
            model.TeamMemberIds = [this.selectedTeamMember.id];
            model.TeamMembers = [{ id: this.selectedTeamMember.id, TmFullName: this.selectedTeamMember.Name } as TeamMenberOfCommitment];
          }
          if (this.selectedProject) {
            model.ProjectIds = [this.selectedProject.id];
          }
          if (this.selectedCustomer) {
            model.CustomerIds = [this.selectedCustomer.id];
            model.Customers = [{ id: this.selectedCustomer.id, CuFullName: this.selectedCustomer.Name }];
          }
          model.PublicHolidayZoneId = this.selectedPublicHolidayZone;
          if (model.id) {
            model.id = (await CommitmentService.update(model)).id;
          } else {
            model.id = (await CommitmentService.set(model)).id;
          }
          model.EndDate = this.model.EndDate;
          this.$emit("saved", model);
        }

        this.loading = false;
      }
    },
    minMax(v: any): boolean | string {
      return !this.model.HoursPerWeekRadio || !!v || "Required.";
    },
    async onChangeCustomer() {
      this.selectedProject = null;
      this.projects = await this.loadProjects();
    },
    async loadProjects(): Promise<Array<{ id: string; Name: string }>> {
      this.projectLoading = true;
      return new Promise((resolve, reject) => {
        if (this.selectedCustomer) {
          const customerId = this.selectedCustomer.id;
          ProjectService.getByCustomerId(customerId)
            .then((list: any) => {
              resolve(
                list.map((c: any) => {
                  return {
                    id: c.id,
                    Name: c.Name,
                  };
                })
              );
            })
            .finally(() => {
              this.projectLoading = false;
            });
        } else {
          resolve([]);
        }
      });
    },
    async loadData(): Promise<void> {
      await this.fetchLineManager();
      await this.fetchProjectLeads();
      await this.fetchPortfolioManagers();
      await this.fetchTDMs();
      if (this.commitmentModel) {
        this.loading = true;

        let commitment = this.commitmentModel;
        if (this.commitmentModel.id) {
          commitment = await CommitmentService.get(this.commitmentModel.id);
        }
        if (commitment) {
          this.model = {
            Name: commitment.Name,
            IsActive: commitment.IsActive,
            HoursPerWeekRadio: commitment.HoursPerWeekRadio,
            HoursPerWeekMin: commitment.HoursPerWeekMin ? parseFloat(commitment.HoursPerWeekMin.toString()) : null,
            SeniorityLevel: commitment.SeniorityLevel,
            StartDate: commitment.StartDate,
            EndDate: commitment.EndDate,
            HoursPerWeekMax: commitment.HoursPerWeekMax ? parseFloat(commitment.HoursPerWeekMax.toString()) : null,
            WokingDays: commitment.WokingDays ? commitment.WokingDays : [],
            LineManagerId: commitment.LineManagerId,
            ProjectLeadId: commitment.ProjectLeadId,
            PortfolioManagerId: commitment.PortfolioManagerId,
            TDMId: commitment.TDMId,
            Rate: commitment.Rate
              ? {
                  TeamMemberRate: commitment.Rate.TeamMemberRate,
                  LineManagerRate: commitment.Rate.LineManagerRate,
                  TDMRate: commitment.Rate.TDMRate,
                  ProjectLeadRate: commitment.Rate.ProjectLeadRate,
                  PortfolioManagerRate: commitment.Rate.PortfolioManagerRate,
                  HrFinanceRate: commitment.Rate.HrFinanceRate,
                  CharityRate: commitment.Rate.CharityRate,
                  OffshoreEntityRate: commitment.Rate.OffshoreEntityRate,
                  ToolsRate: commitment.Rate.ToolsRate,
                  FunctionalManagerRate: commitment.Rate.FunctionalManagerRate,
                  BusinessManagerRate: commitment.Rate.BusinessManagerRate,
                  SubsidyRate: commitment.Rate.SubsidyRate,
                  MiscRate: commitment.Rate.MiscRate,
                }
              : {
                  TeamMemberRate: 0,
                  LineManagerRate: 1,
                  TDMRate: 0,
                  ProjectLeadRate: 0,
                  PortfolioManagerRate: 0,
                  HrFinanceRate: 0.5,
                  CharityRate: 0,
                  OffshoreEntityRate: 0.2,
                  ToolsRate: 0.2,
                  FunctionalManagerRate: 1,
                  BusinessManagerRate: 1,
                  SubsidyRate: 0,
                  MiscRate: 0,
                },
          };
          if (commitment.Rate) {
            this.model.Rate = {
              TeamMemberRate: commitment.Rate.TeamMemberRate,
              LineManagerRate: commitment.Rate.LineManagerRate,
              TDMRate: commitment.Rate.TDMRate,
              ProjectLeadRate: commitment.Rate.ProjectLeadRate,
              PortfolioManagerRate: commitment.Rate.PortfolioManagerRate,
              HrFinanceRate: commitment.Rate.HrFinanceRate,
              CharityRate: commitment.Rate.CharityRate,
              OffshoreEntityRate: commitment.Rate.OffshoreEntityRate,
              ToolsRate: commitment.Rate.ToolsRate,
              FunctionalManagerRate: commitment.Rate.FunctionalManagerRate,
              BusinessManagerRate: commitment.Rate.BusinessManagerRate,
              SubsidyRate: commitment.Rate.SubsidyRate,
              MiscRate: commitment.Rate.MiscRate,
            };
          } else {
            this.model.Rate = {
              TeamMemberRate: 0,
              LineManagerRate: 1,
              TDMRate: 0,
              ProjectLeadRate: 0,
              PortfolioManagerRate: 0,
              HrFinanceRate: 0.5,
              CharityRate: 0,
              OffshoreEntityRate: 0.2,
              ToolsRate: 0.2,
              FunctionalManagerRate: 1,
              BusinessManagerRate: 1,
              SubsidyRate: 0,
              MiscRate: 0,
            };
          }
          if (this.model.LineManagerId) {
            const lineManagerItem = this.lineManagers.find((c: any) => c.id === this.model.LineManagerId);
            if (lineManagerItem) {
              this.selectedLineManager = { Name: lineManagerItem.Name, id: lineManagerItem.id };
            }
          }

          if (this.model.ProjectLeadId) {
            const projectLeadItem = this.projectLeads.find((c: any) => c.id === this.model.ProjectLeadId);
            if (projectLeadItem) {
              this.selectedProjectLead = { Name: projectLeadItem.Name, id: projectLeadItem.id };
            }
          }

          if (this.model.PortfolioManagerId) {
            const portfolioManagerItem = this.portfolioManagers.find((c: any) => c.id === this.model.PortfolioManagerId);
            if (portfolioManagerItem) {
              this.selectedPortfolioManager = { Name: portfolioManagerItem.Name, id: portfolioManagerItem.id };
            }
          }

          if (this.model.TDMId) {
            const tdmItem = this.tdms.find((c: any) => c.id === this.model.TDMId);
            if (tdmItem) {
              this.selectedTDM = { Name: tdmItem.Name, id: tdmItem.id };
            }
          }

          this.selectedPublicHolidayZone = commitment.PublicHolidayZoneId;
          if (commitment.Customers) {
            this.selectedCustomer = commitment.Customers.map((x: any) => {
              return { id: x.id, Name: x.CuFullName };
            })[0];
            this.projects = await this.loadProjects();
          }
          if (commitment.TeamMembers) {
            this.selectedTeamMember = commitment.TeamMembers.map((x: any) => {
              return { id: x.id, Name: x.TmFullName };
            })[0];
          }
          if (commitment.ProjectIds) {
            this.selectedProject = this.projects.filter((item) => commitment.ProjectIds[0] == item.id)[0];
          }
          this.hasTimesheet = commitment.HasTimesheet;
        }
        this.loading = false;
      } else {
        this.model = {
          IsActive: true,
          Name: "",
          SeniorityLevel: 0,
          StartDate: null,
          EndDate: null,
          HoursPerWeekMax: null,
          HoursPerWeekMin: null,
          HoursPerWeekRadio: false,
          WokingDays: [],
          LineManagerId: "",
          ProjectLeadId: "",
          PortfolioManagerId: "",
          TDMId: "",
          Rate: {
            TeamMemberRate: 0,
            LineManagerRate: 1,
            TDMRate: 0,
            ProjectLeadRate: 0,
            PortfolioManagerRate: 0,
            HrFinanceRate: 0.5,
            CharityRate: 0,
            OffshoreEntityRate: 0.2,
            ToolsRate: 0.2,
            FunctionalManagerRate: 1,
            BusinessManagerRate: 1,
            SubsidyRate: 0,
            MiscRate: 0,
          },
        };
      }
    },
  },
  computed: {
    selectedHoursPerWeekMin: {
      get(): number | null {
        return this.model.HoursPerWeekMin;
      },
      set(value: any): void {
        this.model.HoursPerWeekMin = value ? parseFloat(value) : null;
      },
    },
    selectedHoursPerWeekMax: {
      get(): number | null {
        return this.model.HoursPerWeekMax;
      },
      set(value: any): void {
        this.model.HoursPerWeekMax = value ? parseFloat(value) : null;
      },
    },
  },
});
</script>
