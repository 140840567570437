import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, withKeys as _withKeys, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_NewBillableItem = _resolveComponent("NewBillableItem")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, {
      loading: _ctx.loading,
      disabled: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-title-left" }, "Billable Item List", -1)),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  class: "primary_btn",
                  "prepend-icon": "mdi-plus",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewBillableItem = true))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("New Billable Item")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.billableItems,
              loading: _ctx.loading,
              headers: _ctx.headers,
              "fixed-header": ""
            }, {
              "item.Action": _withCtx(({ item }) => [
                _createVNode(_component_v_menu, null, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({
                      color: "black",
                      variant: "text",
                      size: "small"
                    }, props, { icon: "mdi-dots-vertical" }), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              title: "Edit",
                              onClick: ($event: any) => (_ctx.editBillableItem(item))
                            }, null, 8, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["items", "loading", "headers"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showNewBillableItem,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showNewBillableItem) = $event)),
      persistent: "",
      "max-width": "600px",
      onKeydown: _withKeys(_ctx.closeNewBillingItemPopup, ["esc"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NewBillableItem, {
          onClose: _ctx.closeNewBillingItemPopup,
          onSave: _ctx.createNewBillingItem,
          billableItem: _ctx.selectedBillableItem
        }, null, 8, ["onClose", "onSave", "billableItem"])
      ]),
      _: 1
    }, 8, ["modelValue", "onKeydown"])
  ], 64))
}