import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnalyticalReport = _resolveComponent("AnalyticalReport")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.customers.length > 0)
      ? (_openBlock(), _createBlock(_component_AnalyticalReport, {
          key: 0,
          ref: "analyticalReport",
          customers: _ctx.customers,
          reportLoading: _ctx.reportLoading,
          onCreateReport: _ctx.createReport,
          timesheets: _ctx.timesheets
        }, null, 8, ["customers", "reportLoading", "onCreateReport", "timesheets"]))
      : _createCommentVNode("", true)
  ]))
}