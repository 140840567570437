import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_2 = { class: "zoneList" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "selectedZoneTitle" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _cache[9] || (_cache[9] = _createTextVNode(" Public Holidays ")),
            _createVNode(_component_v_divider, {
              class: "mx-4",
              inset: "",
              vertical: ""
            }),
            (_ctx.zoneMessage)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.zoneMessage), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_v_spacer)
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_divider),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "mt-2",
                  cols: "12",
                  md: "4",
                  xs: "12",
                  sm: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          class: "zoneTxt",
                          cols: "12",
                          md: "8",
                          xs: "12",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              modelValue: _ctx.zoneTitle,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.zoneTitle) = $event)),
                              dense: "",
                              outlined: "",
                              class: "mt-md-3",
                              label: "Zone",
                              type: "text"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          class: "zoneBut mt-7",
                          cols: "12",
                          md: "4",
                          xs: "12",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              class: "mt-md-3 addZoneBut",
                              color: "primary",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addZone()))
                            }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode(" Add ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "11",
                      xs: "12",
                      sm: "6",
                      class: "zoneMenu"
                    }, {
                      default: _withCtx(() => [
                        _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Zones", -1)),
                        _createVNode(_component_TextField, {
                          dense: "",
                          outlined: "",
                          class: "mt-md-3 mb-0",
                          modelValue: _ctx.selectedHolidayName,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedHolidayName) = $event)),
                          label: "Search",
                          type: "text"
                        }, null, 8, ["modelValue"]),
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tempPublicHolidays, (item) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: item.Id
                              }, [
                                _createElementVNode("a", {
                                  style: _normalizeStyle([item.IsClicked ? { background: 'rgb(226 226 226)' } : { 'background-color': 'none' }]),
                                  onClick: ($event: any) => (_ctx.selectedHoliday = item)
                                }, _toDisplayString(item.Title), 13, _hoisted_3)
                              ]))
                            }), 128))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  class: "zoneDetail mt-2 pl-0",
                  cols: "12",
                  md: "8",
                  xs: "12",
                  sm: "6"
                }, {
                  default: _withCtx(() => [
                    (_ctx.selectedHoliday)
                      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "7",
                              xs: "12",
                              sm: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextField, {
                                  modelValue: _ctx.holidayTitle,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.holidayTitle) = $event)),
                                  dense: "",
                                  outlined: "",
                                  class: "mt-md-3",
                                  label: "Holiday",
                                  type: "text"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "5",
                              xs: "12",
                              sm: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "8",
                                      xs: "12",
                                      sm: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_DateInputField, {
                                          label: "Date",
                                          "first-day-of-week": "1",
                                          modelValue: _ctx.holidayDate,
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.holidayDate) = $event)),
                                          "hide-actions": "",
                                          placeholder: "Date",
                                          "prepend-icon": ""
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "4",
                                      xs: "12",
                                      sm: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          class: "mt-9 addHolidayButton",
                                          color: "primary",
                                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addDates()))
                                        }, {
                                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                                            _createTextVNode(" Add ")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.selectedHoliday && _ctx.selectedHoliday.Id)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          class: "pb-0",
                          cols: "12",
                          md: "12",
                          xs: "12",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "pb-0 pl-0",
                                  cols: "12",
                                  md: "10",
                                  xs: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.selectedHoliday.Title), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  class: "pb-0 deleteZone",
                                  cols: "12",
                                  md: "2",
                                  xs: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      class: "error",
                                      title: "Delete",
                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removeZone()))
                                    }, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode("Delete")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.holidayDates && _ctx.holidayDates.length > 0)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 2,
                          class: "holidaysList mb-5 pt-0 pl-0",
                          cols: "12",
                          md: "12",
                          xs: "12",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_data_table, {
                              class: "holidayItem holidayColumnTitle mt-1 mr-0",
                              items: _ctx.holidayDates,
                              headers: _ctx.headers,
                              loading: _ctx.loading
                            }, {
                              "item.Title": _withCtx(({ item }) => [
                                _createElementVNode("span", null, _toDisplayString(item.Title), 1)
                              ]),
                              "item.Date": _withCtx(({ item }) => [
                                _createElementVNode("span", null, _toDisplayString(item.Date), 1)
                              ]),
                              "item.Actions": _withCtx(({ item }) => [
                                _createElementVNode("div", null, [
                                  _createVNode(_component_v_btn, {
                                    class: "error removeDateButton",
                                    fab: "",
                                    "x-small": "",
                                    title: "Delete",
                                    onClick: ($event: any) => (_ctx.removeDate(item))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                                          _createTextVNode("mdi-delete")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ])
                              ]),
                              _: 1
                            }, 8, ["items", "headers", "loading"])
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_v_col, {
                          key: 3,
                          class: "holidayMessage mt-3"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.selectedHoliday)
                              ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.selectedHoliday.Title) + " doesn't have any Dates", 1))
                              : (_openBlock(), _createElementBlock("h1", _hoisted_6, "Please select a Zone"))
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showConfirm,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showConfirm) = $event)),
      "max-width": "500",
      onKeydown: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          message: _ctx.confirmModel,
          onConfirm: _ctx.confirmRemove,
          onCancel: _ctx.cancelRemove
        }, null, 8, ["message", "onConfirm", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}