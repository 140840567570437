<template>
  <div>
    <h1>Welcome to CODE CLAN Admin Panel!</h1>
    <div v-if="!isAuthenticated">You are not loggined, you will redirect to login page in {{ remainTime }} seconds!</div>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      remainTime: 5,
      invtLogin: 0,
    };
  },
  mounted() {
    if (!this.isAuthenticated) {
      setTimeout(async () => {
        clearInterval(this.invtLogin);
        await this.$router.push("/login");
      }, 5000);
      this.invtLogin = setInterval(() => {
        this.remainTime -= 1;
      }, 1000);
    }
  },
  computed: {
    isAuthenticated(): boolean {
      return store.getters.userIsAdmin;
    },
  },
  destroyed() {
    if (this.invtLogin) {
      clearInterval(this.invtLogin);
    }
  },
});
</script>
