String.prototype.withComma = function () {
  return this ? this.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this;
};
Number.prototype.withComma = function () {
  return this ? this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this;
};
String.prototype.toCurrency = function (currencyUnit) {
  return this ? `${currencyUnit}${this.withComma()}` : this;
};
Number.prototype.toCurrency = function (currencyUnit) {
  return this ? `${currencyUnit}${this.withComma()}` : this;
};

declare global {
  interface String {
    withComma: () => string;
    toCurrency: (currencyUnit: string) => string;
  }
  interface Number {
    withComma: () => string;
    toCurrency: (currencyUnit: string) => string;
  }
}

export default "";
