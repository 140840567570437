import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wazuh_dashboard = _resolveComponent("wazuh-dashboard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_wazuh_dashboard, {
      vmId: _ctx.vmId,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Close()))
    }, null, 8, ["vmId"])
  ]))
}