import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Welcome to CODE CLAN Admin Panel!", -1)),
    (!_ctx.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "You are not loggined, you will redirect to login page in " + _toDisplayString(_ctx.remainTime) + " seconds!", 1))
      : _createCommentVNode("", true)
  ]))
}