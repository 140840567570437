<template>
  <v-card>
    <v-card-title class="d-flex justify-end">
      <v-btn icon @click="closeDialog" class="ml-auto" variant="text">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container>
        <span class="headline">{{ taskTitle }}</span>
      </v-container>

      <v-divider></v-divider>
      <v-form class="form-inputs" ref="frmTask">
        <v-container class="py-4">
          <v-row>
            <v-col cols="12" :md="isHiringmanager ? 6 : 4" xs="12" sm="12">
              <ComboBoxField
                :rules="[validations.required]"
                :readonly="isHiringmanager"
                :items="namePosition"
                dense
                v-model="newTask.PositionId"
                label="Job Opening"
                placeholder="Job Opening"
                item-title="Title"
                item-value="Id"
                showRequired
                outlined
                :return-object="false"
              />
            </v-col>
            <v-col cols="12" :md="isHiringmanager ? 6 : 4" xs="12" sm="12">
              <ComboBoxField
                :rules="[validations.required]"
                clearable
                label="Task"
                placeholder="Task"
                showRequired
                outlined
                :items="taskList"
                dense
                item-title="Name"
                item-value="Id"
                @update:modelValue="onTaskTemplateSelected"
                v-model="selectedTask"
              >
              </ComboBoxField>
            </v-col>
            <v-col v-if="!isHiringmanager" class="mt-8" cols="12" :md="isHiringmanager ? 6 : 4" xs="12" sm="12">
              <v-switch :readonly="isHiringmanager" :disabled="disableSwitch" v-model="newTask.isDefault" label="Add to task"></v-switch>
            </v-col>

            <v-col cols="12" md="12" xs="12" sm="12">
              <TextAreaField
                :rules="[validations.required]"
                :readonly="isHiringmanager"
                v-model="newTask.Description"
                dense
                outlined
                label="Description"
                showRequired
                placeholder="Description"
              />
            </v-col>

            <v-col cols="12" :md="taskTitle == 'Edit Task' ? 4 : 6" xs="12" sm="12">
              <DateInputField
                label="Task Date"
                first-day-of-week="1"
                :rules="[validations.required]"
                v-model="selectedTaskDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
            <v-col cols="12" :md="taskTitle == 'Edit Task' ? 4 : 6" xs="12" sm="12">
              <TextField
                :rules="[validations.required, validations.integer]"
                :readonly="isHiringmanager"
                v-model="newTask.OrderNumber"
                dense
                outlined
                label="Priority"
                showRequired
                placeholder="Priority"
                type="number"
              />
            </v-col>
            <v-col cols="12" md="4" xs="12" sm="12" v-if="taskTitle == 'Edit Task'">
              <ComboBoxField
                :rules="[validations.required]"
                :readonly="isHiringmanager"
                v-model="selectedTaskStatus"
                :items="suggestedStatuses"
                dense
                outlined
                label="Status"
                showRequired
                placeholder="Status"
              />
            </v-col>

            <v-col cols="12" md="12" xs="12" sm="12" class="add-button">
              <v-btn class="secondary" variant="elevated" @click="closeDialog"><v-icon>mdi-close</v-icon> cancel</v-btn>
              <v-btn color="primary" @click="AddTaskPosition">
                <v-icon left>mdi-check</v-icon>
                {{ Savebutton }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  PositionApi,
  TaskTemplateApi,
  TaskTemplateModel,
  EntitySummaryModel,
  PositionTaskResponseModel,
  PositionTaskModel,
  PositionTaskDetailResponseModel,
} from "shared-components/src/services/openApi/api";
import validations from "shared-components/src/utils/validations";
import moment from "moment";
import store from "@/store";

var positionApi = new PositionApi();
var taskApi = new TaskTemplateApi();

export default defineComponent({
  props: {
    task: {
      type: Object as () => PositionTaskDetailResponseModel,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isHiringmanager: {
      type: Boolean,
    },
  },
  data() {
    return {
      validations,
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      selectedTask: null as TaskTemplateModel | null,
      selectedTaskDate: null as Date | null,
      taskList: [] as TaskTemplateModel[],
      namePosition: [] as EntitySummaryModel[],
      dateMenu: false,
      newTask: {
        Id: "",
        PositionId: "",
        TaskTemplateId: "",
        TaskTemplateName: "",
        Title: "",
        Date: "",
        OrderNumber: 1,
        Description: "",
        Status: 0,
        isDefault: false,
      } as PositionTaskModel,
      suggestedStatuses: ["New", "Active", "Resolved", "Closed", "Archived"],
      Active: false,
      disableSwitch: false,
      selectedTaskStatus: "",
    };
  },
  async mounted() {
    await this.getTaskList();
    await this.getPositonSummeryList();
    if (this.task && this.task.Id) {
      this.selectedTaskDate = moment(this.task.Date).toDate();

      if (this.task.TaskTemplateId) {
        this.selectedTask = this.taskList?.find((i) => i.Id == this.task.TaskTemplateId) ?? null;
        this.newTask.TaskTemplateId = this.task.TaskTemplateId;
      } else {
        this.selectedTask = { Id: this.task.TaskTemplateName, Name: this.task.TaskTemplateName } as TaskTemplateModel;
        this.newTask.TaskTemplateName = this.task.TaskTemplateName;
      }

      this.selectedTaskStatus = this.task.Status ?? "New";
      this.newTask.Date = this.task.Date;
      this.newTask.Description = this.task.Description;
      this.newTask.Id = this.task.Id;
      this.newTask.OrderNumber = this.task.OrderNumber;
      this.newTask.PositionId = this.task.PositionId;
    }
  },
  computed: {
    taskTitle(): string {
      return this.isEditing ? "Edit Task" : "New Task";
    },
    Savebutton(): string {
      return this.isEditing ? "Update Task" : "Add Task";
    },
  },
  methods: {
    onTaskTemplateSelected(item: any) {
      if (item) {
        if (item.Name) {
          this.newTask.Description = item.Description;
          this.newTask.TaskTemplateName = item.Name;
          this.newTask.isDefault = false;
          this.disableSwitch = true;
        } else {
          this.newTask.TaskTemplateName = item;
          this.disableSwitch = false;
        }
      }
    },
    closeDialog() {
      this.$emit("close-task-dialog");
    },
    async getTaskList() {
      this.taskList = (await taskApi.geTaskTemplateList()).data.Items as TaskTemplateModel[];
    },
    async getPositonSummeryList() {
      await positionApi.getPositionSummaryList().then((data) => {
        this.namePosition = data.data;
      });
    },
    async AddTaskPosition() {
      const isTaskValid = await (this.$refs.frmTask as any).validate();
      if (isTaskValid.valid) {
        this.newTask.Date = this.selectedTaskDate?.toDateString();

        if (this.taskList && this.selectedTask) {
          var existTask = this.taskList.find((task: any) => task.Name.trim().toLowerCase() === this.newTask?.TaskTemplateName?.trim().toLowerCase());

          if (existTask) {
            this.newTask.TaskTemplateName = "";
            this.newTask.TaskTemplateId = existTask.Id;
          }
        }

        if (this.selectedTaskStatus) {
          if (this.selectedTaskStatus === "New") {
            this.newTask.Status = 0;
          } else if (this.selectedTaskStatus === "Active") {
            this.newTask.Status = 1;
          } else if (this.selectedTaskStatus === "Resolved") {
            this.newTask.Status = 2;
          } else if (this.selectedTaskStatus === "Closed") {
            this.newTask.Status = 3;
          } else if (this.selectedTaskStatus === "Archived") {
            this.newTask.Status = 4;
          }
        }
        try {
          const response = await positionApi.savePositionTask(this.newTask);
          this.$emit("TaskSaved", response.data);

          this.newTask = {
            Id: "",
            PositionId: "",
            TaskTemplateId: "",
            TaskTemplateName: "",
            Title: "",
            Date: "",
            OrderNumber: 0,
            Description: "",
            Status: 0,
            isDefault: false,
          } as PositionTaskModel;
          store.dispatch("showSuccessMessage", "Task has been successfully added.");
        } catch (error) {
          console.error("Error adding task:", error);
          store.dispatch("showErrorMassage", "There is an error!");
        }
      }
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
  font-size: 1.5rem;
}

.task-status {
  font-weight: bold;
  font-size: 1.2rem;
}

.task-details {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 1rem;
}

.task-section {
  margin-top: 16px;
}

.task-title {
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}

.add-button {
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
</style>
