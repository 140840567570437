import HRCommitmentReport from "shared-components/src/models/HRCommitmentReport";
import Utils from "shared-components/src/utils/Utils";
import ApiService from "./ApiService";

export default class HRCommitmentReportService {
  public static async getList(
    startDate: Date,
    endDate: Date,
    selectedCustomer: any,
    selectedCommitment: any,
    selectedTeammember: any
  ): Promise<HRCommitmentReport[]> {
    return new Promise((resolve, reject) => {
      const sDate = Utils.addTimezoneOffsetToStartDate(startDate) as Date;
      const eDate = Utils.addTimezoneOffsetToEndDate(endDate) as Date;
      ApiService.post(`/reports/hrcommitments`, { startDate: sDate, endDate: eDate, selectedCustomer, selectedCommitment, selectedTeammember })
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const retVal = result.data.map((doc: any) => {
              const item = { id: doc.id, ...doc } as HRCommitmentReport;
              return item;
            });
            resolve(retVal);
          }
        })
        .catch((commitmentError) => reject(commitmentError));
    });
  }
}
