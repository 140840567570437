<template>
  <div class="text-end">
    <v-btn class="mx-3" :disabled="loading" append-icon="mdi-refresh" text="Refresh" variant="outlined" @click="fetchDataTables"></v-btn>
  </div>
  <v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4" fixed-tabs>
    <v-tab :value="1" prepend-icon="mdi-import">Import</v-tab>
    <v-tab :value="2" prepend-icon="mdi-export">Export</v-tab>
  </v-tabs>
  <v-tabs-window v-model="tab">
    <v-tabs-window-item :key="1" :value="1">
      <v-row class="mt-3 mx-3 justify-end">
        <div class="text-end">
          <v-btn
            class="mx-3"
            :disabled="!anyFileSelected"
            append-icon="mdi-delete"
            text="Delete"
            variant="outlined"
            @click="deleteSelectedFiles"
          ></v-btn>
        </div>
        <div class="text-end">
          <v-btn
            class="mx-3"
            :disabled="!anyFileSelected"
            append-icon="mdi-import"
            text="Import"
            variant="outlined"
            @click="importSelectedFiles"
          ></v-btn>
        </div>
      </v-row>
      <v-data-table v-model="selectedFiles" :items="fileInfo" :loading="loading" item-value="name" show-select>
        <template v-slot:loading>
          <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>
      </v-data-table>
      <v-row justify="start">
        <v-col cols="4"><v-file-input v-model="files" chips multiple></v-file-input></v-col>
        <v-col cols="2">
          <v-btn
            class="mx-2"
            :disabled="!anyFileToUpload"
            append-icon="mdi-upload-multiple"
            text="Upload"
            variant="outlined"
            @click="uploadFiles"
          ></v-btn>
        </v-col>
      </v-row>
    </v-tabs-window-item>
    <v-tabs-window-item :key="2" :value="2">
      <div class="text-end">
        <v-btn
          class="mx-3"
          :disabled="!selectedSomeUser"
          append-icon="mdi-export"
          text="Export"
          variant="outlined"
          @click="exportSelectedUsers"
        ></v-btn>
      </div>
      <v-divider class="mt-4" inset></v-divider>
      <v-data-table v-model="selectedUsers" :items="users" :loading="loading" item-value="id" show-select>
        <template v-slot:loading>
          <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>
      </v-data-table>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  BulkImportExportApi,
  TeammemberApi,
  TeammemberSummaryDetailsModel,
  UploadFileRequestModel,
} from "shared-components/src/services/openApi/api";
import store from "@/store";
import ApiService from "shared-components/src/services/ApiService";

export default defineComponent({
  data() {
    return {
      loading: false,
      tab: null,
      files: [],
      selectedFiles: [] as string[],
      fileInfo: [
        {
          name: "",
        },
      ],
      users: [] as TeammemberSummaryDetailsModel[],
      selectedUsers: [] as string[],
    };
  },
  methods: {
    uploadFiles() {
      this.loading = true;
      try {
        var fileRequestModel = [] as UploadFileRequestModel[];
        this.files.forEach((file) => {
          var f = { file: file } as UploadFileRequestModel;
          fileRequestModel.push(f);
        });
        this.files.forEach((file) => {
          ApiService.upload(`/bulk/upload`, file).then((res: any) => {
            store.dispatch("showSuccessMessage", "Uploaded Succesfully");
          });
        });
      } finally {
        this.loading = false;
      }
    },
    importSelectedFiles() {
      this.loading = true;
      try {
        new BulkImportExportApi()
          .bulkImportByAdmin(this.selectedFiles.map((name) => name))
          .then((rsp: any) => store.dispatch("showSuccessMessage", rsp.data));
      } finally {
        this.loading = false;
      }
    },
    exportSelectedUsers() {
      this.loading = true;
      try {
        new BulkImportExportApi().bulkExportByAdmin(this.selectedUsers.map((id) => id)).then((response: any) => {
          var downloadLink = response.data;
          window.open(downloadLink, "_blank");
        });
      } finally {
        this.loading = false;
      }
    },
    deleteSelectedFiles() {
      this.loading = true;
      try {
        new BulkImportExportApi()
          .deleteFilesByAdmin(this.selectedFiles.map((name) => name))
          .then((rsp: any) => store.dispatch("showSuccessMessage", rsp.data));
      } finally {
        this.loading = false;
      }
    },
    fetchDataTables() {
      this.loading = true;
      try {
        new BulkImportExportApi().retrieveAvailableFilesByAdmin().then((items: any) => {
          this.fileInfo = items.data.map((item: string) => ({ name: item }));
        });
        new TeammemberApi().getTeammemberListSummaryDetails().then((items: any) => {
          this.users = items.data.map((item: TeammemberSummaryDetailsModel) => ({ id: item.Id, fullName: item.FullName, email: item.Email }));
        });
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchDataTables();
  },
  computed: {
    selectedAllUser() {
      return this.selectedUsers.length === this.users.length;
    },
    anyFileSelected() {
      return this.selectedFiles.length > 0;
    },
    anyFileToUpload() {
      return this.files.length > 0;
    },
    selectedSomeUser() {
      return this.selectedUsers.length > 0;
    },
    selectionTitle() {
      return "User Count";
    },
    selectionSubtitle() {
      if (this.selectedSomeUser) return this.selectedUsers.length;
      return "Make a selection above!";
    },
  },
});
</script>
