import { SystemErrors } from "shared-components/src/definitions/systemErrors";
import { CryptoCurrency } from "shared-components/src/models/CryptoCurrency";
import { BankAccount } from "shared-components/src/models/BankAccount";
import { WiseAccount } from "shared-components/src/models/WiseAccount";
import { Paypal } from "shared-components/src/models/Paypal";
import ApiService from "shared-components/src/services/ApiService";

export default class PaymentMethodService {
  public static getTeammemberCryptoCurrency(teammemberId: any): Promise<CryptoCurrency | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/admin/paymentMethod/cryptoCurrency/${teammemberId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static getTeammemberBankAccount(teammemberId: any): Promise<BankAccount | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/admin/paymentMethod/bankAccount/${teammemberId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getTeammemberWiseAccount(teammemberId: any): Promise<WiseAccount | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/admin/paymentMethod/wiseAccount/${teammemberId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getTeammemberPaypal(teammemberId: any): Promise<Paypal | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/admin/paymentMethod/paypal/${teammemberId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
