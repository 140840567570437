import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight-light" }
const _hoisted_2 = { class: "font-weight-light small" }
const _hoisted_3 = { class: "font-weight-light small" }
const _hoisted_4 = { class: "font-weight-light small" }
const _hoisted_5 = { class: "font-weight-light small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_new_user = _resolveComponent("new-user")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_role = _resolveComponent("role")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "mt-3" }, "Users list", -1)),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  class: "mt-3 ml-6 mr-3",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewUser = true))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("mdi-plus")
                      ])),
                      _: 1
                    }),
                    _cache[9] || (_cache[9] = _createTextVNode(" New User"))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { md: "6" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TextField, {
                      outlined: "",
                      placeholder: "Search",
                      class: "mt-5",
                      clearable: "",
                      "clear-icon": "mdi-close",
                      modelValue: _ctx.searchText,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_data_table, {
              items: _ctx.filterdDesserts,
              headers: _ctx.headers,
              loading: _ctx.loading
            }, {
              "item.email": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.email), 1)
              ]),
              "item.uid": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(item.id), 1)
              ]),
              "item.displayName": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.displayName), 1)
              ]),
              "item.metadata.creationTime": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatDate(item.creationDate)), 1)
              ]),
              "item.roles": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(item.roles.join(", ")), 1)
              ]),
              "item.Actions": _withCtx(({ item }) => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_btn, {
                    class: "mt-1",
                    variant: "text",
                    size: "small",
                    title: "edit item",
                    onClick: ($event: any) => (_ctx.edit(item)),
                    icon: "mdi-circle-edit-outline"
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            }, 8, ["items", "headers", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showNewUser,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showNewUser) = $event)),
      persistent: "",
      fullscreen: "",
      onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.showNewUser = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_new_user, {
          onSaved: _ctx.userSaved,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showNewUser = false))
        }, null, 8, ["onSaved"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showRole,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showRole) = $event)),
      "max-width": "300",
      onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.showRole = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        (_ctx.showRole)
          ? (_openBlock(), _createBlock(_component_role, {
              key: 0,
              onSaved: _ctx.userSaved,
              selectedUser: _ctx.selectedUser,
              onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showRole = false))
            }, null, 8, ["onSaved", "selectedUser"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}