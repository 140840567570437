<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        VM Support
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="showNewSupportModal = true" prepend-icon="mdi-plus"> New Support Notification </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="align-center">
        <v-col md="2">
          <SelectField :items="statusItems" item-title="title" item-value="value" v-model="status" label="Status" outlined dense hide-details />
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn color="secondary" class="mr-4" @click="clearFilters"> <v-icon left>mdi-close</v-icon> Clear </v-btn>
          <v-btn color="primary" @click="fetchSupportList"><v-icon left>mdi-check</v-icon> Filter </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table-server
          :items="supportList"
          :headers="headers"
          :loading="loading"
          :page="paginationModel.page"
          :items-per-page="paginationModel.numberOfPages"
          @update:options="(event: any) => tableOptions = event"
          :items-length="paginationModel.totalItemsCount"
          item-key="Id"
        >
          <template v-slot:item.ShortDesc="{ item }">
            {{ item.Title }}
          </template>
          <template v-slot:item.Sender="{ item }">
            {{ titleCase(item.Sender ?? "") }}
          </template>
          <template v-slot:item.CreatedAt="{ item }">
            {{ formatDate(item.CreatedOn) }}
          </template>
          <template v-slot:item.LastReplyDateTime="{ item }">
            {{ formatDate(item.LastReplyOn) }}
          </template>
          <template v-slot:item.Status="{ item }">
            <v-chip v-if="item.Status" :color="getStatusColor(item.Status)" dark>
              {{ item.Status }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y location="bottom" scroll-strategy="close">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
              </template>
              <v-list>
                <v-list-item @click="viewItem(item.Id)" prepend-icon="mdi-comment-edit-outline " title="View" />
                <v-list-item @click="viewAction(item)" prepend-icon="mdi-check-circle-outline " :title="`Set as ${getNewActionStatus(item)}`" />
              </v-list>
            </v-menu>
          </template>
        </v-data-table-server>
      </v-row>
    </v-card-text>
    <v-dialog width="600" v-model="showNewSupportModal" @keypress.esc="closeNewSupportModal">
      <VdSupport @cancel="closeNewSupportModal" @sendRequest="createSupportNotification" :isSupport="true" />
    </v-dialog>
    <v-dialog width="800" v-if="showViewSupportModal" v-model="showViewSupportModal" @keypress.esc="closeViewSupportModal">
      <ViewSupportItem @close="closeViewSupportModal" :supportId="selectedSupportId" />
    </v-dialog>
    <v-dialog width="800" v-if="showActionSupportModal" v-model="showActionSupportModal" @keypress.esc="closeActionSupportModal">
      <ViewSupportAction @close="closeActionSupportModal" :newStatus="newSupportActionStatus" :supportId="selectedSupportId" />
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import CreateOrEditVM from "./CreateOrEditVM.vue";
import VdSupport from "shared-components/src/components/Vm/VdSupport.vue";
import { AdminSupportNotificationItemModel, NotificationApi, PaginationRequestModel } from "shared-components/src/services/openApi/api";
import store from "@/store";
import moment from "moment";
import ViewSupportItem from "./ViewSupportItem.vue";
import ViewSupportAction from "./ViewSupportAction.vue";
import UrlHelper from "shared-components/src/utils/UrlHelper";

export default defineComponent({
  components: { ConfirmDialog, CreateOrEditVM, VdSupport, ViewSupportItem, ViewSupportAction },
  async mounted() {
    //await this.fetchSupportList();
    this.checkAndLoadUrlItems();
  },
  data() {
    return {
      showNewSupportModal: false,
      showViewSupportModal: false,
      showActionSupportModal: false,
      newSupportActionStatus: "",
      paginationModel: {
        page: 1,
        totalItemsCount: 0 as number | undefined,
        numberOfPages: 10,
        sortProp: "CreatedAt",
        sortOrder: "desc",
      },
      status: "All",
      tableOptions: {} as any,
      tmLoading: true,
      loading: false,
      supportList: [] as AdminSupportNotificationItemModel[],
      selectedSupportId: null as string | null,
      headers: [
        { title: "Title", value: "ShortDesc", sortable: true },
        { title: "Sender", value: "Sender", width: 200 },
        { title: "Message", value: "Message" },
        { title: "Created on", value: "CreatedAt", width: 200, sortable: true },
        { title: "Last Reply On", value: "LastReplyDateTime", width: 200, sortable: true },
        { title: "Status", value: "Status" },
        { title: "Action", value: "action", align: "end" },
      ],
      statusItems: [
        {
          title: "All",
          value: "All",
        },
        {
          title: "New",
          value: "New",
        },
        {
          title: "Resolved",
          value: "Resolved",
        },
      ],
    };
  },
  computed: {
    isSupport(): boolean {
      return store.getters.userIsSupport ?? false;
    },
  },
  methods: {
    getNewActionStatus(item: AdminSupportNotificationItemModel) {
      return item.Status === "New" ? "Resolved" : "New";
    },
    clearFilters() {
      this.status = "All";
      this.fetchSupportList();
    },
    async checkAndLoadUrlItems() {
      const urlItems = UrlHelper().notification().getUrlHashItems();
      if (urlItems.id && urlItems.action == "view") {
        this.viewItem(urlItems.id);
      }
    },
    closeNewSupportModal() {
      this.showNewSupportModal = false;
    },
    closeViewSupportModal() {
      this.showViewSupportModal = false;
    },
    closeActionSupportModal() {
      this.showActionSupportModal = false;
    },
    async createSupportNotification(event: any) {
      const requestModel = { Message: event.Message, Title: event.Title } as AdminSupportNotificationItemModel;
      await new NotificationApi().creatSupportNotifications(requestModel);
      this.showNewSupportModal = false;
      store.dispatch("showSuccessMessage", "Vd support request submitted successfully");
      await this.fetchSupportList();
    },
    getStatusColor(status: string) {
      switch (status) {
        case "Resolved":
          return "success";
        case "Rejected":
          return "error";
        default:
          return "info";
      }
    },
    viewItem(itemId: string) {
      this.selectedSupportId = itemId;
      this.showViewSupportModal = true;
    },
    viewAction(item: AdminSupportNotificationItemModel) {
      this.selectedSupportId = item && item.Id ? item.Id : "";
      this.newSupportActionStatus = this.getNewActionStatus(item);
      this.showActionSupportModal = true;
    },
    async fetchSupportList() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;

        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.paginationModel.sortOrder,
          SortProp: this.paginationModel.sortProp,
        } as PaginationRequestModel;
        const result = (await new NotificationApi().getSupportNotifications(this.status, paginationModel)).data;
        this.supportList = result.Items as AdminSupportNotificationItemModel[];
        this.paginationModel.totalItemsCount = result.Total;
      } finally {
        this.loading = false;
      }
    },
    formatDate(strDate: string) {
      if (strDate) {
        return moment(strDate).format("YYYY-MM-DD HH:MM:ss");
      }
      return "-";
    },
    titleCase(str: string) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
  },
  watch: {
    showViewSupportModal(newVal, oldValue) {
      if (!newVal && oldValue != newVal) {
        this.fetchSupportList();
      }
    },
    showActionSupportModal(newVal, oldValue) {
      if (!newVal && oldValue != newVal) {
        this.fetchSupportList();
      }
    },
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.paginationModel.sortProp = newVal.sortBy[0].key;
          this.paginationModel.sortOrder = newVal.sortBy[0].order;
        }
        this.fetchSupportList();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.selected-row {
  background-color: red;
}
.vm-checkbox {
  height: 50px;
}
.vm-row:hover {
  cursor: pointer;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
