import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    "items-per-page": _ctx.itemsPerPage,
    "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
    headers: _ctx.headers,
    items: _ctx.applications,
    "items-length": _ctx.totalItems,
    loading: _ctx.loading,
    search: _ctx.search,
    "item-value": "name",
    "onUpdate:options": _ctx.fetchListOfCandidatesShortlisted
  }, {
    "item.actions": _withCtx(({ item, index }) => [
      (!_ctx.showComboBoxes[index])
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: ($event: any) => (_ctx.toggleComboBox(index)),
            class: "text-uppercase",
            size: "small",
            color: "green",
            text: "Change Status"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.showComboBoxes[index])
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "8" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ComboBoxField, {
                    modelValue: item.Status,
                    "onUpdate:modelValue": ($event: any) => ((item.Status) = $event),
                    items: _ctx.statuses,
                    clearable: "",
                    dense: "",
                    "hide-details": ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_v_col, { cols: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "ma-auto",
                    onClick: ($event: any) => (_ctx.changePositionApplicationStatus(item, index)),
                    color: "green"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Done")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["items-per-page", "headers", "items", "items-length", "loading", "search", "onUpdate:options"]))
}