import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_VdSupport = _resolveComponent("VdSupport")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_ViewSupportItem = _resolveComponent("ViewSupportItem")!
  const _component_ViewSupportAction = _resolveComponent("ViewSupportAction")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createTextVNode(" VM Support ")),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewSupportModal = true)),
                "prepend-icon": "mdi-plus"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" New Support Notification ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "align-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_SelectField, {
                    items: _ctx.statusItems,
                    "item-title": "title",
                    "item-value": "value",
                    modelValue: _ctx.status,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.status) = $event)),
                    label: "Status",
                    outlined: "",
                    dense: "",
                    "hide-details": ""
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "secondary",
                    class: "mr-4",
                    onClick: _ctx.clearFilters
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { left: "" }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      }),
                      _cache[9] || (_cache[9] = _createTextVNode(" Clear "))
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    onClick: _ctx.fetchSupportList
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { left: "" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("mdi-check")
                        ])),
                        _: 1
                      }),
                      _cache[11] || (_cache[11] = _createTextVNode(" Filter "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_data_table_server, {
                items: _ctx.supportList,
                headers: _ctx.headers,
                loading: _ctx.loading,
                page: _ctx.paginationModel.page,
                "items-per-page": _ctx.paginationModel.numberOfPages,
                "onUpdate:options": _cache[2] || (_cache[2] = (event) => _ctx.tableOptions = event),
                "items-length": _ctx.paginationModel.totalItemsCount,
                "item-key": "Id"
              }, {
                "item.ShortDesc": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.Title), 1)
                ]),
                "item.Sender": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(_ctx.titleCase(item.Sender ?? "")), 1)
                ]),
                "item.CreatedAt": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(item.CreatedOn)), 1)
                ]),
                "item.LastReplyDateTime": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(item.LastReplyOn)), 1)
                ]),
                "item.Status": _withCtx(({ item }) => [
                  (item.Status)
                    ? (_openBlock(), _createBlock(_component_v_chip, {
                        key: 0,
                        color: _ctx.getStatusColor(item.Status),
                        dark: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Status), 1)
                        ]),
                        _: 2
                      }, 1032, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                "item.action": _withCtx(({ item }) => [
                  _createVNode(_component_v_menu, {
                    "offset-y": "",
                    location: "bottom",
                    "scroll-strategy": "close"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        size: "small",
                        variant: "text",
                        color: "black"
                      }, props, { icon: "mdi-dots-vertical" }), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item, {
                            onClick: ($event: any) => (_ctx.viewItem(item.Id)),
                            "prepend-icon": "mdi-comment-edit-outline ",
                            title: "View"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_v_list_item, {
                            onClick: ($event: any) => (_ctx.viewAction(item)),
                            "prepend-icon": "mdi-check-circle-outline ",
                            title: `Set as ${_ctx.getNewActionStatus(item)}`
                          }, null, 8, ["onClick", "title"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        width: "600",
        modelValue: _ctx.showNewSupportModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showNewSupportModal) = $event)),
        onKeypress: _withKeys(_ctx.closeNewSupportModal, ["esc"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VdSupport, {
            onCancel: _ctx.closeNewSupportModal,
            onSendRequest: _ctx.createSupportNotification,
            isSupport: true
          }, null, 8, ["onCancel", "onSendRequest"])
        ]),
        _: 1
      }, 8, ["modelValue", "onKeypress"]),
      (_ctx.showViewSupportModal)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            width: "800",
            modelValue: _ctx.showViewSupportModal,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showViewSupportModal) = $event)),
            onKeypress: _withKeys(_ctx.closeViewSupportModal, ["esc"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ViewSupportItem, {
                onClose: _ctx.closeViewSupportModal,
                supportId: _ctx.selectedSupportId
              }, null, 8, ["onClose", "supportId"])
            ]),
            _: 1
          }, 8, ["modelValue", "onKeypress"]))
        : _createCommentVNode("", true),
      (_ctx.showActionSupportModal)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 1,
            width: "800",
            modelValue: _ctx.showActionSupportModal,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showActionSupportModal) = $event)),
            onKeypress: _withKeys(_ctx.closeActionSupportModal, ["esc"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ViewSupportAction, {
                onClose: _ctx.closeActionSupportModal,
                newStatus: _ctx.newSupportActionStatus,
                supportId: _ctx.selectedSupportId
              }, null, 8, ["onClose", "newStatus", "supportId"])
            ]),
            _: 1
          }, 8, ["modelValue", "onKeypress"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}