<template>
  <v-app>
    <v-app-bar color="primary" prominent>
      <v-app-bar-nav-icon v-if="drawerPermanent" @click="toggleDrawerOpen">
        <i
          :class="{
            'v-icon': true,
            notranslate: true,
            mdi: true,
            'mdi-chevron-right': !drawerIsOpen,
            'mdi-chevron-left': drawerIsOpen,
          }"
        ></i>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-if="!drawerPermanent" @click="toggleDrawerOpen" />
      

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-if="!isAuthenticated" variant="text" to="/login">Login</v-btn>

        <v-btn v-if="isAuthenticated" variant="text" to="/logout">Logout</v-btn>
      </v-toolbar-items>
      
      <v-spacer />

      <ProfileDropdown v-if="isAuthenticated" />
      <!-- <router-link to="/" class="logo-link"> Project Portal </router-link> -->
    </v-app-bar>
    <v-navigation-drawer
      dark
      fixed
      :temporary="!drawerPermanent"
      :permanent="drawerPermanent"
      :mini-variant="drawerMini"
      v-model="drawerIsOpen"
      clipped-top
      class="app-drawer"
      width="276"
    >
      <NavigationMenu :isAuthenticated="isAuthenticated" :isHiringmanager="isHiringmanager" :isSupport="isSupport" :userInfo="userInfo" />
    </v-navigation-drawer>
    <v-main>
      <!-- <v-alert transition="scroll-y-transition" prominent type="warning" v-if="connectionLost">
        <v-row align="center">
          <v-col class="grow"> Oops, You have lost your connection! </v-col>
          <v-col class="shrink">
            <v-btn @click="checkConnection">try again</v-btn>
          </v-col>
        </v-row>
      </v-alert> -->
      <v-container fluid class="pages-container">
        <transition name="slide-y-transition" mode="out-in">
          <loading-component v-show="showLoading"></loading-component>
        </transition>
        <router-view></router-view>
        <v-snackbar
        v-for="(snakbar, index) in getSnakbars"
        left
        class="snakbar"
        bottom
        :key="snakbar.id"
        :color="snakbar.status"
        v-model="snakbar.show"
        :timeout="snakbar.timeout"
        :style="`bottom: ${index * 60 + 10}px;`"
      >
        {{ snakbar.body }}
        <template v-slot:actions="{ isActive }">
          <v-btn color="white" ripple variant="text" v-bind="isActive" @click="snakbarActionClicked(snakbar.id)"> {{ snakbar.button }}</v-btn>
        </template>
      </v-snackbar>
      </v-container>
    </v-main>
    
  </v-app>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import ProfileDropdown from "@/components/User/ProfileDropdown.vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import UserInfo from "shared-components/src/models/UserInfo";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import store from "@/store";
import router from "./router";
import { defaultUser } from "@/store/state";
import AppHelper from "shared-components/src/utils/AppHelper";
import { mapGetters, mapMutations } from "vuex";
import { REMOVE_ALL_SNAKBARS } from "@/store/types";
import { CountryApi } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    NavigationMenu,
    LoadingComponent,
    ProfileDropdown,
  },
  data() {
    return {
      mini: false,
      submitting: false,
      connectionLost: false,
    };
  },
  computed: {
    ...mapGetters(["showLoading", "getSnakbars", "getSnakbar"]),
    showLoading(): boolean {
      return store.state.showLoading;
    },
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
    isAuthenticated(): boolean {
      return store.getters.userIsAdmin ?? false;
    },
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
    isSupport(): boolean {
      return store.getters.userIsSupport ?? false;
    },
    drawerIsOpen: {
      get(): boolean {
        return store.state.drawerIsOpen;
      },
      set(newValue: boolean) {
        this.handleDrawerOpen(newValue);
      },
    },
    drawerPermanent(): boolean {
      return this.$vuetify.display.name === "lg" 
      || this.$vuetify.display.name === "xl" || this.$vuetify.display.name === "md";
    },
    drawerMini(): boolean {
      return this.drawerPermanent ? this.mini : false;
    },
  },
  created() {
    this.REMOVE_ALL_SNAKBARS();
  },
  beforeCreate() {
    if (!AppHelper.IsAzureEnv()) {
      setInterval(() => {
        const idToken = localStorage.getItem("idToken");
        if ((!idToken || idToken == "null") && router.currentRoute.value.name != "login") {
          store.dispatch("setUserInfo", { userInfo: defaultUser });
          router.push("/login");
        }
      }, 3000);
    }
  },
  mounted() {
    const mainLoader = document.getElementById("mainAppLoader");
    if (mainLoader) {
      mainLoader.remove();
    }
    const loaoderStyle = document.getElementById("app-loader-style");
    if (loaoderStyle) {
      loaoderStyle.remove();
    }
  },
  methods: {
    ...mapMutations([REMOVE_ALL_SNAKBARS]),
    snakbarActionClicked(id: string) {
      this.$emit("snakBarClicked", id);
      if (this.getSnakbar(id).handler) {
        this.getSnakbar(id).handler(id);
      }
    },
    toggleDrawerOpen() {
      return store.dispatch("toggleDrawerOpen");
    },
    handleDrawerOpen(value: boolean) {
      return store.dispatch("handleDrawerOpen", value);
    },
    
  },
});
</script>

<style scoped>
.app-drawer {
  background-image: url("./assets/images/menu-back.png");
  background-color: #4d5b69 !important;
  background-repeat: no-repeat;
  background-size: auto;
}

.pages-container {
  min-height: 340px;
}

.logo-link {
  height: 100%;
}

.snakbar {
  transition: all 0.1s;
}
</style>
