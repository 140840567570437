<template>
  <v-card :loading="loading" :disabled="loading" class="overflow-visible">
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">Cost Analysis</div>
        <div class="card-title-right">
          <!-- <v-btn variant="text" size="small" icon="mdi-close" @click="close"></v-btn> -->
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <label>Select Month</label>
          <VueDatePicker v-model="selectedMonth" :clearable="false" range month-picker position="center" teleport @update:model-value="fetchCostAnalysis"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table-server
            :group-by="groupBy"
            :headers="headers"
            :items="constAnalysises"
            item-value="name"
            :loading="loading"
            :page="paginationModel.page"
            :items-per-page="paginationModel.numberOfPages"
            @update:options="(event: any) => tableOptions = event"
            :items-length="paginationModel.totalItemsCount"
            item-key="Id"
            density 
            class="costAnalysis"
          >
            <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
              <tr>
                <td :colspan="columns.length - 1">
                  <VBtn
                    :icon="isGroupOpen(item) ? '$expand' : '$next'"
                    size="small"
                    variant="text"
                    @click="toggleGroup(item)"
                    :class="[item.depth == 1 ? 'ml-3' : item.depth == 2 ? 'ml-6' : item.depth == 3 ? 'ml-9': '']"
                  ></VBtn>
                  {{ getGroupName(item)  }}
                </td>
                <td :colspan="1">
                  <div class="text-center">
                    {{ formatSumOfGroup(item) }}  
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:item.BillableItemFee="{ item }">
              {{ getBillableItemFee(item) }}
            </template>
            <template v-slot:item.BillableItemName="{ item }">
              {{ getBillableItemName(item) }}
            </template>
            <template v-slot:item.amount="{ item }">
              {{ getBillableItemAmount(item) }}
            </template>
          </v-data-table-server>
        </v-col>
      </v-row>
      
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import { CostAnalysisReportResponseModel, PaginationRequestModel, ReportApi } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
  },
  data() {
    return {
      constAnalysises: [] as CostAnalysisReportResponseModel[],
      loading: false,
      selectedMonth: [{
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      }],
      paginationModel: {
        page: 1,
        totalItemsCount: 0 as number | undefined,
        numberOfPages: 10,
        sortProp: "CreatedAt",
        sortOrder: "desc",
      },
      tableOptions: {} as any,
      viewMode: "year",
      groupBy: [
          {
            key: 'CustomerName',
            order: 'asc',
          },
          {
            key: 'ProjectName',
            order: 'asc',
          },
          {
            key: 'TeammemberName',
            order: 'asc',
          },
          {
            key: 'BillableItemCommitmentId',
            order: 'asc',
          },
        ],
        headers: [
          {
            title: 'Billable Item Name',
            align: 'start',
            sortable: false,
            key: 'BillableItemName',
          },
          { title: 'Fee', key: 'BillableItemFee', width: 100 },
          { title: 'Quantity', key: 'BillableItemQuantity', width: 100 },
          { title: 'Amount', key: 'amount', align: "end", width: 150 },
        ],
    };
  },
  methods: {
    getGroupName(item: any){
      if(item.key == "BillableItemCommitmentId"){
        let billableItems = this.constAnalysises.filter(ca => ca.BillableItemCommitmentId == item.value)
        let billableName = billableItems[0].BillableItemName;
        let billableStart = billableItems[0].BillableItemFrom;
        let billableEnd = billableItems[0].BillableItemTo;;
        billableItems.forEach(bi => {
          if(moment(bi.BillableItemFrom) < moment(billableStart))
          {
            billableStart = bi.BillableItemFrom
          }
          if(moment(bi.BillableItemTo) > moment(billableEnd))
          {
            billableEnd = bi.BillableItemTo
          }
        })
        return `${billableName} - (${billableStart} - ${billableEnd})`;
      }
      return item.value;
    },
    updateViewMode(mode: any) {
      this.viewMode = mode === 'year' ? 'year' : 'months'
    },
    async fetchCostAnalysis(){
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;

        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.paginationModel.sortOrder,
          SortProp: this.paginationModel.sortProp,
        } as PaginationRequestModel;
        
        if(this.selectedMonth.length > 1 && this.selectedMonth[1] != null){
          paginationModel.filters = {
            "From": `${this.selectedMonth[0].year}-${this.selectedMonth[0].month + 1 }`, "To": `${this.selectedMonth[1].year}-${this.selectedMonth[1].month + 1}`}
        }else{
          paginationModel.filters = {
            "From": `${this.selectedMonth[0].year}-${this.selectedMonth[0].month + 1}`, "To": `${this.selectedMonth[0].year}-${this.selectedMonth[0].month + 1}`}
        }
        const result = (await (new ReportApi().getCostAnalysisReport(paginationModel))).data;
        this.constAnalysises = result.Items as CostAnalysisReportResponseModel[];
        this.paginationModel.totalItemsCount = result.Total;
      }
      finally {
        this.loading = false;
      }
    },
    getBillableItemAmount(item: any){
      return `${(item.BillableItemFee * item.BillableItemQuantity).toString()} ${item.BillableItemCurrency}`
    },
    getBillableItemName(item: any){
      return `${item.BillableItemName} - (${item.BillableItemFrom} - ${item.BillableItemTo})`
    },
    getBillableItemFee(item: any){
      return `${item.BillableItemFee} ${item.BillableItemCurrency}`
    },
    formatSumOfGroup(items: any){
      const sumItems = this.getSumOfGroup(items);
      return sumItems.map(item => `${item.Value} ${item.Currency}`).join(" + ")
    },
    getSumOfGroup(item: any){
      let sumValue = [] as {Value: number, Currency: string}[];
      if(item.items && item.items.length > 0){
         item.items.forEach((element: any) => {
          if(Object.keys(element).includes("columns")){
            var sumObjectIndex = sumValue.findIndex(item => item.Currency == element.raw.BillableItemCurrency)
            if(sumObjectIndex != -1){
              sumValue[sumObjectIndex].Value += element.columns.BillableItemFee * element.columns.BillableItemQuantity;
            }else{
              sumValue.push({Value: element.columns.BillableItemFee * element.columns.BillableItemQuantity, Currency: element.raw.BillableItemCurrency})
            }
          }else{
            sumValue = sumValue.concat(this.getSumOfGroup(element));
          }
        });
      }
      return sumValue;
    }
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.paginationModel.sortProp = newVal.sortBy[0].key;
          this.paginationModel.sortOrder = newVal.sortBy[0].order;
        }
        this.fetchCostAnalysis();
      }
    },
  }
});
</script>
<style lang="scss">
.costAnalysis{
  table thead tr th { 
    border-left: 1px solid #dddddd !important;
    border-top: 1px solid #dddddd !important;
    border-right: 1px solid #dddddd !important;
  }
  table thead tr td { 
    border-left: 1px solid #dddddd !important; 
  }
  table tbody tr th { border-left: 1px solid #dddddd !important; }
  table tbody tr td { 
    border-left: 1px solid #dddddd !important; 
    border-right: 1px solid #dddddd !important;
  }
}

</style>
