import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_AddNewJob = _resolveComponent("AddNewJob")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_DetailJobOpening = _resolveComponent("DetailJobOpening")!
  const _component_PositionApplications = _resolveComponent("PositionApplications")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { loading: _ctx.filterLoading }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "headline" }, "Job Openings", -1)),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          class: "mb-2",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newJobPosition()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { left: "" }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("mdi-plus")
                              ])),
                              _: 1
                            }),
                            _cache[12] || (_cache[12] = _createTextVNode(" New Job "))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_container, { class: "mt-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6",
                              xl: _ctx.isHiringmanager ? 4 : 3,
                              lg: _ctx.isHiringmanager ? 4 : 3,
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  "item-title": "Name",
                                  "item-value": "id",
                                  label: "Job Role",
                                  items: _ctx.jobRoles,
                                  modelValue: _ctx.filterJobRole,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterJobRole) = $event)),
                                  dense: "",
                                  outlined: "",
                                  placeholder: "Job Role",
                                  type: "text"
                                }, null, 8, ["items", "modelValue"])
                              ]),
                              _: 1
                            }, 8, ["xl", "lg"]),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6",
                              xl: _ctx.isHiringmanager ? 4 : 3,
                              lg: _ctx.isHiringmanager ? 4 : 3,
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  label: "Job Title",
                                  modelValue: _ctx.filterJobtitle,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterJobtitle) = $event)),
                                  items: _ctx.jobTitles,
                                  "item-title": "Name",
                                  "item-value": "id",
                                  dense: "",
                                  outlined: "",
                                  placeholder: "Job Title",
                                  type: "text"
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            }, 8, ["xl", "lg"]),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6",
                              xl: _ctx.isHiringmanager ? 4 : 3,
                              lg: _ctx.isHiringmanager ? 4 : 3,
                              sm: "6",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  "item-title": "FullName",
                                  "item-value": "id",
                                  items: _ctx.customers,
                                  label: "Customer",
                                  modelValue: _ctx.filterCustomer,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterCustomer) = $event)),
                                  dense: "",
                                  outlined: "",
                                  placeholder: "Customer",
                                  type: "text"
                                }, null, 8, ["items", "modelValue"])
                              ]),
                              _: 1
                            }, 8, ["xl", "lg"]),
                            (!_ctx.isHiringmanager)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  cols: "12",
                                  md: "6",
                                  xl: _ctx.isHiringmanager ? 4 : 3,
                                  lg: _ctx.isHiringmanager ? 4 : 3,
                                  sm: "6",
                                  xs: "12"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_AutoCompleteField, {
                                      class: "no-wrap-label",
                                      items: _ctx.hiringManagerList,
                                      label: "Hiring Manager",
                                      modelValue: _ctx.filtereHiringManager,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filtereHiringManager) = $event)),
                                      dense: "",
                                      outlined: "",
                                      placeholder: "Hiring Manager",
                                      type: "text",
                                      "item-title": "fullName",
                                      "item-value": "id"
                                    }, null, 8, ["items", "modelValue"])
                                  ]),
                                  _: 1
                                }, 8, ["xl", "lg"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "filter"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, { onClick: _ctx.clearFilter }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { left: "" }, {
                                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                                    _createTextVNode("mdi-close")
                                  ])),
                                  _: 1
                                }),
                                _cache[15] || (_cache[15] = _createTextVNode(" clear Filter "))
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              color: "primary",
                              onClick: _ctx.fitlterJob
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { left: "" }, {
                                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                                    _createTextVNode("mdi-check")
                                  ])),
                                  _: 1
                                }),
                                _cache[17] || (_cache[17] = _createTextVNode(" Filter "))
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_data_table_server, {
                      loading: _ctx.filterLoading,
                      "item-key": "Id",
                      headers: _ctx.headers,
                      items: _ctx.jobs,
                      class: "elevation-5 pr-5 pl-5",
                      page: _ctx.page,
                      "items-per-page": _ctx.numberOfPages,
                      "items-length": _ctx.totalItemsCount,
                      "onUpdate:options": _cache[6] || (_cache[6] = (event) => _ctx.tableOptions = event)
                    }, {
                      "item.JobTitles": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.getJobTitlesName(item.JobTitles)), 1)
                      ]),
                      "item.StartDate": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(item.StartDate ? new Date(item.StartDate) : null)), 1)
                      ]),
                      "item.Status": _withCtx(({ item }) => [
                        _createVNode(_component_v_chip, {
                          color: _ctx.getStatusColor(item.Status),
                          dark: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.Status), 1)
                          ]),
                          _: 2
                        }, 1032, ["color"])
                      ]),
                      "item.actions": _withCtx(({ item }) => [
                        _createVNode(_component_v_menu, {
                          "offset-y": "",
                          left: "",
                          attach: ""
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({ small: "" }, props, {
                              onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
                            }), {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createTextVNode(" mdi-dots-vertical ")
                              ])),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, { density: "compact" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDynamicActions(item), (actionItem, index) => {
                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: index,
                                    onClick: ($event: any) => (actionItem.action(item)),
                                    disabled: (actionItem.text === 'Active' || actionItem.text === 'Archive') && _ctx.isHiringmanager,
                                    color: "primary"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        class: "mr-1",
                                        color: "blue",
                                        small: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(actionItem.icon), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, {
                                        textContent: _toDisplayString(actionItem.text),
                                        class: "text-left"
                                      }, null, 8, ["textContent"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick", "disabled"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }, 8, ["loading", "headers", "items", "page", "items-per-page", "items-length"])
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.dialog)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 0,
              modelValue: _ctx.dialog,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialog) = $event)),
              fullscreen: "",
              "hide-overlay": "",
              transition: "dialog-bottom-transition"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AddNewJob, {
                  customers: _ctx.customers,
                  projectList: _ctx.projectList,
                  commitmentModel: _ctx.editItem,
                  hiringManagerList: _ctx.hiringManagerList,
                  taskList: _ctx.taskList,
                  skills: _ctx.skills,
                  details: _ctx.details,
                  "dialog-title": _ctx.dialogTitle,
                  dialog: _ctx.dialog,
                  editingJob: _ctx.editingJob,
                  locations: _ctx.locations,
                  loading: _ctx.countryLoading,
                  disable: _ctx.countryLoading,
                  onPositionSaved: _ctx.positonSaved,
                  onCloseDialog: _ctx.closeDialog
                }, null, 8, ["customers", "projectList", "commitmentModel", "hiringManagerList", "taskList", "skills", "details", "dialog-title", "dialog", "editingJob", "locations", "loading", "disable", "onPositionSaved", "onCloseDialog"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.viewDialog)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 1,
              modelValue: _ctx.viewDialog,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.viewDialog) = $event)),
              "max-width": "1000px",
              persistent: "",
              transition: "dialog-bottom-transition"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DetailJobOpening, {
                  job: _ctx.selectedJob,
                  onCloseDialog: _ctx.closeDetailDialog,
                  selectedCutomerName: _ctx.selectedCutomerName,
                  selectedJobRole: _ctx.selectedJobRole,
                  locations: _ctx.locations,
                  loading: _ctx.countryLoading,
                  disable: _ctx.countryLoading
                }, null, 8, ["job", "onCloseDialog", "selectedCutomerName", "selectedJobRole", "locations", "loading", "disable"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.viewPositionApplications,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.viewPositionApplications) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PositionApplications, {
              positionId: _ctx.selectedJob.Id ?? ''
            }, null, 8, ["positionId"]),
            _createVNode(_component_v_btn, {
              text: "Close",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.viewPositionApplications = false))
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}