<template>
  <v-card>
    <v-toolbar dark color="primary" class="sticky-title">
      <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog" class="mb-5">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form class="form-inputs" ref="frmPosition">
        <v-container class="container">
          <v-card v-if="!isHiringmanager" class="postiion" :disabled="loading" :loading="loading">
            <v-row>
              <v-col cols="12">
                <v-card-title>Job Opening</v-card-title>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <TextField
                  placeholder="Title"
                  showRequired
                  :rules="[validations.required]"
                  dense
                  v-model="createPositionModel.Title"
                  label="Title"
                  outlined
                ></TextField>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <ComboBoxField
                  :rules="[validations.required]"
                  placeholder="Customer"
                  showRequired
                  :items="fetchCustomers"
                  item-title="FullName"
                  item-value="id"
                  label="Customer"
                  outlined
                  dense
                  v-model="selectedCustomers"
                  @update:modelValue="onChangeCustomer"
                ></ComboBoxField>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <AutoCompleteField
                  :rules="[validations.required]"
                  placeholder="Project"
                  showRequired
                  :items="filteredProjects"
                  item-title="Name"
                  item-value="id"
                  label="Project"
                  outlined
                  dense
                  v-model="createPositionModel.ProjectId"
                ></AutoCompleteField>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <AutoCompleteField
                  :rules="[validations.required]"
                  dense
                  label="Job Role"
                  placeholder="Job Role"
                  item-title="Name"
                  item-value="id"
                  showRequired
                  :items="jobRoles"
                  v-model="selectedJobRole"
                  outlined
                  @update:modelValue="onChangeJobRole"
                ></AutoCompleteField>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <AutoCompleteField
                  :rules="[validations.required]"
                  id="jobTitle"
                  clearable
                  label="Job Title"
                  placeholder="Job Title"
                  :items="jobTitles"
                  outlined
                  dense
                  multiple
                  showRequired
                  item-title="Name"
                  item-value="id"
                  closable-chips
                  v-model="selectedJobTitle"
                >
                </AutoCompleteField>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <AutoCompleteField
                  :rules="[validations.required]"
                  dense
                  label="Hiring Manager"
                  placeholder="Hiring Manager"
                  showRequired
                  v-model="createPositionModel.HiringManagerId"
                  :items="hiringManagerList"
                  item-title="fullName"
                  item-value="id"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <ComboBoxField
                  :rules="[validations.EmailCheck, validations.required]"
                  v-model="createPositionModel.HiringContact"
                  dense
                  outlined
                  label="Hiring Contact"
                  showRequired
                  placeholder="Hiring Contact"
                  type="text"
                />
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <TextField
                  :rules="[validations.required, validations.integer]"
                  dense
                  placeholder="Number of Positions"
                  label="Number of Positions"
                  showRequired
                  v-model="createPositionModel.NoOfPositions"
                  type="number"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <DateInputField
                  showRequired
                  label="Position Start Date"
                  first-day-of-week="1"
                  :rules="[validations.required]"
                  v-model="createPositionModel.StartDate"
                  hide-actions
                  placeholder="Date"
                  prepend-icon=""
                />
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <AutoCompleteField
                  :rules="[validations.required]"
                  id="countryId"
                  dense
                  label="Countries"
                  placeholder="Countries"
                  showRequired
                  v-model="createPositionModel.CountryId"
                  :items="sortedLocations"
                  multiple
                  outlined
                  item-title="text"
                  item-value="value"
                />
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <AutoCompleteField
                  :rules="[validations.required]"
                  multiple
                  dense
                  label="Skills"
                  placeholder="Skills"
                  showRequired
                  v-model="createPositionModel.Skills"
                  outlined
                  :items="sortedSkills"
                  item-title="Text"
                  item-value="id"
                />
              </v-col>
              <v-col cols="12">
                <TextAreaField
                  :rules="[validations.required]"
                  showRequired
                  v-model="createPositionModel.JobDescription"
                  outlined
                  label="Job Description"
                  placeholder="Job Description"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-form ref="frmTask">
            <v-card class="postiion">
              <v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-title>Tasks</v-card-title>
                  </v-col>
                  <v-col cols="12" md="6" xs="12" sm="12">
                    <ComboBoxField
                      :rules="[validations.required]"
                      id="Task"
                      clearable
                      label="Task"
                      placeholder="Task"
                      showRequired
                      outlined
                      :items="taskList"
                      dense
                      item-title="Title"
                      item-value="Id"
                      @update:modelValue="onTaskTemplateSelected"
                      v-model="selectedTask"
                      :return-object="false"
                    >
                    </ComboBoxField>
                  </v-col>
                  <v-col class="mt-8" cols="12" md="4" xs="12" sm="12">
                    <v-switch :disabled="disableSwitch" v-model="newTask.isDefault" label="Add to task" color="primary"></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <TextAreaField
                      :rules="[validations.required]"
                      showRequired
                      v-model="newTask.Description"
                      outlined
                      label="Description"
                      placeholder="Description"
                    />
                  </v-col>
                  <v-col cols="12" :md="6" xs="12" sm="12">
                    <DateInputField
                      showRequired
                      label="Date"
                      first-day-of-week="1"
                      :rules="[validations.required]"
                      v-model="selectedTaskDate"
                      hide-actions
                      placeholder="Date"
                      prepend-icon=""
                    />
                  </v-col>
                  <v-col cols="12" :md="6" xs="12" sm="12">
                    <TextField
                      :rules="[validations.required, validations.integer]"
                      dense
                      outlined
                      label="Priority"
                      v-model="newTask.OrderNumber"
                      showRequired
                      placeholder="Priority"
                      type="number"
                    />
                  </v-col>

                  <v-col cols="12" class="save-btn mb-5">
                    <v-btn class="secondary" variant="elevated" @click="resettaskform"><v-icon>mdi-close</v-icon> cancel</v-btn>
                    <v-btn color="primary" @click="isEditingTask ? saveEditedTask() : addTask()">
                      <v-icon left>mdi-check</v-icon>
                      {{ isEditingTask ? "Update" : "Add Task" }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <v-card>
                    <v-data-table :headers="headerTask" :items="tableTasks" :hide-default-footer="true">
                      <template v-slot:item.TaskTemplateName="{ item }">
                        {{ getTaskName(item) }}
                      </template>
                      <template v-slot:item.taskDate="{ item }">
                        {{ formatDate(item.Date) }}
                      </template>
                      <template v-slot:item.order="{ item }">
                        {{ item.OrderNumber }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-20" @click="editTask(item)">mdi-pencil</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>

                <v-col cols="12" class="save-btn">
                  <v-btn class="secondary" variant="elevated" @click="closeDialog"><v-icon>mdi-close</v-icon> cancel</v-btn>
                  <v-btn class="primary" color="primary" variant="elevated" @click="savePosition" :loading="loading"
                    ><v-icon>mdi-check</v-icon>
                    {{ dialogTitle == "Edit Job" ? "Update" : "Save" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  PositionApi,
  PositionModel,
  PositionTaskModel,
  TaskTemplateApi,
  PositionResponseModel,
  PositionDetailResponseModel,
  EntitySummaryModel,
  PositionTaskRequestModel,
} from "shared-components/src/services/openApi/api";
import Details from "shared-components/src/models/Details";
import ManagerModel from "shared-components/src/models/ManagerModel";
import Project from "shared-components/src/models/Project";
import CoreSkill from "shared-components/src/models/CoreSkill";
import { TaskTemplateModel } from "shared-components/src/services/openApi/api";
import validations from "shared-components/src/utils/validations";
import Utils from "shared-components/src/utils/Utils";
import store from "@/store";
import moment from "moment";

var positionApi = new PositionApi();
var taskTemplateApi = new TaskTemplateApi();
export default defineComponent({
  props: {
    customers: {
      type: Array as PropType<ManagerModel[]>,
      reqired: false,
    },
    projectList: {
      type: Array as PropType<Project[]>,
      reqired: false,
    },
    dialogTitle: {
      type: String,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: false,
    },
    details: {
      type: Array as PropType<Details[]>,
      required: false,
    },
    skills: {
      type: Array as PropType<CoreSkill[]>,
      required: false,
    },
    hiringManagerList: {
      type: Array as PropType<any[]>,
      required: false,
    },
    editingJob: {
      type: Object as PropType<PositionDetailResponseModel | null>,
      required: false,
    },
    locations: {
      type : Array as PropType<any[]>,
      required: true,
    },
  },
  data() {
    return {
      taskList: [] as EntitySummaryModel[],
      filterCustomer: [] as ManagerModel[],
      validations,
      isTaskSelected: false,
      projects: [] as Project[],
      selectedCustomers: null as ManagerModel | null,
      selectedTaskDate: new Date() as Date,
      selectedStatus: "",
      jobRoles: [] as Details[],
      jobTitles: [] as Details[],
      selecteTaskTitle: null,
      selectedJobRole: "",
      selectedJobTitle: [] as Details[] | null,
      filteredJobTitles: [] as string[],
      createPositionModel: {
        JobTitles: [] as string[],
        CountryId: [] as string[],
        ProjectId: "",
      } as PositionModel,
      editingTask: {
        TaskTemplateId: undefined,
        TaskTemplateName: "",
        Title: "",
        Date: "",
        OrderNumber: 1,
        Description: "",
        Status: 0,
        isDefault: false,
      } as PositionTaskModel,
      taskInput: "",
      newTask: {
        TaskTemplateId: undefined,
        TaskTemplateName: "",
        Title: "",
        Date: "",
        OrderNumber: 1,
        Description: "",
        Status: 0,
        isDefault: false,
      } as PositionTaskModel,
      selectedTask: "",
      tableTasks: [] as PositionTaskModel[],
      newTaskDialog: false,
      jobPositionDate: false,
      dateMenu: false,
      headerTask: [
        { title: "Task", align: "start", value: "TaskTemplateName", sortable: false },
        { title: "Date", align: "center", value: "taskDate", sortable: false },
        { title: "Priority", align: "center", value: "order", sortable: false },
        { title: "Actions", align: "end", value: "actions", sortable: false },
      ],
      choosedJobTitle: [],
      taskStatuses: ["New", "Active", "Resolved", "Closed", "Archived"],
      selectedTaskStatus: "New",
      Active: false,
      disableSwitch: false,
      loading: false,
      isEditingTask: false,
    };
  },
  async mounted() {
    this.fetchProjects();
    this.loadJobRoleAndJobTitle();
    this.getActiveTaskTemplateList();

    if (this.editingJob) {
      this.createPositionModel.ProjectId = this.editingJob.ProjectId;

      const selectedProject = this.projects.find((p) => {
        return p.id === this.editingJob?.ProjectId;
      });
      if (selectedProject && selectedProject.Customerss && selectedProject.Customerss.length > 0) {
        this.selectedCustomers = this.customers?.find((c) => {
          return c.id === ((selectedProject?.Customerss as any)[0] as any);
        }) as any;
      }
      if (selectedProject && selectedProject.id) {
        this.createPositionModel.ProjectId = selectedProject.id;
      }

      this.createPositionModel.Title = this.editingJob.Title;
      this.createPositionModel.Id = this.editingJob.Id;

      this.createPositionModel.HiringManagerId = this.editingJob.HiringmanagerId;
      this.createPositionModel.HiringContact = this.editingJob.HiringContact;
      this.createPositionModel.NoOfPositions = this.editingJob.NoOfPositions;
      if (this.editingJob.StartDate) {
        var splitedDate = this.editingJob.StartDate.split("T")[0].split("-");
        this.createPositionModel.StartDate = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 1, Number(splitedDate[2]), 0, 0, 0, 0) as any;
      }

      this.createPositionModel.CountryId = this.editingJob.CountryId;
      this.createPositionModel.Skills = this.editingJob.Skills ? [...this.editingJob.Skills.map((item: any) => item.id)] : [];
      this.createPositionModel.JobDescription = this.editingJob.JobDescription;
      this.createPositionModel.Status = this.editingJob.Status;

      this.selectedJobRole = this.editingJob.JobRole ?? "";

      this.selectedJobTitle =
        this.editingJob.JobTitles?.map((item) => {
          return {
            id: item.id,
            Name: item.Name,
            Type: item.Type,
          } as Details;
        }) ?? null;

      this.selectedStatus = this.editingJob.Status ?? "";
      this.tableTasks = this.editingJob.Tasks ?? [];
    }
  },
  watch: {
    selectedJobRole(newVal: any) {
      this.filterJobTitleByRole();
    },
    details(newVal: any) {
      this.loadJobRoleAndJobTitle();
    },
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
    sortedSkills() {
      const selectedSkills = this.createPositionModel.Skills || [];
      return (this.skills || []).slice().sort((a, b) => {
        const isSelectedA = selectedSkills.includes(a.id);
        const isSelectedB = selectedSkills.includes(b.id);
        if (isSelectedA && !isSelectedB) {
          return -1;
        }
        if (!isSelectedA && isSelectedB) {
          return 1;
        }
        return 0;
      });
    },
    sortedLocations() {
      const selectedLocations = this.createPositionModel.CountryId || [];

      return this.locations.slice().sort((a, b) => {
        const isSelectedA = selectedLocations.includes(a.value);
        const isSelectedB = selectedLocations.includes(b.value);

        if (isSelectedA && !isSelectedB) {
          return -1;
        }
        if (!isSelectedA && isSelectedB) {
          return 1;
        }
        return a.text.localeCompare(b.text);
      });
    },
    isTaskSelected(): boolean {
      return this.newTask.TaskTemplateId !== null;
    },
    fetchCustomers() {
      const customers = this.customers ?? [];
      this.filterCustomer = customers
        .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item: any) => {
          if (item.FirstName && item.FirstName.trim() && item.LastName && item.LastName.trim()) {
            item.FullName = `${item.FirstName} ${item.LastName}`;
          } else {
            if (item.FirstName && item.FirstName.trim()) {
              item.FullName = item.FirstName;
            } else {
              item.FullName = item.LastName;
            }
          }
          return item;
        });
      return this.filterCustomer;
    },
    filteredProjects() {
      if (!this.selectedCustomers) {
        // return this.projects;
        return [];
      }
      const customerId = (this.selectedCustomers as ManagerModel).id;
      return this.projects.filter((project) => project.Customerss?.includes(customerId));
    },
  },
  methods: {
    getTaskName(item: any) {
      if (!item?.TaskTemplateName) {
        const findTask = this.taskList?.find((c) => c?.Id === item?.TaskTemplateId);
        return findTask?.Title || "Untitled Task";
      }
      return item.TaskTemplateName;
    },
    closeNewTaskDialog() {
      this.newTaskDialog = false;
    },
    formatDate(date: any) {
      date = new Date(date);
      if (date) {
        return Utils.toVsDateFormat(date);
      }
    },
    updateFormattedDate(value: any) {
      this.jobPositionDate = false;
    },
    onChangeCustomer(value: any) {
      this.selectedCustomers = value;
      this.createPositionModel.ProjectId = "";
    },
    onChangeJobRole(value: any) {
      this.selectedJobRole = value;
      this.selectedJobTitle = [];

      if (!value) {
        this.jobTitles = [];
      } else {
        this.filterJobTitleByRole();
      }
    },
    onTaskTemplateSelected(item: any) {
      if (item) {
        this.newTask.TaskTemplateName = item;
      }
    },
    fetchProjects() {
      this.projects = (this.projectList?.filter((c: any) => c.Name && c.Name.trim().length > 0) ?? []) as Project[];
    },    
    
    loadJobRoleAndJobTitle() {
      if (!this.details) {
        return;
      }

      this.jobRoles = this.details.filter((item: any) => item.Type == "JobRole");

      if (this.selectedJobRole) {
        this.jobTitles = this.details.filter((item: any) => item.Type == "JobTitle" && item.RoleId === this.selectedJobRole);
      } else {
        this.jobTitles = [];
      }
    },
    filterJobTitleByRole() {
      if (!this.details) {
        return;
      }

      if (this.selectedJobRole) {
        this.jobTitles = this.details.filter((item: any) => item.Type === "JobTitle" && item.RoleId === this.selectedJobRole);
      } else {
        this.jobTitles = [];
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    async addTask() {
      let isTaskValid = (await (this.$refs.frmTask as any).validate()).valid;
      if (isTaskValid) {
        if (this.selectedTaskDate) {
          this.newTask.Date = Utils.toVsDateFormat(this.selectedTaskDate);
        }

        if (this.taskList && this.newTask?.TaskTemplateName) {
          var taskName = this.newTask?.TaskTemplateName?.trim().toLowerCase();

          if (taskName) {
            var existTask = this.taskList.find(
              (task: any) => (task.Name && task.Name.trim().toLowerCase() === taskName) || (task.Id && task.Id.toLowerCase() === taskName)
            );
          }

          if (existTask) {
            this.newTask.TaskTemplateName = "";
            this.newTask.TaskTemplateId = existTask.Id;
          } else {
            this.newTask.TaskTemplateId = "";
            this.newTask.TaskTemplateName = taskName;
          }
        }

        if (this.selectedTaskStatus) {
          switch (this.selectedTaskStatus) {
            case "New":
              this.newTask.Status = 0;
              break;
            case "Active":
              this.newTask.Status = 1;
              break;
            case "Resolved":
              this.newTask.Status = 2;
              break;
            case "Closed":
              this.newTask.Status = 3;
              break;
            case "Archived":
              this.newTask.Status = 4;
              break;
          }
        }
        this.tableTasks.push({ ...this.newTask });
        store.dispatch("showSuccessMessage", "Task has been successfully added.");

        this.resetTask();
      }
    },
    resetTask() {
      this.newTask = {} as PositionTaskModel;
      (this.$refs.frmTask as any).reset();
    },
    async savePosition() {
      let isValid = (await (this.$refs.frmPosition as any).validate()).valid;
      if (isValid) {
        try {
          this.createPositionModel.Tasks = this.tableTasks.map(
            (task) =>
              ({
                TaskTemplateId: task.TaskTemplateId,
                TaskTemplateName: task.TaskTemplateName,
                Title: task.Title,
                Date: task.Date,
                OrderNumber: task.OrderNumber,
                Description: task.Description,
                Status: task.Status,
                isDefault: task.isDefault,
              } as PositionTaskRequestModel)
          );

          if (this.selectedJobRole) {
            this.createPositionModel.JobRoleId = this.selectedJobRole;
          }
          if (this.selectedJobTitle && this.selectedJobTitle.length > 0) {
            const jobTitlesIds = this.selectedJobTitle.map((job) => job.id);
            this.createPositionModel.JobTitles = jobTitlesIds;
          }

          if (!Array.isArray(this.createPositionModel.CountryId)) {
            this.createPositionModel.CountryId = this.createPositionModel.CountryId ? [this.createPositionModel.CountryId] : [];
          }
          if (this.createPositionModel.StartDate) {
            this.createPositionModel.StartDate = moment(this.createPositionModel.StartDate).format("YYYY-MM-DD");
          }
          try {
            const response = await positionApi.savePosition(this.createPositionModel);
            this.$emit("PositionSaved", response.data);

            store.dispatch("showSuccessMessage", "Position has been successfully added.");
          } catch (error) {
            store.dispatch("showErrorMessage", "Failed to save the position. Please try again.");
            console.error("Error in saving position:", error);
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    editTask(item: PositionTaskModel) {
      this.isEditingTask = true;
      if (item.Date) {
        var splitedDate = item.Date.split("T")[0].split("-");
        this.selectedTaskDate = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 1, Number(splitedDate[2]), 0, 0, 0, 0);
        this.newTask.Date = Utils.toVsDateFormat(this.selectedTaskDate);
      }

      this.editingTask = { ...item };
      this.selectedTaskStatus = this.getStatusName(item?.Status as number);
      if (this.editingTask.TaskTemplateId) {
        this.selectedTask = this.editingTask.TaskTemplateId;
        this.newTask.TaskTemplateId = this.selectedTask;
      } else {
        this.selectedTask = this.editingTask.TaskTemplateName ?? "";
        this.newTask.TaskTemplateName = this.selectedTask;
      }
      this.newTask.OrderNumber = this.editingTask.OrderNumber;
      this.newTask.Description = this.editingTask.Description;
      this.newTask.Status = 0;
      this.newTask.isDefault = this.editingTask.isDefault;
    },
    saveEditedTask() {
      this.isEditingTask = false;
      const index = this.tableTasks.findIndex((task) => task.TaskTemplateId === this.editingTask.TaskTemplateId);
      if (index != -1) {
        var tasksArray = [...this.tableTasks];
        tasksArray[index].TaskTemplateId = this.newTask.TaskTemplateId;
        tasksArray[index].TaskTemplateName = this.newTask.TaskTemplateName;
        tasksArray[index].Date = Utils.toVsDateFormat(this.selectedTaskDate);
        tasksArray[index].OrderNumber = this.newTask.OrderNumber;
        tasksArray[index].Description = this.newTask.Description;
        tasksArray[index].Status = this.newTask.Status;
        if (this.newTask.Date) {
          this.newTask.Date = Utils.toVsDateFormat(this.selectedTaskDate);
        }
        this.tableTasks = tasksArray;
      }
      this.resettaskform();
      this.newTaskDialog = false;
    },
    getStatusName(code: number) {
      switch (code) {
        case 0:
          return "New";
          break;
        case 1:
          return "Active";
          break;
        case 2:
          return "Resolved";
          break;
        case 3:
          return "Closed";
          break;
        case 4:
          return "Archived";
          break;

        default:
          return "undefined";
          break;
      }
    },
    resettaskform() {
      this.selectedTask = "";
      this.selectedTaskDate = new Date();
      this.selectedTaskStatus = "New";
      this.newTask = {} as PositionTaskModel;
      (this.$refs.frmTask as any).reset();
    },
    async getActiveTaskTemplateList() {
      try {
        const result = await taskTemplateApi.geTaskTemplateListSummary();
        this.taskList = result.data;
      } catch (err) {
        console.error(err);
      }
    },
  },
});
</script>

<style scoped>
.v-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.postiion {
  padding: 20px 40px;
}

.v-btn {
  margin-top: 20px;
}

.text-right {
  text-align: right;
}

.container {
  margin-top: 28px;
}

.part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task-button {
  margin-top: 0px;
  right: 0px;
}

.save-btn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.sticky-title {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
