import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, "Set Support Status as " + _toDisplayString(_ctx.newStatus), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-weight-medium" }, "Title", -1)),
                  _createTextVNode(": " + _toDisplayString(_ctx.supportModel?.Title), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-weight-medium" }, "Sender", -1)),
                  _createTextVNode(": " + _toDisplayString(_ctx.titleCase(_ctx.supportModel?.Sender)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "font-weight-medium" }, "Created At", -1)),
                  _createTextVNode(": " + _toDisplayString(_ctx.formatDate(_ctx.supportModel?.CreatedOn)), 1)
                ]),
                _: 1
              }),
              (_ctx.supportModel)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        disabled: !_ctx.supportModel.AllowedToSendReplyToSender,
                        color: "primary",
                        modelValue: _ctx.sendToSender,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sendToSender) = $event)),
                        "hide-details": "",
                        label: "Send reply to sender"
                      }, null, 8, ["disabled", "modelValue"]),
                      _createVNode(_component_v_switch, {
                        disabled: !_ctx.supportModel.AllowedToSendReplyToClient,
                        color: "primary",
                        modelValue: _ctx.sendToClient,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sendToClient) = $event)),
                        "hide-details": "",
                        label: "Send message to client"
                      }, null, 8, ["disabled", "modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.supportModel)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        disabled: !_ctx.supportModel.AllowedToSendReplyToAdmin,
                        color: "primary",
                        modelValue: _ctx.sendToAdmin,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sendToAdmin) = $event)),
                        "hide-details": "",
                        label: "Send message to admin"
                      }, null, 8, ["disabled", "modelValue"]),
                      _createVNode(_component_v_switch, {
                        disabled: !_ctx.supportModel.AllowedToSendReplyToSupport,
                        color: "primary",
                        modelValue: _ctx.sendToSupport,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sendToSupport) = $event)),
                        "hide-details": "",
                        label: "Send message to support"
                      }, null, 8, ["disabled", "modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextAreaField, {
                    dense: "",
                    outlined: "",
                    modelValue: _ctx.message,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.message) = $event)),
                    label: "Custome Message",
                    placeholder: "Custome Message"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "secondary",
                class: "mr-10",
                onClick: _ctx.setNewStatus,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Set as " + _toDisplayString(_ctx.newStatus), 1)
                ]),
                _: 1
              }, 8, ["onClick", "loading"]),
              _createVNode(_component_v_btn, {
                variant: "text",
                color: "primary",
                onClick: _ctx.close,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" cancel")
                ])),
                _: 1
              }, 8, ["onClick", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}