import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  dense: "",
  label: "Short Description",
  outlined: ""
}
const _hoisted_2 = {
  dense: "",
  label: "Text",
  outlined: ""
}
const _hoisted_3 = {
  dense: "",
  label: "Sms",
  outlined: ""
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.notificationItem.PushText), 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  dark: "",
                  onClick: _ctx.cancel,
                  fab: "",
                  class: "error",
                  "x-small": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.notificationItem.ShortDesc), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.notificationItem.Text), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.notificationItem.SmsText), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.notificationItem.EmailText
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SelectField, {
                      items: _ctx.users,
                      "item-title": "fullName",
                      "item-value": "id",
                      modelValue: _ctx.selectedUser,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUser) = $event)),
                      label: "Users",
                      outlined: "",
                      dense: ""
                    }, null, 8, ["items", "modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_data_table, {
                      items: _ctx.tempItems,
                      headers: _ctx.headers,
                      loading: _ctx.loading
                    }, {
                      "item.UserId": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.getUserName(item.UserId)), 1)
                      ]),
                      "item.Status": _withCtx(({ item }) => [
                        _createElementVNode("span", null, _toDisplayString(item.Status), 1)
                      ]),
                      "item.SeenDate": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(item.SeenDate)), 1)
                      ]),
                      _: 1
                    }, 8, ["items", "headers", "loading"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "2"
                }, {
                  default: _withCtx(() => [
                    _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Type: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.notificationItem.Type), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3"
                }, {
                  default: _withCtx(() => [
                    _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Section: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.notificationItem.Section), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Creation Date: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.notificationItem.CreationDate)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3"
                }, {
                  default: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Seen Date: ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.notificationItem.SeenDate)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}