<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">Support Notification</div>
        <div class="card-title-right">
          <v-btn color="primary" dark @click="close" variant="text" size="small" icon="mdi-close" />
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12"> <span class="font-weight-medium">Title</span>: {{ supportModel?.Title }} </v-col>
        <v-col cols="6"> <span class="font-weight-medium">Sender</span>: {{ titleCase(supportModel?.Sender) }} </v-col>
        <v-col cols="6"> <span class="font-weight-medium">Created At</span>: {{ formatDate(supportModel?.CreatedOn) }} </v-col>
        <v-col cols="12">
          <ChatBox
            title="Messages"
            :messages="chatMessages"
            :showReceivedBy="true"
            :buttons="getChatButtons()"
            @sendMessage="sendSupportNotification"
            :userId="getUserId"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  AdminSupportNotificationItemModel,
  AdminSupportNotificationReplyRequestModel,
  NotificationApi,
} from "shared-components/src/services/openApi/api";
import moment from "moment";
import ChatBox from "shared-components/src/components/ChatBox/ChatBox.vue";
import ChatBoxMessage from "shared-components/src/models/ChatBoxMessage";
import ChatBoxButton from "shared-components/src/models/ChatBoxButton";
import store from "@/store";

export default defineComponent({
  props: ["supportId"],
  components: { ChatBox },
  async mounted() {
    await this.fetchSupportItem();
  },
  data() {
    return {
      supportModel: null as AdminSupportNotificationItemModel | null,
      loading: false,
      chatMessages: [] as ChatBoxMessage[],
    };
  },
  methods: {
    getChatButtons(): ChatBoxButton[] {
      if (this.supportModel) {
        const items = [] as ChatBoxButton[];
        if (this.supportModel.AllowedToSendReplyToSender) {
          items.push({
            Icon: "mdi-send-circle",
            Key: "replyToSender",
            Text: "Send Reply To Sender",
          });
        }

        if (this.supportModel.AllowedToSendReplyToClient) {
          items.push({
            Icon: "mdi-send-circle",
            Key: "sendToClient",
            Text: "Send Notification To Client",
          });
        }

        if (this.supportModel.AllowedToSendReplyToAdmin) {
          items.push({
            Icon: "mdi-send-circle",
            Key: "sendToAdmin",
            Text: "Send Notification To Admin",
          });
        }

        if (this.supportModel.AllowedToSendReplyToSupport) {
          items.push({
            Icon: "mdi-send-circle",
            Key: "sendToSupport",
            Text: "Send Notification To Support",
          });
        }

        return items;
      }

      return [];
    },
    close() {
      this.$emit("close");
    },
    async sendSupportNotification(event: any) {
      if (this.supportId) {
        try {
          this.loading = true;
          await new NotificationApi().replySupportNotification({
            SupportTicketId: this.supportId,
            Message: event.Message,
            SendToAdmin: event.ButtonKey === "sendToAdmin",
            SendToClient: event.ButtonKey === "sendToClient",
            SendToSupport: event.ButtonKey === "sendToSupport",
            ReplyToSender: event.ButtonKey === "replyToSender",
          } as AdminSupportNotificationReplyRequestModel);
          await this.fetchSupportItem();
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchSupportItem() {
      if (this.supportId) {
        try {
          this.loading = true;
          const result = (await new NotificationApi().getSupportNotificationById(this.supportId)).data;
          this.supportModel = result;
          this.chatMessages = [];

          if (this.supportModel.Replies && this.supportModel.Replies.length > 0)
            this.supportModel.Replies?.forEach((item) => {
              this.chatMessages.push({
                CreatedOn: item.CreatedOn,
                CreatorUserId: item.SenderId,
                Message: item.Message,
                ReceivedBy: [item.Receiver],
                SentBy: item.Sender,
              } as ChatBoxMessage);
            });
        } finally {
          this.loading = false;
        }
      }
    },
    formatDate(strDate: string | undefined) {
      if (strDate) {
        return moment(strDate).format("YYYY-MM-DD HH:MM:ss");
      }
      return "-";
    },
    titleCase(str: string | undefined) {
      if (str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      }
      return "-";
    },
  },
  computed: {
    getUserId() {
      return store.state.userInfo.id;
    },
  },
});
</script>
