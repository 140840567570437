import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "action-container"
}
const _hoisted_3 = { class: "review-container" }
const _hoisted_4 = {
  key: 1,
  class: "review-arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ReviewCommitmentItem = _resolveComponent("ReviewCommitmentItem")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.pageLoading,
    disabled: _ctx.pageLoading
  }, {
    default: _withCtx(() => [
      (!_ctx.pageLoading && _ctx.reviewModel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _cache[6] || (_cache[6] = _createElementVNode("span", null, "Review Commitment", -1)),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      dark: "",
                      onClick: _ctx.cancel,
                      fab: "",
                      class: "error",
                      "x-small": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("mdi-close")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                (_ctx.reviewModel?.FromCommitment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _cache[7] || (_cache[7] = _createElementVNode("span", null, "Applied Actions:", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviewModel?.Actions?.ActionItems, (action, index) => {
                        return (_openBlock(), _createElementBlock("b", { key: index }, _toDisplayString(action.DisplayName), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.reviewModel?.FromCommitment)
                    ? (_openBlock(), _createBlock(_component_ReviewCommitmentItem, {
                        key: 0,
                        class: "review-item",
                        reviewModel: _ctx.reviewModel
                      }, null, 8, ["reviewModel"]))
                    : _createCommentVNode("", true),
                  (_ctx.reviewModel?.FromCommitment)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, "=>"))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ReviewCommitmentItem, {
                    class: "review-item",
                    reviewModel: _ctx.reviewModel,
                    isNewCommitment: true
                  }, null, 8, ["reviewModel"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  md: "12",
                  class: "text-right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "primary_btn mr-5",
                      dark: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUpdateStatus(true)))
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Reject")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "secondary_btn mr-5",
                      dark: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateStatus(false)))
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Approve")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showUpdateStatusPopup,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showUpdateStatusPopup) = $event)),
        persistent: "",
        "max-width": "400px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!_ctx.rejectMode ? "Approve Commitment" : "Reject Commitment"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("span", null, "Are you sure about this action ?", -1)
                    ])),
                    _: 1
                  }),
                  (_ctx.rejectMode)
                    ? (_openBlock(), _createBlock(_component_TextAreaField, {
                        key: 0,
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.rejectMessage,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rejectMessage) = $event)),
                        showRequired: "",
                        label: "Reason of Rejecting",
                        placeholder: "Reason of Rejecting"
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    md: "12",
                    class: "d-flex justify-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        loading: _ctx.loading,
                        class: "primary_btn mr-5",
                        dark: "",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdateStatusPopup = false))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("Cancel")
                        ])),
                        _: 1
                      }, 8, ["loading"]),
                      _createVNode(_component_v_btn, {
                        loading: _ctx.loading,
                        class: "secondary_btn",
                        dark: "",
                        onClick: _ctx.updateCommitmentStatus
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(!_ctx.rejectMode ? "Yes! Approve it" : "Yes! Reject it"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}