import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline" }
const _hoisted_2 = { class: "task-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_ChatBox = _resolveComponent("ChatBox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pb-5" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedRow.TaskTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _ctx.closeDialog,
                    variant: "text"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { gutter: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "d-flex align-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_avatar, {
                        class: "mr-1",
                        color: _ctx.getStatusColor(_ctx.item.Status),
                        size: "12"
                      }, null, 8, ["color"]),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.Status), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Job Opening", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.selectedRow.PositionTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Hiring Manager", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.selectedRow.HiringManagerName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Date", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.item.Date), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Priority", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.item.OrderNumber), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Description", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.item.Description), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ChatBox, {
                        title: "Messages",
                        buttons: _ctx.chatButtons,
                        messages: _ctx.chatMessages,
                        onSendMessage: _ctx.sendMessage,
                        userId: _ctx.getUserId
                      }, null, 8, ["buttons", "messages", "onSendMessage", "userId"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}