import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_CreateOrEditVM = _resolveComponent("CreateOrEditVM")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_VdSupport = _resolveComponent("VdSupport")!
  const _component_ChangeStatus = _resolveComponent("ChangeStatus")!
  const _component_ActionLogList = _resolveComponent("ActionLogList")!
  const _component_ActivityWatch = _resolveComponent("ActivityWatch")!
  const _component_Wazuh = _resolveComponent("Wazuh")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[31] || (_cache[31] = _createTextVNode(" VM List ")),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "primary",
                class: "mr-4",
                dark: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMaintenancePopup()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode("mdi-bell-outline")
                    ])),
                    _: 1
                  }),
                  _cache[28] || (_cache[28] = _createTextVNode(" Send Support Announcement"))
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                color: "primary",
                dark: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                      _createTextVNode("mdi-plus")
                    ])),
                    _: 1
                  }),
                  _cache[30] || (_cache[30] = _createTextVNode(" Register new VM"))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                md: "3",
                sm: "4",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    items: _ctx.teammembers,
                    "item-title": "FullName",
                    "item-value": "Id",
                    modelValue: _ctx.selectedTeammemberFilter,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTeammemberFilter) = $event)),
                    firstInactiveId: _ctx.firstInactiveTeammemberId,
                    label: "Team Member",
                    outlined: "",
                    dense: "",
                    "hide-details": ""
                  }, null, 8, ["items", "modelValue", "firstInactiveId"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "3",
                sm: "4",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    items: _ctx.customers,
                    "item-title": "FullName",
                    "item-value": "id",
                    modelValue: _ctx.selectedCustomerFilter,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCustomerFilter) = $event)),
                    label: "Customer",
                    outlined: "",
                    dense: "",
                    "hide-details": ""
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "3",
                sm: "4",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextField, {
                    outlined: "",
                    dense: "",
                    label: "Public IP",
                    placeholder: "Public IP",
                    modelValue: _ctx.selectedPublicIpFilter,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedPublicIpFilter) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "3",
                sm: "4",
                cols: "12",
                class: "text-right pt-12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "mr-3",
                    onClick: _ctx.clearFilter
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { left: "" }, {
                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      }),
                      _cache[33] || (_cache[33] = _createTextVNode(" clear Filter "))
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    onClick: _ctx.fetchVMs
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { left: "" }, {
                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                          _createTextVNode("mdi-check")
                        ])),
                        _: 1
                      }),
                      _cache[35] || (_cache[35] = _createTextVNode(" Filter "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_form, {
            ref: "frmList",
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "secondary",
                onClick: _ctx.removeSelection
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[36] || (_cache[36] = [
                      _createTextVNode("mdi-tag-remove-outline")
                    ])),
                    _: 1
                  }),
                  _cache[37] || (_cache[37] = _createTextVNode(" Deselect all"))
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_data_table_server, {
                items: _ctx.vmList,
                headers: _ctx.headers,
                loading: _ctx.loading,
                page: _ctx.page,
                "items-per-page": _ctx.numberOfPages,
                "onUpdate:options": _cache[5] || (_cache[5] = (event) => _ctx.tableOptions = event),
                "items-length": _ctx.totalItemsCount
              }, {
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", {
                    class: _normalizeClass(_ctx.getClassByStatus(item))
                  }, [
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_checkbox, {
                        class: "vm-checkbox",
                        modelValue: item.IsSelected,
                        "onUpdate:modelValue": ($event: any) => ((item.IsSelected) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _createElementVNode("td", {
                      title: item.ReleatedCommitmentsFullName
                    }, _toDisplayString(item.MostRecentCommitmentName), 9, _hoisted_1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getTMName(item.OwnerId)), 1),
                    _createElementVNode("td", null, _toDisplayString(item.Username), 1),
                    _createElementVNode("td", null, _toDisplayString(item.Password), 1),
                    _createElementVNode("td", null, _toDisplayString(item.PublicIP), 1),
                    _createElementVNode("td", null, _toDisplayString(item.PrivateIP), 1),
                    _createElementVNode("td", null, [
                      (item.Status)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 0,
                            color: _ctx.getStatusColor(item.Status),
                            dark: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.Status), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_menu, {
                        "offset-y": "",
                        location: "bottom",
                        "scroll-strategy": "close"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            size: "small",
                            variant: "text",
                            color: "black"
                          }, props, { icon: "mdi-dots-vertical" }), null, 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.editVM(item)),
                                "prepend-icon": "mdi-pencil-outline",
                                title: "Edit"
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.showSupportTicket(item)),
                                "prepend-icon": "mdi-bell-outline",
                                title: "Support Ticket"
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.showActivityWatch(item)),
                                "prepend-icon": "mdi-chart-line",
                                title: "Show Activity Watch"
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.showWazuhDashboard(item)),
                                "prepend-icon": "mdi-shield-check",
                                title: "Show Wazuh Report"
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.showVmActionLog(item)),
                                "prepend-icon": "mdi-list-box",
                                title: "Show Action Log"
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_v_menu, {
                                "offset-y": "",
                                location: "left"
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, { small: "" }, {
                                        default: _withCtx(() => _cache[38] || (_cache[38] = [
                                          _createTextVNode("mdi-content-save-cog")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => _cache[39] || (_cache[39] = [
                                          _createTextVNode("Status")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1040)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vmStatuses, (statusName) => {
                                        return (_openBlock(), _createBlock(_component_v_list_item, {
                                          onClick: ($event: any) => (_ctx.openStatusPopup(item, statusName))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(statusName), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      }), 256))
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ], 2)
                ]),
                _: 1
              }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"])
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        width: "auto",
        modelValue: _ctx.showDialog,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
        onKeypress: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CreateOrEditVM, {
            teammembers: _ctx.teammembers,
            onCancel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.close())),
            onSave: _ctx.save,
            loading: _ctx.loading,
            vmModel: _ctx.selectedVM
          }, null, 8, ["teammembers", "onSave", "loading", "vmModel"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.showMaintenanceDialog)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            width: "600",
            modelValue: _ctx.showMaintenanceDialog,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showMaintenanceDialog) = $event)),
            onKeypress: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VdSupport, {
                teammembers: _ctx.teammembers,
                onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.close())),
                onSendRequest: _ctx.createNotification,
                vmList: _ctx.selectedVmList
              }, null, 8, ["teammembers", "onSendRequest", "vmList"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showStatusPopup)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 1,
            persistent: "",
            modelValue: _ctx.showStatusPopup,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showStatusPopup) = $event)),
            "max-width": "600",
            onKeydown: _cache[14] || (_cache[14] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ChangeStatus, {
                status: _ctx.selectedVmStatus,
                vmId: _ctx.selectedVmId,
                onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.close())),
                onConfirmed: _ctx.changeVmStatus
              }, null, 8, ["status", "vmId", "onConfirmed"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showActionLogPopup)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 2,
            persistent: "",
            modelValue: _ctx.showActionLogPopup,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showActionLogPopup) = $event)),
            "max-width": "600",
            onKeydown: _cache[17] || (_cache[17] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ActionLogList, {
                vmId: _ctx.selectedVmId,
                onClose: _cache[15] || (_cache[15] = ($event: any) => (_ctx.close()))
              }, null, 8, ["vmId"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showActivityWatchDialog)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 3,
            persistent: "",
            modelValue: _ctx.showActivityWatchDialog,
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.showActivityWatchDialog) = $event)),
            fullscreen: "",
            transition: "dialog-bottom-transition",
            onKeydown: _cache[20] || (_cache[20] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ActivityWatch, {
                vmId: _ctx.selectedVmId,
                onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.close()))
              }, null, 8, ["vmId"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showWazuhDialog)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 4,
            persistent: "",
            modelValue: _ctx.showWazuhDialog,
            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.showWazuhDialog) = $event)),
            fullscreen: "",
            transition: "dialog-bottom-transition",
            onKeydown: _cache[23] || (_cache[23] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Wazuh, {
                vmId: _ctx.selectedVmId,
                onClose: _cache[21] || (_cache[21] = ($event: any) => (_ctx.close()))
              }, null, 8, ["vmId"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showSupportDialog)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 5,
            width: "600",
            modelValue: _ctx.showSupportDialog,
            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.showSupportDialog) = $event)),
            onKeypress: _cache[26] || (_cache[26] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VdSupport, {
                onCancel: _cache[24] || (_cache[24] = ($event: any) => (_ctx.close())),
                onSendRequest: _ctx.createSupportNotification,
                isSupport: true,
                vmId: _ctx.selectedVmId
              }, null, 8, ["onSendRequest", "vmId"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}