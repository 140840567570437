import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_TaskDetail = _resolveComponent("TaskDetail")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_NewTask = _resolveComponent("NewTask")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "title" }, {
                      default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "headline" }, "Task List", -1)),
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          onClick: _ctx.openNewTaskDialog
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { left: "" }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("mdi-plus")
                              ])),
                              _: 1
                            }),
                            _cache[8] || (_cache[8] = _createTextVNode(" New Task "))
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: _ctx.isHiringmanager ? 4 : 3,
                              xs: "12",
                              sm: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  "item-title": "Title",
                                  "item-value": "Id",
                                  modelValue: _ctx.namePositionFilter,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.namePositionFilter) = $event)),
                                  items: _ctx.namePosition,
                                  dense: "",
                                  outlined: "",
                                  label: "Job Opening",
                                  placeholder: "Job Opening",
                                  type: "text"
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            }, 8, ["md"]),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: _ctx.isHiringmanager ? 4 : 3,
                              xs: "12",
                              sm: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextField, {
                                  modelValue: _ctx.taskTitleFilter,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.taskTitleFilter) = $event)),
                                  dense: "",
                                  outlined: "",
                                  label: "Task Title",
                                  placeholder: "Task Title",
                                  type: "text"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }, 8, ["md"]),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: _ctx.isHiringmanager ? 4 : 3,
                              xs: "12",
                              sm: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_DateInputField, {
                                  "onClick:clear": _ctx.onDateClear,
                                  clearable: "",
                                  "first-day-of-week": "1",
                                  modelValue: _ctx.datefilter,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.datefilter) = $event)),
                                  "hide-actions": "",
                                  label: "Task Date",
                                  placeholder: "Date",
                                  "prepend-icon": ""
                                }, null, 8, ["onClick:clear", "modelValue"])
                              ]),
                              _: 1
                            }, 8, ["md"]),
                            (!_ctx.isHiringmanager)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  cols: "12",
                                  md: _ctx.isHiringmanager ? 4 : 3,
                                  xs: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_AutoCompleteField, {
                                      items: _ctx.hiringManagerList,
                                      "item-title": "fullName",
                                      "item-value": "id",
                                      modelValue: _ctx.hiringManagerFilter,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hiringManagerFilter) = $event)),
                                      dense: "",
                                      outlined: "",
                                      label: "Hiring Manager",
                                      placeholder: "Hiring Manager",
                                      type: "text"
                                    }, null, 8, ["items", "modelValue"])
                                  ]),
                                  _: 1
                                }, 8, ["md"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "filter"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, { onClick: _ctx.clearFilter }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { left: "" }, {
                                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createTextVNode("mdi-close")
                                  ])),
                                  _: 1
                                }),
                                _cache[11] || (_cache[11] = _createTextVNode(" clear Filter "))
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              color: "primary",
                              onClick: _ctx.filteredTasks
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { left: "" }, {
                                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                                    _createTextVNode("mdi-check")
                                  ])),
                                  _: 1
                                }),
                                _cache[13] || (_cache[13] = _createTextVNode(" Filter "))
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_data_table_server, {
                      loading: _ctx.dataLoading,
                      headers: _ctx.headerTask,
                      items: _ctx.tableTasks,
                      class: "elevation-1 pr-5 pl-5",
                      page: _ctx.page,
                      "items-per-page": _ctx.numberOfPages,
                      "items-length": _ctx.totalItemsCount,
                      "onUpdate:options": _cache[4] || (_cache[4] = (event) => _ctx.tableOptions = event)
                    }, {
                      "item.Status": _withCtx(({ item }) => [
                        _createVNode(_component_v_chip, {
                          color: _ctx.getStatusColor(item.Status),
                          dark: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.Status), 1)
                          ]),
                          _: 2
                        }, 1032, ["color"])
                      ]),
                      "item.actions": _withCtx(({ item }) => [
                        _createVNode(_component_v_menu, {
                          "offset-y": "",
                          location: "left"
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_btn, _mergeProps({
                              "x-small": "",
                              icon: ""
                            }, props, { variant: "text" }), {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                                    _createTextVNode("mdi-dots-vertical")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, { density: "compact" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (actionItem, index) => {
                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                    disabled: actionItem.text === 'Edit' && _ctx.isHiringmanager,
                                    key: index,
                                    onClick: ($event: any) => (actionItem.action(item)),
                                    color: "primary"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        icon: actionItem.icon,
                                        class: "mr-1",
                                        color: "blue",
                                        small: ""
                                      }, null, 8, ["icon"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, {
                                        textContent: _toDisplayString(actionItem.text),
                                        class: "text-left"
                                      }, null, 8, ["textContent"])
                                    ]),
                                    _: 2
                                  }, 1032, ["disabled", "onClick"]))
                                }), 128)),
                                _createVNode(_component_v_menu, {
                                  "offset-y": "",
                                  location: "left"
                                }, {
                                  activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                                      prepend: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          color: "blue",
                                          small: ""
                                        }, {
                                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                                            _createTextVNode("mdi-content-save-cog")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                                            _createTextVNode("Status")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1040)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item, {
                                          onClick: ($event: any) => (_ctx.changeStatusTask(item, 'New'))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                _createTextVNode("New")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]),
                                        _createVNode(_component_v_list_item, {
                                          onClick: ($event: any) => (_ctx.changeStatusTask(item, 'Active'))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                _createTextVNode("Active")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]),
                                        _createVNode(_component_v_list_item, {
                                          onClick: ($event: any) => (_ctx.changeStatusTask(item, 'Resolved'))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                _createTextVNode("Resolved")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]),
                                        _createVNode(_component_v_list_item, {
                                          onClick: ($event: any) => (_ctx.changeStatusTask(item, 'Closed'))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                _createTextVNode("Closed")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]),
                                        _createVNode(_component_v_list_item, {
                                          onClick: ($event: any) => (_ctx.changeStatusTask(item, 'Archived'))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_list_item_title, null, {
                                              default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                _createTextVNode("Archived")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }, 8, ["loading", "headers", "items", "page", "items-per-page", "items-length"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.taskDialog)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 0,
              "max-width": "800px",
              modelValue: _ctx.taskDialog,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.taskDialog) = $event)),
              transition: "dialog-bottom-transition"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TaskDetail, {
                  selectedRow: _ctx.selectedRow,
                  isHiringmanager: _ctx.isHiringmanager,
                  item: _ctx.selectedTask,
                  onCloseDialog: _ctx.closeDialog
                }, null, 8, ["selectedRow", "isHiringmanager", "item", "onCloseDialog"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.newTaskDialog)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 1,
              modelValue: _ctx.newTaskDialog,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newTaskDialog) = $event)),
              fullscreen: "",
              "hide-overlay": "",
              transition: "dialog-bottom-transition"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NewTask, {
                  isHiringmanager: _ctx.isHiringmanager,
                  "is-editing": _ctx.isEditing,
                  task: _ctx.selectedTask,
                  onTaskSaved: _ctx.TaskSaved,
                  onCloseTaskDialog: _ctx.closeNewTaskDialog
                }, null, 8, ["isHiringmanager", "is-editing", "task", "onTaskSaved", "onCloseTaskDialog"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}