import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "font-weight-light" }
const _hoisted_3 = { class: "font-weight-light" }
const _hoisted_4 = { class: "font-weight-light" }
const _hoisted_5 = { class: "font-weight-light" }
const _hoisted_6 = { class: "font-weight-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateRangeInputField = _resolveComponent("DateRangeInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, "Account Ledger Report for " + _toDisplayString(_ctx.accountName), 1),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  dark: "",
                  onClick: _ctx.cancel,
                  fab: "",
                  class: "error",
                  "x-small": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3",
                  sm: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DateRangeInputField, {
                      modelValue: _ctx.selectedDateRange,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateRange) = $event)),
                      label: "Report Date Range",
                      "first-day-of-week": "1",
                      "hide-actions": "",
                      placeholder: "Select Report Date Range",
                      "prepend-icon": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "1",
                  sm: "1",
                  class: "mt-9"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, {
                      "offset-y": "",
                      left: "",
                      attach: ""
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({
                          small: "",
                          icon: "",
                          light: ""
                        }, props), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, {
                              icon: "",
                              cir: ""
                            }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode(" mdi-menu-down ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_text, { class: "pa-6" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, { flat: "" }, {
                                  default: _withCtx(() => [
                                    _cache[4] || (_cache[4] = _createTextVNode(" Options ")),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateRanges, (item) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                        key: item,
                                        value: item,
                                        title: item,
                                        onClick: ($event: any) => (_ctx.selectedRange(item))
                                      }, null, 8, ["value", "title", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  md: "2",
                  sm: "4",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "accent action-button mt-9",
                      color: "primary",
                      loading: _ctx.loading,
                      onClick: _ctx.createReport
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("mdi-sync")
                          ])),
                          _: 1
                        }),
                        _cache[6] || (_cache[6] = _createTextVNode(" Process "))
                      ]),
                      _: 1
                    }, 8, ["loading", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_data_table_server, {
              items: _ctx.items,
              headers: _ctx.headers,
              loading: _ctx.loading,
              page: _ctx.page,
              "items-per-page": _ctx.numberOfPages,
              "onUpdate:options": _cache[1] || (_cache[1] = (event) => _ctx.tableOptions = event),
              "items-length": _ctx.totalItemsCount,
              "hide-default-footer": ""
            }, {
              "item.date": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formatDate(item.date)), 1)
              ]),
              "item.description": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.description), 1)
              ]),
              "item.referenceNumber": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.referenceNumber), 1)
              ]),
              "item.debit": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.GetFormatTwoDecimal(item.debit)), 1)
              ]),
              "item.credit": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.GetFormatTwoDecimal(item.credit)), 1)
              ]),
              _: 1
            }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}