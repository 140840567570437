import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "onClick"]
const _hoisted_2 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_add_activity = _resolveComponent("add-activity")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                      default: _withCtx(() => [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mt-3" }, "Activity List", -1)),
                        _createVNode(_component_v_divider, {
                          class: "mx-4",
                          inset: "",
                          vertical: ""
                        }),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          class: "mt-2 mr-3",
                          dark: "",
                          onClick: _ctx.ShowAddActivityPopup
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("mdi-plus")
                              ])),
                              _: 1
                            }),
                            _cache[4] || (_cache[4] = _createTextVNode(" New Activity "))
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "mt-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_data_table, {
                      class: "pt-0",
                      items: _ctx.activities,
                      "item-class": _ctx.row_classes,
                      loading: _ctx.Loading,
                      headers: _ctx.headers
                    }, {
                      "item.Value": _withCtx(({ item }) => [
                        _createElementVNode("strong", null, _toDisplayString(item.Activity.Value) + " " + _toDisplayString(item.Activity.Disabled ? "- Disabled" : ""), 1)
                      ]),
                      "item.IconClass": _withCtx(({ item }) => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.Activity.IconClass), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      "item.Actions": _withCtx(({ item }) => [
                        _createElementVNode("button", {
                          disabled: item.Loading,
                          onClick: ($event: any) => (_ctx.ShowUpdateActivityPopup(item.Activity)),
                          title: "Update"
                        }, [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("mdi-lead-pencil")
                            ])),
                            _: 1
                          })
                        ], 8, _hoisted_1),
                        _createElementVNode("button", {
                          disabled: item.Loading,
                          onClick: ($event: any) => (_ctx.SetActivityAsDisabled(item.Activity))
                        }, [
                          (!item.Activity.Disabled)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                title: "Disable",
                                disabled: item.Loading
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("mdi-checkbox-blank-circle")
                                ])),
                                _: 2
                              }, 1032, ["disabled"]))
                            : _createCommentVNode("", true),
                          (item.Activity.Disabled)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 1,
                                title: "Active",
                                disabled: item.Loading
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode("mdi-checkbox-blank-circle-outline")
                                ])),
                                _: 2
                              }, 1032, ["disabled"]))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_2)
                      ]),
                      _: 1
                    }, 8, ["items", "item-class", "loading", "headers"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showPopup,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPopup) = $event)),
      persistent: "",
      "max-width": "600",
      onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.showPopup = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        (_ctx.showPopup)
          ? (_openBlock(), _createBlock(_component_add_activity, {
              key: 0,
              onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopup = false)),
              onSave: _ctx.ActivityAdded,
              activityModel: _ctx.selectedActivity,
              activityList: _ctx.activities
            }, null, 8, ["onSave", "activityModel", "activityList"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}