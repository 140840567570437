import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-end" }
const _hoisted_2 = { class: "text-end" }
const _hoisted_3 = { class: "text-end" }
const _hoisted_4 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        class: "mx-3",
        disabled: _ctx.loading,
        "append-icon": "mdi-refresh",
        text: "Refresh",
        variant: "outlined",
        onClick: _ctx.fetchDataTables
      }, null, 8, ["disabled", "onClick"])
    ]),
    _createVNode(_component_v_tabs, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      "align-tabs": "center",
      color: "deep-purple-accent-4",
      "fixed-tabs": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, {
          value: 1,
          "prepend-icon": "mdi-import"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Import")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tab, {
          value: 2,
          "prepend-icon": "mdi-export"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Export")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_tabs_window, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tab) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_v_tabs_window_item, {
          key: 1,
          value: 1
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "mt-3 mx-3 justify-end" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    class: "mx-3",
                    disabled: !_ctx.anyFileSelected,
                    "append-icon": "mdi-delete",
                    text: "Delete",
                    variant: "outlined",
                    onClick: _ctx.deleteSelectedFiles
                  }, null, 8, ["disabled", "onClick"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_btn, {
                    class: "mx-3",
                    disabled: !_ctx.anyFileSelected,
                    "append-icon": "mdi-import",
                    text: "Import",
                    variant: "outlined",
                    onClick: _ctx.importSelectedFiles
                  }, null, 8, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_data_table, {
              modelValue: _ctx.selectedFiles,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFiles) = $event)),
              items: _ctx.fileInfo,
              loading: _ctx.loading,
              "item-value": "name",
              "show-select": ""
            }, {
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 1
            }, 8, ["modelValue", "items", "loading"]),
            _createVNode(_component_v_row, { justify: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_file_input, {
                      modelValue: _ctx.files,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.files) = $event)),
                      chips: "",
                      multiple: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "mx-2",
                      disabled: !_ctx.anyFileToUpload,
                      "append-icon": "mdi-upload-multiple",
                      text: "Upload",
                      variant: "outlined",
                      onClick: _ctx.uploadFiles
                    }, null, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
        (_openBlock(), _createBlock(_component_v_tabs_window_item, {
          key: 2,
          value: 2
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_v_btn, {
                class: "mx-3",
                disabled: !_ctx.selectedSomeUser,
                "append-icon": "mdi-export",
                text: "Export",
                variant: "outlined",
                onClick: _ctx.exportSelectedUsers
              }, null, 8, ["disabled", "onClick"])
            ]),
            _createVNode(_component_v_divider, {
              class: "mt-4",
              inset: ""
            }),
            _createVNode(_component_v_data_table, {
              modelValue: _ctx.selectedUsers,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedUsers) = $event)),
              items: _ctx.users,
              loading: _ctx.loading,
              "item-value": "id",
              "show-select": ""
            }, {
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 1
            }, 8, ["modelValue", "items", "loading"])
          ]),
          _: 1
        }))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}