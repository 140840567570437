<template>
  <WeeklyTimesheetAuditVue :isAdmin="true"></WeeklyTimesheetAuditVue>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WeeklyTimesheetAuditVue from "shared-components/src/components/weeklyTimesheetAudit/weeklyTimesheetAudit.vue";

export default defineComponent({
  components: {
    WeeklyTimesheetAuditVue,
  },
  data() {
    return {};
  },
  async mounted() {},
});
</script>
