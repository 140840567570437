import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_new_notification = _resolveComponent("new-notification")!
  const _component_notification_detail = _resolveComponent("notification-detail")!
  const _component_loading_component = _resolveComponent("loading-component")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_v_col, { md: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { loading: _ctx.loading }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _cache[15] || (_cache[15] = _createElementVNode("span", { class: "mt-2" }, " Notification Management", -1)),
                      _createVNode(_component_v_divider, {
                        class: "mx-4",
                        inset: "",
                        vertical: ""
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        class: "mt-3 mr-1",
                        dark: "",
                        onClick: _ctx.newNotif
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("mdi-plus")
                            ])),
                            _: 1
                          }),
                          _cache[14] || (_cache[14] = _createTextVNode(" New Notice "))
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2",
                        sm: "3",
                        xs: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DateInputField, {
                            label: "From date",
                            "first-day-of-week": "1",
                            modelValue: _ctx.startDate,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
                            "hide-actions": "",
                            placeholder: "Date",
                            "prepend-icon": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "2",
                        sm: "3",
                        xs: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DateInputField, {
                            label: "To date",
                            "first-day-of-week": "1",
                            modelValue: _ctx.endDate,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event)),
                            "hide-actions": "",
                            placeholder: "Date",
                            "prepend-icon": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.users,
                            "item-title": "fullName",
                            "item-value": "id",
                            modelValue: _ctx.selectedUser,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedUser) = $event)),
                            label: "User",
                            outlined: "",
                            dense: ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "6",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_switch, {
                            class: "pb-0 mt-6",
                            label: "Read",
                            modelValue: _ctx.isRead,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isRead) = $event)),
                            color: "success"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "6",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "secondary",
                            class: "mt-0",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearFilters()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createTextVNode("mdi-close")
                                ])),
                                _: 1
                              }),
                              _cache[17] || (_cache[17] = _createTextVNode(" Clear "))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    items: _ctx.tempNotifications,
                    headers: _ctx.headers,
                    loading: _ctx.loading
                  }, {
                    "item.ShortDesc": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.ShortDesc), 1)
                    ]),
                    "item.Users": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Users ? _ctx.formatUser(item.Users) : ""), 1)
                    ]),
                    "item.Section": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Section), 1)
                    ]),
                    "item.Seen": _withCtx(({ item }) => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        readonly: "",
                        modelValue: item.Seen,
                        "onUpdate:modelValue": ($event: any) => ((item.Seen) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    "item.CreationDate": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.CreationDate)), 1)
                    ]),
                    "item.Schedule": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.Schedule)), 1)
                    ]),
                    "item.SeenDate": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.SeenDate)), 1)
                    ]),
                    "item.Status": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Status), 1)
                    ]),
                    "item.Actions": _withCtx(({ item }) => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_btn, {
                          class: "mt-1",
                          variant: "text",
                          size: "small",
                          title: "show more inforamtion!",
                          onClick: ($event: any) => (_ctx.showNotifDetail(item)),
                          icon: "mdi-magnify"
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["items", "headers", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showConfirm,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showConfirm) = $event)),
        "max-width": "500",
        onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_confirm_dialog, {
            message: _ctx.confirmModel,
            onConfirm: _ctx.confirmRemove,
            onCancel: _ctx.cancelRemove
          }, null, 8, ["message", "onConfirm", "onCancel"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.showNewNotif)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            modelValue: _ctx.showNewNotif,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showNewNotif) = $event)),
            persistent: "",
            fullscreen: "",
            onKeydown: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.showNewNotif = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_new_notification, {
                onCancel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showNewNotif = false)),
                onSaved: _ctx.notifSaved,
                users: _ctx.users
              }, null, 8, ["onSaved", "users"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.ShowNotifDetailPopUp)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 1,
            modelValue: _ctx.ShowNotifDetailPopUp,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.ShowNotifDetailPopUp) = $event)),
            persistent: "",
            onKeydown: _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.ShowNotifDetailPopUp = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_notification_detail, {
                notificationItem: _ctx.selectedNotification,
                users: _ctx.users,
                onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.ShowNotifDetailPopUp = false))
              }, null, 8, ["notificationItem", "users"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}