<template>
    <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="applications"
    :items-length="totalItems"
    :loading="loading"
    :search="search"
    item-value="name"
    @update:options="fetchListOfCandidatesShortlisted"
  >
    <template v-slot:item.actions="{ item, index }">
      <v-btn v-if="!showComboBoxes[index]" @click="toggleComboBox(index)" class="text-uppercase" size="small" color="green" text="Change Status">
      </v-btn>
      <v-row v-if="showComboBoxes[index]">
        <v-col cols="8">
        <ComboBoxField
          v-model="item.Status"
          :items="statuses"
          clearable
          dense
          hide-details/>
        </v-col>
        <v-col cols="4">
          <v-btn class="ma-auto" @click="changePositionApplicationStatus(item, index)" color="green">Done</v-btn>
        </v-col>
      </v-row>
    </template>
  </v-data-table-server>
</template>
<script lang="ts">
import {
  BaseRequestModel,
  PositionApi,
  PositionApplicationWithTeammemberSummaryInfoResponseModel as PositionApplicationInfo,
  PositionApplicationRequestModel, PositionApplicationRequestModelStatusEnum
} from "shared-components/src/services/openApi/api";
import { defineComponent } from "vue";
import ComboBoxField from "shared-components/src/components/Fields/ComboBoxField.vue";
import store from "@/store";

export default defineComponent({
  props: {
    positionId: {
      type: String,
      required: true,
    },
  },
  components: { ComboBoxField, },
  data() {
    return {
      itemsPerPage: 10,
      headers: [
        { title: "Teammember Email", align: "start", sortable: false, key: "TeammemberInfo.Email",},
        { title: "Application Date", key: "ApplicationDate" },
        { title: "Source", key: "Source" },
        { title: "Status", key: "Status" },
        { title: "Scores", sortable: false, key: "Scores" },
        { title: "Actions", sortable: false, key: "actions", },
      ],
      search: "",
      applications: [] as PositionApplicationInfo[],
      loading: true,
      totalItems: 0,
      showComboBoxes: [] as boolean[],
      selectedStatus: "" as string | null,
      statuses: Object.values(PositionApplicationRequestModelStatusEnum) as string[],
    };
  },
  async mounted() {
    await this.fetchListOfCandidatesShortlisted({});
  },
  methods: {
    async changePositionApplicationStatus(position: PositionApplicationInfo, index: number) {
      this.showComboBoxes[index] = false;
      var request = {
        "Status": position.Status,
        "PositionId": this.positionId
      } as PositionApplicationRequestModel;
      try {
        await new PositionApi().updatePositionApplication(request);
        store.dispatch("showSuccessMessage", "Position's Application Updated Successfully.");
      } catch (error) {
        store.dispatch("showErrorMessage", "Failed to Update Position's Application.");
      }
    },
    toggleComboBox(index: number) {
      this.showComboBoxes[index] = true;
    },
    async fetchListOfCandidatesShortlisted({ page, itemsPerPage, sortBy }: any) {
      this.loading = true;
      var request = {fields: {"PositionId": this.positionId}, sortProps: sortBy ?? [{"key": "ApplicationDate", "order": "desc"}]} as BaseRequestModel;
      this.applications = (await new PositionApi().getPositionApplicationForAdmin(request)).data;
      this.totalItems = this.applications.length;
      this.showComboBoxes = new Array(this.totalItems).fill(false);
      this.loading = false;
    },
  },
});
</script>