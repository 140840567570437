<template>
  <div>
    <v-card class="overflow-visible">
      <v-card-title>
        Revenue
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-row>
        <v-col class="filters ml-2" md="3">
          <label class="input-label">Date</label>
          <VueDatePicker v-model="selectedMonth" month-picker auto-apply />
        </v-col>
        <v-col class="filters" md="4">
          <SelectField
            :items="customers"
            item-title="text"
            item-value="id"
            v-model="model.selectedCustomer"
            label="Customer"
            placeholder="Select Customer"
            outlined
          ></SelectField>
        </v-col>
        <v-col class="filters mt-11" md="4">
          <v-btn color="primary" @click="loadData()"> <v-icon>mdi-sync</v-icon> Process </v-btn>
          <v-btn color="secondary" class="ml-2" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
        </v-col>
      </v-row>
      <v-card-text> </v-card-text>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" :loading="loading" :custom-sort="customSort" :items-per-page="-1" hide-default-footer>
          <template v-slot:item.Index="{ item }">
            <strong v-if="item.isSum"></strong>
            <strong v-else>{{ item.Index }}</strong>
          </template>
          <template v-slot:item.customerName="{ item }">
            <strong v-if="item.isSum">Total Payments</strong>
            <span v-else>{{ item.customerName }}</span>
          </template>
          <template v-slot:item.fromDate="{ item }">
            <span>{{ getMonthName(item) }}</span>
          </template>

          <template v-slot:item.amount="{ item }">
            <strong v-if="item.isSum">{{ getTotalAudOfReport(items) }}</strong>
            <span v-else-if="item.customerInvoice && item.customerInvoice.audTotalAmount">{{
              moneyFormat(item.customerInvoice.audTotalAmount)
            }}</span>
          </template>
          <template v-slot:item.paymentAmount="{ item }">
            <strong v-if="item.isSum">{{ getTotalAudPaymentOfReport(items) }}</strong>
            <span v-else-if="item.customerInvoice && item.customerInvoice.totalPaymentAmount">{{
              moneyFormat(item.customerInvoice.totalPaymentAmount)
            }}</span>
          </template>
          <template v-slot:item.Action="{ item }">
            <span v-if="item.customerInvoice">
              <v-icon color="orange" @click="setReferece(item)">mdi-information</v-icon>
              <span v-if="item.customerInvoice" v-html="getPaymentRefAndDateDescription(item.customerInvoice.payments)"></span>
            </span>
          </template>
          <template v-slot:item.customerInvoice="{ item }">
            <span v-if="item.customerInvoice">
              <v-btn class="primary" small @click="showCustomerInvoice(item.customerInvoice)"> Invoice Details </v-btn>
            </span>
            <span v-else-if="!item.isSum">
              <v-icon color="green" @click="newCustomerInvoice(item)">mdi-plus-circle</v-icon>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog v-if="showCustomerInvoiceDialog" persistent v-model="showCustomerInvoiceDialog" @keydown.esc="showCustomerInvoiceDialog = false">
        <CustomerInvoiceForm
          v-if="selectedCustomerInvoice"
          :invoice="selectedCustomerInvoice"
          @close="showCustomerInvoiceDialog = false"
          @confirmed="showCustomerInvoiceConfirmed"
        ></CustomerInvoiceForm>
      </v-dialog>
      <v-dialog v-if="showSetRefrence" persistent v-model="showSetRefrence" max-width="1200" @keydown.esc="showSetRefrence = false">
        <SetPayment
          v-if="selectedCustomerInvoice"
          :selectedInvoice="selectedCustomerInvoice"
          @close="showSetRefrence = false"
          @confirmed="setPaymentConfirmed"
        ></SetPayment>
      </v-dialog>
    </v-card>
  </div>
</template>
<script lang="ts">
import {
  CustomerApi,
  CustomerInvoiceApi,
  CustomerInvoicePaymentVM,
  CustomerInvoiceVM,
  ManagerCustomerModel,
  ReportApi,
  Revenue,
  TimesheetRequestModel,
} from "shared-components/src/services/openApi";
import ListHelpers from "shared-components/src/utils/ListHelper";
import Utils from "shared-components/src/utils/Utils";
import CustomerInvoiceForm from "../customerInvoice/CustomerInvoiceForm.vue";
import SetPayment from "../customerInvoice/SetPayment.vue";
import { defineComponent } from "vue";
var reportApi = new ReportApi();
var customerApi = new CustomerApi();
var customerInvoiceApi = new CustomerInvoiceApi();

export default defineComponent({
  components: {
    CustomerInvoiceForm,
    SetPayment,
  },
  data() {
    return {
      selectedMonth: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      cuLoading: false,
      loading: false,
      customers: [] as Array<{ id: string; text: string }>,
      model: {
        FromDate: new Date() as Date,
        ToDate: new Date() as Date,
        selectedCustomer: null,
      },
      items: [] as Revenue[],
      headers: [
        { title: "No.", sortable: false, align: "start", value: "Index", width: "5%" },
        { title: "Customer", value: "customerName", align: "left", width: "12%" },
        { title: "Month", sortable: false, value: "fromDate", align: "left", width: "15%" },
        { title: "Amount AUD", value: "amount", align: "right", width: "10%" },
        { title: "Payment Amount", value: "paymentAmount", align: "right", width: "12%" },
        { title: "Payment Reference", sortable: false, value: "Action", align: "center", width: "28%" },
        { title: "Customer Invoice", sortable: false, value: "customerInvoice", align: "center", width: "18%" },
      ],
      selectedCustomerInvoice: null as CustomerInvoiceVM | null,
      showCustomerInvoiceDialog: false,
      showSetRefrence: false,
    };
  },
  async mounted() {
    this.setDefaultSelectedMonth();
    this.setFromDateAndToDate(this.model.FromDate);
    await this.fetchCustomers();
  },
  watch: {
    selectedMonth(newValue) {
      if (newValue) {
        this.setFromDateAndToDate(`${newValue.year}-${newValue.month + 1}`);
      }
    },
  },
  methods: {
    getPaymentRefAndDateDescription(payments: CustomerInvoicePaymentVM[] | undefined): string {
      let refText = "";
      if (payments && payments.length) {
        refText += `<br/><table style="border: 1px solid; width: 100%;">
      <tr>
        <th>Reference</th>
        <th>Date</th>
        <th>Amount (AUD)</th>
      </tr>`;
        payments.forEach((payment, index) => {
          refText += `<tr>`;

          refText += `<td>`;
          if (payment.paymentRefNo) {
            refText += `${payment.paymentRefNo} `;
          }
          refText += `</td>`;

          refText += `<td>`;

          if (payment.paymentDate) {
            refText += `${Utils.toVsDateFormat(new Date(payment.paymentDate))} `;
          }
          refText += `</td>`;
          refText += `<td>`;
          if (payment.audTotalPay) {
            refText += `${Utils.moneyFormat(payment.audTotalPay)} AUD `;
          }
          refText += `</td>`;
          refText += `</tr>`;
        });
        var totalAUDPayment = payments.reduce((n, { audTotalPay }) => n + (audTotalPay ?? 0), 0);
        refText += `<tr>
  	<td><strong>Total payment</strong></td>
    <td></td>
    <td><strong>${totalAUDPayment} AUD</strong></td>
  </tr>`;
        refText += `</table><br/>`;
      }
      return refText;
    },
    setPaymentConfirmed() {
      this.showSetRefrence = false;
      this.loadData();
    },

    showCustomerInvoiceConfirmed() {
      this.showCustomerInvoiceDialog = false;
      this.loadData();
    },
    async showCustomerInvoice(item: CustomerInvoiceVM) {
      this.loading = true;
      const invoiceId = item && item.id ? item.id : "";
      customerInvoiceApi
        .getCustomerInvoice(invoiceId)
        .then((result: any) => {
          const item = result.data;
          this.selectedCustomerInvoice = item;
          this.showCustomerInvoiceDialog = true;
        })
        .finally(() => {
          this.loading = false; // Set loading to false after the operation is complete
        });
    },
    async newCustomerInvoice(item: Revenue) {
      this.loading = true;
      const selectedCustomerId = item.customer?.id ? item.customer?.id : "";
      const fromDate = Utils.toVsDateFormat(item.fromDate ? item.fromDate : new Date());
      const toDate = Utils.toVsDateFormat(item.toDate ? item.toDate : new Date());

      customerInvoiceApi
        .generateCustomerInvoice(selectedCustomerId, fromDate, toDate)
        .then((result: any) => {
          const item = result.data;
          this.selectedCustomerInvoice = item;
          this.showCustomerInvoiceDialog = true;
        })
        .finally(() => {
          this.loading = false; // Set loading to false after the operation is complete
        });
    },
    async fetchCustomerInvoice(item: Revenue) {
      if (item.customerInvoice) {
        let customerInvoiceId = item && item.customerInvoice && item.customerInvoice.id ? item.customerInvoice.id : "";

        // set loading
        this.loading = true;
        try {
          this.selectedCustomerInvoice = (await customerInvoiceApi.getCustomerInvoice(customerInvoiceId)).data as CustomerInvoiceVM;
        } finally {
          this.loading = false;
        }
      } else {
        this.selectedCustomerInvoice = null;
      }
    },
    async setReferece(item: Revenue) {
      await this.fetchCustomerInvoice(item);

      if (this.selectedCustomerInvoice) {
        this.showSetRefrence = true;
      }
    },
    moneyFormat(Amount: any) {
      return `${Utils.numberToCurrency(Amount)} AUD`;
    },
    getTotalAudOfReport(items: Revenue[] | undefined) {
      if (!items) {
        return "";
      }
      let totalAud = 0;
      if (items) {
        items.forEach((item: Revenue) => {
          if (item.customerInvoice && item.customerInvoice.audTotalAmount) {
            totalAud += item.customerInvoice.audTotalAmount;
          }
        });
      }

      return this.moneyFormat(totalAud);
    },
    getTotalAudPaymentOfReport(items: Revenue[] | undefined) {
      if (!items) {
        return "";
      }
      let totalAudPayment = 0;
      if (items) {
        items.forEach((item: Revenue) => {
          if (item.customerInvoice && item.customerInvoice.totalPaymentAmount) {
            totalAudPayment += item.customerInvoice.totalPaymentAmount;
          }
        });
      }

      return this.moneyFormat(totalAudPayment);
    },
    getMonthName(item: any) {
      if (item.fromDate) {
        const date = Utils.vsDateToDatetime(item.fromDate);
        return `${Utils.getMonthName(date)} ${date.getFullYear()}`;
      } else {
        return "";
      }
    },
    customSort(items: any, index: any, isDesc: any) {
      const filteredItems = items.filter((x: any) => !x.isSum);
      ListHelpers().sort(filteredItems, index[0], isDesc[0] ? "asc" : "desc");
      this.setIndex(filteredItems);

      this.setSumRecord(filteredItems);
      return filteredItems;
    },
    fetchCustomers() {
      this.cuLoading = true;
      customerApi
        .getCustomers()
        .then((items: any) => {
          this.customers = items
            ? items.data
                .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
                .map((item: any) => {
                  return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
                })
            : [];
        })
        .finally(() => {
          this.cuLoading = false; // Set loading to false after the operation is complete
        });
    },
    setFromDateAndToDate(date: string | Date) {
      // set from date
      if (typeof date === "string") {
        this.model.FromDate = date ? Utils.vsDateToDatetime(`${date}-01`) : Utils.vsDateToDatetime(new Date().toDateString());
      } else {
        this.model.FromDate = Utils.firstDayOfMonth(date);
      }

      // set to date
      this.model.ToDate = Utils.lastOfTime(Utils.lastDayOfMonth(this.model.FromDate));
    },
    setDefaultSelectedMonth(): void {
      let d = Utils.addMonths(new Date(), -1);
      let m = d.getMonth();
      let y = d.getFullYear();
      this.selectedMonth = {
        month: m,
        year: y,
      };
    },
    async loadData() {
      this.loading = true;

      var body = {
        customerId: this.model.selectedCustomer ? this.model.selectedCustomer : "",
        startDate: Utils.toVsDateFormat(this.model.FromDate),
        endDate: Utils.toVsDateFormat(this.model.ToDate),
      } as TimesheetRequestModel;

      reportApi
        .getRevenueReport(body)
        .then((result: any) => {
          const items = result.data;
          if (!items) {
            this.items = [];
          } else {
            this.setIndex(items);
            this.setSumRecord(items);

            this.items = items;
          }
        })
        .finally(() => {
          this.loading = false; // Set loading to false after the operation is complete
        });
    },
    setIndex(items: any[]) {
      if (items) {
        items.forEach((item, index) => {
          item.Index = index + 1;
        });
      }
    },
    setSumRecord(items: Revenue[]) {
      // add sum row
      const sumRow = {
        isSum: true,
      };

      items.push(sumRow as any);
    },
    clearFilters() {
      this.model.FromDate = new Date();
      this.model.ToDate = new Date();
      this.model.selectedCustomer = null;
      this.setDefaultSelectedMonth();
    },
  },
});
</script>
