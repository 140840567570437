<template>
  <div>
    <v-card v-show="!pageLoading">
      <v-card-title>
        <v-row no-gutters>
          <span class="mt-3 mb-5">Pay Runs</span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <v-col md="4" sm="6" cols="12">
            <ComboBoxField
              v-model="selectedTeammember"
              :loading="tmLoading"
              :disabled="tmLoading"
              :items="teammemberSortList"
              :firstInactiveId="firstInactiveTeammemberId"
              item-title="FullName"
              item-value="Id"
              outlined
              label="Teammember"
              placeholder="Select Teammember"
            >
            </ComboBoxField>
          </v-col>
        </v-row>
        <div v-if="selectedTeammember">
          <v-card class="mt-3" :loading="loading || tmLoading" :disabled="loading || tmLoading">
            <v-card-title>
              <v-row>
                <v-col col-6> Past Pay Runs </v-col>
                <v-col col-6 class="text-end">
                  <v-btn color="primary" @click="showAddOrEditDialog(false)">New Pay Run</v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table-server
              :headers="payrunListHeaders"
              :items="pasPayRuns"
              item-key="id"
              class="elevation-1 custom-table-row"
              :loading="loading"
              :page="page"
              :items-per-page="numberOfPages"
              :items-length="totalItemsCount"
              @update:options="(event: any) => tableOptions = event"
            >
              <template v-slot:item.Actions="{ item }">
                <v-menu bottom right>
                  <template v-slot:activator="{ props }">
                    <v-icon small v-bind="props" title="Action"> mdi-dots-vertical</v-icon>
                  </template>
                  <v-list density="compact">
                    <v-list-item @click="showViewPayRun(item)" class="d-flex align-center">
                      <v-list-item-title>View PayRun</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showPayRunPDF(item)" class="d-flex align-center">
                      <v-list-item-title>View PayRun PDF</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="setPayFor(item)" :disabled="loadingPayrun ? true : false">
                      <v-list-item-title> Enter Payment </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!item.AdminApproved || !item.Payments?.length" @click="edit(item)" :disabled="loadingPayrun ? true : false">
                      <v-list-item-title> Edit </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.PayFrom="{ item }">
                {{ formatDate(item.FromDate) }}
              </template>
              <template v-slot:item.PayTo="{ item }">
                {{ formatDate(item.ToDate) }}
              </template>
              <template v-slot:item.PaymentMethodType="{ item }">
                <v-btn v-if="item.PaymentMethodType" color="primary" @click="showPaymentMethodDetailPopup(item)">{{
                  getPaymentMethodNameByNumber(item.PaymentMethodType)
                }}</v-btn>
              </template>
              <template v-slot:item.PayAmount="{ item }">
                {{ formatNumber(item.LCTotalPay) }} {{ item.ExchangeCurrency ? item.ExchangeCurrency : "TMN" }}
              </template>
              <template v-slot:item.PaymentReference="{ item }">
                <span v-html="getPaymentRefAndDate(item)"></span>
              </template>
            </v-data-table-server>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <pay-run-form
      :viewPayRun="viewPayRun"
      :key="`payrun-form-v${keyVersion}`"
      :dateArray="[model.FromDate, model.ToDate]"
      :selectedPayRun="selectedPayRun"
      :isEditMode="isEditMode"
      :showAddOrEdit="showAddOrEdit"
      :selectedTeammember="selectedTeammember"
      :paymentMethodDetail="htmlText"
      :pasPayRuns="pasPayRuns"
      @setAddEditMode="setAddEditMode"
    />

    <loading-component v-if="pageLoading"></loading-component>
    <v-dialog v-if="showPaymentMethodDetail" v-model="showPaymentMethodDetail" persistent @keydown.esc="showPaymentMethodDetail = false">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showPaymentMethodDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Payment method</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <div v-html="htmlText"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showSetRefrence" max-width="1200" @keydown.esc="showSetRefrence = false">
      <set-refrence
        v-if="showSetRefrence"
        :selectedPayRun="selectedPayRun"
        @close="showSetRefrence = false"
        @confirmed="setPaymentConfirmed"
      ></set-refrence>
    </v-dialog>

    <v-dialog v-if="selectedPayRunPDF" :key="`PDF-Modal-${pdfVersion}`" max-width="800" v-model="showPdf" persistent @keydown.esc="closePdfDialog">
      <PayRunDetail
        v-if="selectedPayRunPDF"
        :key="`PDF-${pdfVersion}`"
        :model="selectedPayRunPDF"
        :isForAdmin="true"
        :payrunAuditTrail="payrunAuditTrail"
        @confirmed="payRunConfirmed"
        @close="closePdfDialog"
      ></PayRunDetail>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { TeamMemberSummarySort, TeamMemberSort } from "shared-components/src/models/Teammember";
import PayRunService from "shared-components/src/services/PayRunService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import PaymentMethodService from "@/services/PaymentMethodService";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import PaymentMethods from "shared-components/src/definitions/PaymentMethods";
import PayRun from "shared-components/src/models/PayRun";
import Utils from "shared-components/src/utils/Utils";
import PayRunForm from "./PayRunFormV2.vue";
import SetRefrence from "./SetRefrence.vue";
import AppHelper from "shared-components/src/utils/AppHelper";
import { CryptoCurrency } from "shared-components/src/models/CryptoCurrency";
import { BankAccount } from "shared-components/src/models/BankAccount";
import { WiseAccount } from "shared-components/src/models/WiseAccount";
import { Paypal } from "shared-components/src/models/Paypal";
import store from "@/store";
import UrlHelper from "shared-components/src/utils/UrlHelper";
import PayRunDetail from "shared-components/src/components/PayRan/PayRunDetail.vue";
import { PayRunAuditTrail } from "shared-components/src/models/AuditTrail";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import { AuditTrailEvents } from "shared-components/src/definitions/constants";
import { PaginationRequestModel, PayRunApi } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    LoadingComponent,
    PayRunForm,
    SetRefrence,
    PayRunDetail,
  },
  data() {
    return {
      viewPayRun: false,
      showPdf: false,
      selectedPayRunPDF: null as PayRun | null,
      pdfVersion: 0,
      payrunAuditTrail: {} as PayRunAuditTrail,
      keyVersion: 0,
      showPaymentMethodDetail: false,
      selectedPayRun: null as PayRun | null,
      firstInactiveTeammemberId: "",
      showAddOrEdit: false,
      isEditMode: false,
      showSetRefrence: false,
      pageLoading: true,
      teammemberSortList: [] as TeamMemberSummarySort[] | TeamMemberSort[],
      selectedTeammember: null as TeamMemberSummarySort | TeamMemberSort | null,
      crypto: null as CryptoCurrency | null,
      bank: null as BankAccount | null,
      wise: null as WiseAccount | null,
      paypal: null as Paypal | null,
      tmLoading: true,
      pasPayRuns: [] as PayRun[],
      htmlText: "",
      payrunListHeaders: [
        { title: "Pay From", value: "PayFrom", sortable: false },
        { title: "Pay To", value: "PayTo", sortable: false },
        { title: "Payment Method", value: "PaymentMethodType", sortable: false, align: "center" },
        { title: "Amount", value: "PayAmount", sortable: false, align: "center" },
        { title: "Payment Reference", value: "PaymentReference", sortable: false, align: "center" },
        { title: "Action", value: "Actions", sortable: false, align: "center" },
      ],
      model: {
        FromDate: new Date() as Date,
        ToDate: new Date() as Date,
      },
      loading: false,
      tableOptions: {} as any,
      totalItemsCount: 0 as number | undefined,
      sortOrder: "desc",
      sortProp: "Amount",
      numberOfPages: 10,
      page: 1,
    };
  },
  mounted() {
    this.initTeamMemberSortList();
    this.checkAndLoadUrlItems();
  },
  methods: {
    GetFormatTwoDecimal(number: number) {
      return Utils.moneyFormat(number, 2);
    },
    showViewPayRun(item: PayRun) {
      this.viewPayRun = true;
      this.htmlText = "";
      this.selectedPayRun = { ...item };
      this.createHtmlPaymentDetail(item);
      this.showAddOrEditDialog(true);
    },
    async checkAndLoadUrlItems() {
      const urlItems = UrlHelper().notification().getUrlHashItems();
      if (urlItems.id && urlItems.action == "view") {
        // view payrun
        var payrun = await PayRunService.getById(urlItems.id);
        if (payrun) {
          this.showPayRunPDF(payrun);
        }
      }
    },
    async showPayRunPDF(payRun: PayRun) {
      this.pdfVersion++;
      this.selectedPayRunPDF = payRun;

      if (this.selectedPayRunPDF) {
        // get audit trails of payrun
        const auditTrails = await AuditTrailService.getAuditTrailListByTypeAdmin(payRun.id, payRun.TeamMemberId);
        if (auditTrails) {
          var tmApproved = auditTrails.find((item: any) => item.Event === AuditTrailEvents.TMApprove);
          var adminApproved = auditTrails.find((item: any) => item.Event === AuditTrailEvents.AdminApprove);
          var paymentRefAdded = auditTrails.find((item: any) => item.Event === AuditTrailEvents.PaymentRefAdd);
          var payrunAuditModel = {} as PayRunAuditTrail;
          if (tmApproved && tmApproved.DateTime) {
            payrunAuditModel.TMApprovedDate = Utils.toVsDateFormat(tmApproved.DateTime);
          }
          if (adminApproved && adminApproved.DateTime) {
            payrunAuditModel.AdminApprovedDate = Utils.toVsDateFormat(adminApproved.DateTime);
          }
          if (paymentRefAdded && paymentRefAdded.DateTime) {
            payrunAuditModel.PaymentRefAddedDate = Utils.toVsDateFormat(paymentRefAdded.DateTime);
          }
          this.payrunAuditTrail = payrunAuditModel;
        }
        this.showPdf = true;
      }
    },
    closePdfDialog() {
      this.showPdf = false;
      this.pdfVersion++;
      if (this.selectedTeammember) {
        this.loadPayrunsForTeammember();
      }
    },
    payRunConfirmed() {
      this.closePdfDialog();
    },
    createHtmlPaymentDetail(item: any) {
      switch (item.PaymentMethodType) {
        case PaymentMethods.CryptoCurrency:
          if (item.CryptoCurrencyId) {
            this.htmlText = PayRunService.createCryptoHtml(item.CryptoCurrency);
          }
          break;
        case PaymentMethods.BankAccount:
          if (item.BankAccountId) {
            this.htmlText = PayRunService.createBankHtml(item.BankAccount);
          }
          break;
        case PaymentMethods.WiseAccount:
          if (item.WiseAccountId) {
            this.htmlText = PayRunService.createWiseHtml(item.WiseAccount);
          }
          break;
        case PaymentMethods.Paypal:
          if (item.PaypalId) {
            this.htmlText = PayRunService.createPaypalHtml(item.Paypal);
          }
          break;
        default:
          break;
      }
    },
    getPaymentMethodNameByNumber(number: any) {
      return PayRunService.getPaymentMethodNameByNumber(number);
    },
    showPaymentMethodDetailPopup(item: PayRun) {
      this.createHtmlPaymentDetail(item);
      this.showPaymentMethodDetail = true;
    },
    formatDate(dt: Date | string): string {
      return Utils.formatDate(dt);
    },
    formatNumber(value: number) {
      return Utils.moneyFormat(value, 2);
    },
    getPaymentRefAndDate(item: any) {
      return PayRunService.getPaymentRefAndDateDescription(item.Payments, item.ExchangeCurrency);
    },
    initTeamMemberSortList() {
      if (AppHelper.IsAzureEnv()) {
        TeamMemberService.getSortedSummaryList()
          .then((res: any) => {
            this.findFirstInactiveTeammember(res);
            this.teammemberSortList = res;
          })
          .finally(() => {
            this.tmLoading = false;
            this.pageLoading = false;
          });
      } else {
        TeamMemberService.getSortedList()
          .then((res: any) => {
            this.findFirstInactiveTeammember(res);
            this.teammemberSortList = res;
          })
          .finally(() => {
            this.tmLoading = false;
            this.pageLoading = false;
          });
      }
    },
    findFirstInactiveTeammember(teammemberSortedList: any) {
      var inactiveTeammember = teammemberSortedList.find((c: any) => c.isFirstInactive);
      if (inactiveTeammember) {
        this.firstInactiveTeammemberId = inactiveTeammember.Id;
      }
    },
    setAddEditMode(isEditMode: any, showAddOrEdit: any, loadData: any) {
      this.viewPayRun = false;
      this.isEditMode = isEditMode;
      this.showAddOrEdit = showAddOrEdit;
      this.selectedPayRun = null;
      if (loadData) {
        this.loadingTeammemerPayRuns();
      }
    },
    closeAddOrEditPayrunPopup() {
      this.showAddOrEdit = false;
      this.isEditMode = false;
      this.loading = false;
    },
    showAddOrEditDialog(isEdit: any) {
      if (!isEdit) {
        // set last month as from date
        var today = new Date();
        var lastMonth = new Date(today.setMonth(today.getMonth() - 1));
        this.selectedPayRun = {
          PaymentMethodType: this.selectedTeammember?.PaymentMethodType,
          CryptoCurrencyId: this.crypto?.Id,
          BankAccountId: this.bank?.Id,
          WiseAccountId: this.wise?.Id,
          PaypalId: this.paypal?.Id,
        } as PayRun;
        this.setFromDateAndToDate(lastMonth);

        this.keyVersion++;
      }
      this.isEditMode = isEdit;
      this.showAddOrEdit = true;
    },
    async edit(item: PayRun) {
      this.htmlText = "";
      this.selectedPayRun = { ...item };
      this.createHtmlPaymentDetail(item);
      this.showAddOrEditDialog(true);
    },
    setFromDateAndToDate(date: string | Date) {
      // set from date
      if (typeof date === "string") {
        this.model.FromDate = date ? Utils.vsDateToDatetime(`${date}-01`) : Utils.vsDateToDatetime(new Date().toDateString());
      } else {
        this.model.FromDate = Utils.firstDayOfMonth(date);
      }

      // set to date
      this.model.ToDate = Utils.lastOfTime(Utils.lastDayOfMonth(this.model.FromDate));
    },
    async loadingTeammemerPayRuns() {
      try {
        if (this.selectedTeammember) {
          this.loading = true;
          const { page, itemsPerPage } = this.tableOptions;
          const model = {
            PageNumber: page,
            PageSize: itemsPerPage,
            SortOrder: this.sortOrder,
            SortProp: this.sortProp,
          } as PaginationRequestModel;
          const result = (await new PayRunApi().getAdminPayRunList(this.selectedTeammember.Id, model)).data;
          this.pasPayRuns =
            result.Items?.map((doc: any) => {
              return PayRunService.preparePayRun(doc);
            }) ?? [];
          this.totalItemsCount = result.Total;
        } else {
          this.pasPayRuns = [];
        }
      } finally {
        this.loading = false;
      }
    },
    setPayFor(item: PayRun): void {
      this.selectedPayRun = { ...item };
      this.showSetRefrence = true;
    },
    setPaymentConfirmed(item: PayRun): void {
      this.showSetRefrence = false;
      this.loadingTeammemerPayRuns();
    },
    async fecthCryptoCurrency() {
      var cryptoResult = await PaymentMethodService.getTeammemberCryptoCurrency(this.selectedTeammember?.Id);
      if (cryptoResult) {
        this.crypto = cryptoResult;
        this.htmlText = PayRunService.createCryptoHtml(this.crypto);
      }
    },
    async fecthBankAccount() {
      var bankResult = await PaymentMethodService.getTeammemberBankAccount(this.selectedTeammember?.Id);
      if (bankResult) {
        this.bank = bankResult;
        this.htmlText = PayRunService.createBankHtml(this.bank);
      }
    },
    async fecthWiseAccount() {
      var wiseResult = await PaymentMethodService.getTeammemberWiseAccount(this.selectedTeammember?.Id);
      if (wiseResult) {
        this.wise = wiseResult;
        this.htmlText = PayRunService.createWiseHtml(this.wise);
      }
    },
    async fecthPaypal() {
      var paypalResult = await PaymentMethodService.getTeammemberPaypal(this.selectedTeammember?.Id);
      if (paypalResult) {
        this.paypal = paypalResult;
        this.htmlText = PayRunService.createPaypalHtml(this.paypal);
      }
    },
    loadPaymentMethod() {
      switch (this.selectedTeammember?.PaymentMethodType) {
        case PaymentMethods.CryptoCurrency:
          this.fecthCryptoCurrency();
          break;
        case PaymentMethods.BankAccount:
          this.fecthBankAccount();

          break;
        case PaymentMethods.WiseAccount:
          this.fecthWiseAccount();
          break;
        case PaymentMethods.Paypal:
          this.fecthPaypal();
          break;
        default:
          break;
      }
    },
    loadPayrunsForTeammember() {
      this.htmlText = "";
      this.loadingTeammemerPayRuns();
      this.loadPaymentMethod();
    },
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0];
        }
        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "Title";
        }
      }
      this.loadingTeammemerPayRuns();
    },
    selectedTeammember(newVal, oldValue) {
      if (oldValue != null) {
        this.loadPayrunsForTeammember();
      }
    },
  },
  computed: {
    selectedDate: {
      get(): string {
        return Utils.toVsDateFormat(this.model.FromDate);
      },
      set(date: string): void {
        this.setFromDateAndToDate(date);
      },
    },
    selectedDateText: {
      get(): string {
        return Utils.getMonthName(this.model.ToDate);
      },
      set(date: string): void {},
    },
  },
});
</script>
<style lang="scss" scoped>
.main-tr {
  text-align: center;
}
table.tbl {
  border: 1px solid silver;
  background: #888;
  border: 1px soliver silver;
  width: 100%;
  border-radius: 5px;
  thead {
    tr {
      th {
        padding: 5px;
        background: #fff;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        td {
          border-radius: 5px;
          cursor: pointer;
          background: #eee;
        }
      }
      td {
        padding: 5px;
        background: #fff;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}
</style>
