<template>
  <v-card title="Skills" flat>
    <template v-slot:text>
      <v-text-field v-model="searchQuery" label="Search" placeholder="Search" prepend-inner-icon="mdi-magnify"
        variant="outlined" hide-details single-line clearable></v-text-field>
    </template>

    <v-data-table-virtual :disable=tableLoading :loading=tableLoading :headers="headers" :items="skills"
      :search="searchQuery" multi-sort>
      <template v-slot:item.actions="{ item }">
        <div class="text-end">
          <v-btn class="text-uppercase" size="small" :color="item.IsRequested ? 'red' : 'green'"
            :text="item.IsRequested ? 'Requested' : 'Approved'" :disabled="item.IsRequested ? false : true"
            v-tooltip="item.IsRequested ? 'Approve requested Skill' : 'Already approved'"
            @click="approveSkilByAdmin(item)"></v-btn>
        </div>
      </template>
    </v-data-table-virtual>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CoreSkillApi, CoreSkillFullModel } from "shared-components/src/services/openApi/api";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      searchQuery: "",
      searchTimeout: null,
      tableLoading: false,
      skills: [] as CoreSkillFullModel[],
      displayedItems: [],
      itemsPerPage: 10,
      sortKey: "",
      sortOrder: "asc",
      headers: [
        { align: "start", key: "Text", title: "Skill Name", },
        { key: "CreatedAt", title: "Created At", },
        { key: "UpdatedAt", title: "Updated At", },
        { key: "TeammemberId", title: "TeammemberId", },
        { key: "IsRequested", title: "Is Requested", },
        { key: "actions", title: "Actions", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.fetchSkills();
  },
  methods: {
    async fetchSkills() {
      this.tableLoading = true;
      try {
        var rsp = await new CoreSkillApi().getCoreSkillsWithDetails();
        this.skills = rsp.data;
      } finally {
        this.tableLoading = false;
      }
    },
    async approveSkilByAdmin(skill: CoreSkillFullModel) {
      this.tableLoading = true;
      try {
        var rsp = await new CoreSkillApi().approveRequestedSkillByAdmin(skill.id ?? "");
        skill.TeammemberId = undefined;
        skill.IsRequested = false;
        store.dispatch("showSuccessMessage", "Approved Successfully!");
      } finally {
        this.tableLoading = false;
      }
    },
    // loadMore() {
    //   // Load the next set of skills
    //   const nextItems = this.sortedItems.slice(
    //     this.displayedItems.length,
    //     this.displayedItems.length + this.itemsPerPage
    //   );
    //   // this.displayedItems = this.displayedItems.concat(nextItems);
    // },
  },
});
</script>
