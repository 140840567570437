<template>
  <div>
    <v-card :loading="loading" :disabled="loading">
      <div v-if="showAddOrEdit">
        <v-card-text>
          <div v-if="selectedTeammember">
            <v-dialog
              v-model="showAddOrEdit"
              class="main-dialog"
              :class="`${fullScreenMode ? 'fullscreen' : ''}`"
              :fullscreen="fullScreenMode"
              persistent
              transition="dialog-bottom-transition"
            >
              <v-card :class="{ 'overflow-visible': !fullScreenMode && !isEditMode }" :loading="loadingInfo" :disabled="loadingInfo">
                <v-toolbar dark color="primary">
                  <v-btn icon dark @click="closeAddOrEditPayrunPopup()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    >{{ isEditMode ? "Edit Pay Run" : "New Pay Run" }} For
                    {{ `${selectedTeammember.FirstName} ${selectedTeammember.LastName}` }}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn v-if="fullScreenMode && !viewPayRun" @click="showPdfDialog()" class="secondary"
                      ><v-icon>mdi-file-pdf</v-icon> Show PDF</v-btn
                    >
                    <v-btn dark variant="text" :disabled="!valid" class="primary" @click="save" v-if="fullScreenMode && !viewPayRun"> Save </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <br />
                <v-row class="m-0" v-if="!fullScreenMode && !isEditMode">
                  <v-col md="8" sm="8" cols="12">
                    <VueDatePicker v-model="selectedMonth" month-picker auto-apply />
                  </v-col>
                  <v-col md="4" sm="4" cols="12">
                    <v-btn class="secondary" x-large @click="processRun"> <v-icon>mdi-sync</v-icon> Process </v-btn>
                  </v-col>
                </v-row>

                <v-card-text>
                  <template v-if="billableItems && billableItems.length && !loadingInfo">
                    <v-form ref="frmPayRun" v-model="valid" lazy-validation>
                      <v-row v-for="(billableItem, index) in billableItems" :key="`bi_${index}`" class="m-0">
                        <h3 class="pl-3 mt-2">{{ billableItem.name }}'s Commitments</h3>
                        <br />
                        <v-col md="12">
                          <v-data-table
                            hide-default-footer
                            hide-default-header
                            :item-class="SumRowBackground"
                            :headers="getCommitmentHeadersV2()"
                            :items-per-page="-1"
                            :items="getBillableCommitmentItemsV2(billableItem, true)"
                            item-key="CommitmentName"
                            class="elevation-1 custom-table-row"
                          >
                            <template #thead>
                              <thead class="v-data-table-header">
                                <tr>
                                  <th v-for="head in getCommitmentHeadersV2()" :key="head.title" class="text-center break-line">
                                    <span>{{ head.title }}</span>
                                  </th>
                                </tr>
                              </thead>
                            </template>
                            <template v-slot:item="{ item }">
                              <tr :class="['main-tr', item.IsSum ? 'sum-background' : '']">
                                <td>{{ item.Teammembers }}</td>

                                <td>{{ item.CommitmentName }}</td>
                                <td v-for="(week, index) of item.Weeks" :key="index">
                                  <WeekDetail v-if="item.IsSum || (week.Days && week.Days.length > 0)" :Week="week"></WeekDetail>
                                </td>
                                <td>{{ item.TotalHours }}</td>
                                <td>
                                  <span v-if="!item.IsSum">{{ item.SeniorityLevel }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>{{ GetFormatTwoDecimal(item.TotalPay) }} AUD</td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>

                      <v-row class="m-0">
                        <v-col cols="6" md="6" sm="12">
                          <v-row>
                            <v-col md="8" sm="7" cols="12">
                              <ComboBoxField
                                v-model="exchangeCurrency"
                                @change="onExchangeCurrencyChange"
                                :items="currencies"
                                item-title="name"
                                item-value="id"
                                dense
                                outlined
                                label="Exchange Currency"
                                :readonly="viewPayRun"
                              >
                              </ComboBoxField>
                            </v-col>
                            <v-col md="3" sm="4" cols="12">
                              <CurrencyTextFieldV2
                                dense
                                outlined
                                label="Exchange Rate"
                                v-model="model.ExchangeRate"
                                :readonly="isReadOnly || viewPayRun"
                                :hideGroupingSeparatorOnFocus="false"
                                :precision="8"
                                :min="0"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="m-0">
                        <v-col cols="6" md="6" sm="12">
                          <v-row v-for="(ad, idx) of audAdjustments" :key="`aud_adjsmnt_${idx}`">
                            <v-col md="5" sm="5" cols="12">
                              <CurrencyTextFieldV2
                                :rules="[rules.required]"
                                dense
                                outlined
                                label="Adjustments AUD"
                                v-model="ad.adjustment"
                                :readonly="isReadOnly || viewPayRun"
                                :hideGroupingSeparatorOnFocus="false"
                                :precision="2"
                              />
                            </v-col>
                            <v-col md="5" sm="5" cols="12">
                              <TextField
                                dense
                                outlined
                                label="Adjustment Description"
                                :value="ad.description"
                                @update:modelValue="setAdjustmentDesc($event, ad.id)"
                                :readonly="isReadOnly || viewPayRun"
                              ></TextField>
                            </v-col>
                            <v-col md="1" sm="1" cols="12" class="mt-11">
                              <v-btn
                                v-if="idx === audAdjustments.length - 1 && !viewPayRun"
                                fab
                                x-small
                                rounded
                                color="primary"
                                @click="addAudAjustment"
                                ><v-icon>mdi-plus</v-icon></v-btn
                              >
                              <v-btn
                                v-if="idx !== audAdjustments.length - 1 && !viewPayRun"
                                fab
                                x-small
                                rounded
                                color="error"
                                dark
                                @click="removeAdjustment(ad.id)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <hr />
                      <v-row class="m-0">
                        <v-col cols="6" md="6" sm="12">
                          <v-row v-for="(li, idx) of loanItems" :key="`loan_item_${idx}`">
                            <v-col md="5" sm="5" cols="12">
                              <CurrencyTextField
                                :rules="[rules.required, rules.currency]"
                                dense
                                outlined
                                label="Loan Installment AUD"
                                v-model="li.Amount"
                                :readonly="isReadOnly || viewPayRun"
                              />
                            </v-col>
                            <v-col md="6" sm="6" cols="12">
                              <TextField
                                dense
                                outlined
                                label="Loan Installment Description"
                                :value="li.Description"
                                @update:modelValue="setLoanItemDesc($event, li.LoanId)"
                                :readonly="isReadOnly || viewPayRun"
                                :disabled="li.IsDeleted"
                              ></TextField>
                            </v-col>
                            <v-col md="1" sm="1" cols="12" class="mt-11 text-center">
                              <v-btn v-if="li.IsDeleted && !viewPayRun" fab x-small rounded color="primary" @click="includeLoanItem(li.LoanId)"
                                ><v-icon>mdi-plus</v-icon></v-btn
                              >
                              <v-btn v-if="!li.IsDeleted && !viewPayRun" fab x-small rounded color="error" dark @click="removeLoanItem(li.LoanId)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col md="11" sm="11" cols="12" style="margin-top: -45px; font-size: 12px; font-style: italic">
                              <p style="margin-bottom: 0px">
                                Total Amount: {{ formatNumber(li.LoanTotalAmount) }} AUD,Total Paid Amount:
                                {{ formatNumber(li.LoanTotalPaidAmount) }} AUD, Total Un-Paid Amount:
                                {{ formatNumber(li.LoanTotalUnPaidAmount) }} AUD, Last Paid Amount:
                                {{ formatNumber(li.LoanLastPaidInstallmentAmount) }} AUD
                              </p>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="m-0">
                        <v-col cols="6" md="6" sm="12"> </v-col>
                        <v-col cols="6" md="6" sm="12">
                          <v-row style="padding-top: 18px">
                            <v-col md="3" sm="4" cols="12">
                              <!-- <v-checkbox dense class="m-0 p-0" v-model="model.AdminApproved" label="Admin approved" hide-details></v-checkbox> -->
                            </v-col>
                            <v-col md="4" sm="4" cols="12">
                              <h3>Total pay: {{ GetFormatTwoDecimal(totalPayment) }} AUD</h3>
                            </v-col>
                            <v-col md="5" sm="4" cols="12">
                              <h3>Total pay in {{ exchangeCurrencyId }} : {{ GetFormatTwoDecimal(totalPaymentLC) }}</h3>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <hr v-if="paymentMethodDetail" />
                      <div v-html="paymentMethodDetail"></div>
                    </v-form>
                    <loading-component v-if="pageLoading"></loading-component>
                  </template>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <v-dialog
      max-width="800"
      v-if="showPdf && payRun != null"
      v-model="showPdf"
      :key="`PDF-Modal-${pdfVersion}`"
      persistent
      @keydown.esc="closePdfDialog"
    >
      <PayRunDetail
        :model="payRun"
        :isForAdmin="true"
        @confirmed="payRunConfirmed"
        :key="`PDF-${pdfVersion}`"
        :payrunAuditTrail="payrunAuditTrail"
        @close="closePdfDialog"
      ></PayRunDetail>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { TeamMemberSort, TeamMemberSummarySort } from "shared-components/src/models/Teammember";
import Timesheet from "shared-components/src/models/Timesheet";
import Commitment, { BillableItemCode } from "shared-components/src/models/Commitment";
import PayRun, {
  Adjustment,
  PayRunItem,
  PayrunPayment,
  TeammemberOfPayRun,
  WeekDay,
  WeekOfPayRunItem,
  WeekRange,
} from "shared-components/src/models/PayRun";
import Utils from "shared-components/src/utils/Utils";
import PayRunDetail from "shared-components/src/components/PayRan/PayRunDetail.vue";
import WeekDetail from "./WeekDetail.vue";
import { LoanItem, UnpaidLoanItemRequestModel } from "shared-components/src/models/Loan";
import AuditTrail, { RecordType, PayRunAuditTrail } from "shared-components/src/models/AuditTrail";
import { AuditTrailRecordTypes, AuditTrailEvents, Currencies } from "shared-components/src/definitions/constants";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import TimesheetService from "shared-components/src/services/TimesheetService";
import LoanService from "shared-components/src/services/LoanService";
import PayRunService from "shared-components/src/services/PayRunService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import rules from "shared-components/src/utils/validations";
import store from "@/store";
import { CommitmentModel, PayrunBillableItem, CommitmentBillableItemModel } from "shared-components/src/services/openApi";

export default defineComponent({
  props: {
    paymentMethodDetail: {
      type: String,
      default: "",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    showAddOrEdit: {
      type: Boolean,
      default: false,
    },
    selectedTeammember: {
      type: Object as () => TeamMemberSummarySort | TeamMemberSort | null,
    },
    selectedPayRun: {
      type: Object as () => PayRun | null,
      default: null,
    },
    dateArray: {
      type: Array as () => Date[],
      default: [],
    },
    pasPayRuns: {
      type: Array as () => PayRun[],
    },
    viewPayRun: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PayRunDetail,
    WeekDetail,
    LoadingComponent,
  },
  data() {
    return {
      selectedMonth: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      payMonthPickerMenu: false,
      pageLoading: false,
      currencies: Currencies,
      exchangeCurrency: Currencies.find((x: any) => x.isDefault),
      mainCurrency: Currencies.find((x: any) => x.isMain),
      lastAdminApprove: false,
      dayNames: ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
      loadingPayrun: false,
      payRun: null as PayRun | null,
      showPdf: false,
      pdfVersion: 0,
      fullScreenMode: false,
      payrunAuditTrail: {} as PayRunAuditTrail,
      valid: true,
      timesheets: [] as Timesheet[],
      loanItems: [] as LoanItem[],
      linemanagerTimesheets: [] as Timesheet[],
      tmLoading: true,
      loadingInfo: false,
      rules,
      model: {
        id: "",
        PaymentMethodType: 0,
        FromDate: this.dateArray[0],
        ToDate: this.dateArray[1],
        CryptoCurrencyId: null as string | null,
        BankAccountId: null as string | null,
        WiseAccountId: null as string | null,
        PaypalId: null as string | null,
        Items: [] as PayRunItem[],
        Adjustments: [] as Adjustment[],
        ExchangeRate: 1,
        AdminApproved: true,
        TMApproved: false,
        Payments: [] as PayrunPayment[] | null,
      },
      linemanagerModel: {
        id: "",
        Items: [] as PayRunItem[],
        Adjustments: [] as Adjustment[],
        ExchangeRate: 1,
        AdminApproved: true,
        TMApproved: false,
      },
      loading: false,
      teammemberCommitments: [] as Commitment[],
      linemanagerCommitments: [] as Commitment[],
      editModelHasPayment: false,
      showSetRefrence: false,
      billableItems: [] as PayrunBillableItem[],
    };
  },
  async mounted() {
    this.setDefaultSelectedMonth();
    this.setDefaultPaymentMethod();
    this.setDefaultAdjustments();
  },
  methods: {
    GetFormatTwoDecimal(number: number) {
      return Utils.moneyFormat(number, 2);
    },
    setDefaultSelectedMonth(): void {
      let d = Utils.addMonths(new Date(), -1);
      if (this.dateArray && this.dateArray.length === 2) {
        d = this.dateArray[0];
      }
      let m = d.getMonth();
      let y = d.getFullYear();
      this.selectedMonth = {
        month: m,
        year: y,
      };
    },
    snackbarMessage(messageObject: any) {
      if (messageObject.type == "success") {
        store.dispatch("showSuccessMessage", messageObject.message);
      } else {
        store.dispatch("showErrorMessage", messageObject.message);
      }
    },
    setDefaultPaymentMethod() {
      if (this.selectedPayRun) {
        this.model.PaymentMethodType = this.selectedPayRun.PaymentMethodType;
        this.model.CryptoCurrencyId = this.selectedPayRun.CryptoCurrencyId;
        this.model.WiseAccountId = this.selectedPayRun.WiseAccountId;
        this.model.BankAccountId = this.selectedPayRun.BankAccountId;
        this.model.PaypalId = this.selectedPayRun.PaypalId;
      }
    },
    onExchangeCurrencyChange() {
      this.setDefaultAdjustments();
    },
    setDefaultAdjustments() {
      const adjustmentsArray = [] as any[];

      // add exchange currency adjustment item
      adjustmentsArray.push(this.getDefaultAdjustment(this.exchangeCurrencyId));
      if (!this.isExchangeCurrencyIdIsSameAsMainCurrency) {
        // add main default adjustment item
        adjustmentsArray.push(this.getDefaultAdjustment(this.mainCurrencyId));
      }
      this.model.Adjustments = adjustmentsArray;
    },
    getDefaultAdjustment(type: any) {
      return { id: (Math.random() * 100000000).toFixed().toString(), type: type, adjustment: 0, description: "" };
    },
    closeAddOrEditPayrunPopup(loadData = false) {
      this.fullScreenMode = false;
      this.loadingInfo = false;
      this.resetModel();
      this.$emit("setAddEditMode", false, false, loadData);
    },
    showAddOrEditDialog(isEdit: any) {
      if (!isEdit) this.resetModel();
      this.$emit("setAddEditMode", isEdit, true, false);
    },
    decimalCount(num: any) {
      const numStr = String(num);
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      return 0;
    },
    removeDecimals(value: any) {
      if (value !== 0 && this.decimalCount(value) > 3) {
        return Number(value);
      }
      return value;
    },
    setAdjustmentDesc($event: any, id: string): void {
      const ad = this.model.Adjustments.find((adItem) => adItem.id === id);
      if (ad) {
        ad.description = $event;
      }
    },
    removeAdjustment(id: string): void {
      const idx = this.model.Adjustments.findIndex((ad) => ad.id === id);
      this.model.Adjustments.splice(idx, 1);
    },
    setLoanItemAmount($event: any, loanId: string): void {
      const li = this.loanItems.find((liItem) => liItem.LoanId === loanId);
      if (li) {
        li.Amount = Utils.currencyToNumber($event);
      }
    },
    setLoanItemDesc($event: any, loanId: string): void {
      const li = this.loanItems.find((liItem) => liItem.LoanId === loanId);
      if (li) {
        li.Description = $event;
      }
    },
    removeLoanItem(id: string): void {
      const loanItem = this.loanItems.find((liItem) => liItem.LoanId === id);
      if (loanItem) {
        loanItem.IsDeleted = true;
      }
    },
    includeLoanItem(id: string): void {
      const loanItem = this.loanItems.find((liItem) => liItem.LoanId === id);
      if (loanItem) {
        loanItem.IsDeleted = false;
      }
    },
    addAudAjustment(): void {
      this.model.Adjustments.push({
        id: (Math.random() * 100000000).toFixed().toString(),
        type: this.mainCurrencyId,
        adjustment: 0,
        description: "",
      });
    },
    addSecondaryAjustment(): void {
      this.model.Adjustments.push({
        id: (Math.random() * 100000000).toFixed().toString(),
        type: this.exchangeCurrencyId,
        adjustment: 0,
        description: "",
      });
    },
    payRunConfirmed(): void {
      this.model.AdminApproved = true;
      this.snackbarMessage({
        message: "PayRun confirmed!",
        type: "success",
      });
    },
    async showPdfDialog() {
      if (this.selectedTeammember) {
        // get audit trails of payrun
        const auditTrails = await AuditTrailService.getAuditTrailListByTypeAdmin(this.model.id, this.selectedTeammember.Id);

        if (auditTrails) {
          var tmApproved = auditTrails.find((item: any) => item.Event === AuditTrailEvents.TMApprove);
          var adminApproved = auditTrails.find((item: any) => item.Event === AuditTrailEvents.AdminApprove);
          var paymentRefAdded = auditTrails.find((item: any) => item.Event === AuditTrailEvents.PaymentRefAdd);
          var payrunAuditModel = {} as PayRunAuditTrail;
          if (tmApproved && tmApproved.DateTime) {
            payrunAuditModel.TMApprovedDate = Utils.toVsDateFormat(tmApproved.DateTime);
          }
          if (adminApproved && adminApproved.DateTime) {
            payrunAuditModel.AdminApprovedDate = Utils.toVsDateFormat(adminApproved.DateTime);
          }
          if (paymentRefAdded && paymentRefAdded.DateTime) {
            payrunAuditModel.PaymentRefAddedDate = Utils.toVsDateFormat(paymentRefAdded.DateTime);
          }
          this.payrunAuditTrail = payrunAuditModel;
        }

        this.payRun = this.preparePayRunToSave();
        this.pdfVersion++;
        this.showPdf = true;
      }
    },
    closePdfDialog() {
      this.showPdf = false;
      this.pdfVersion++;
    },
    resetModel(): void {
      this.model.TMApproved = false;
      this.model.Payments = [];
      this.model.Items = [];
      this.model.ToDate = new Date();
      this.model.ExchangeRate = 1;
      this.model.AdminApproved = true;
      this.model.PaymentMethodType = 0;
      this.linemanagerModel.Items = [];
      this.setDefaultAdjustments();
      // set default date
      this.setFromDateAndToDate(this.dateArray[0]);
    },
    setExchangeRate(item: PayRun | null) {
      this.exchangeCurrency = Currencies.find((x: any) => x.isDefault);
      if (item && item.ExchangeCurrency) {
        this.exchangeCurrency = Currencies.find((x: any) => x.id == item.ExchangeCurrency);
      }
    },
    async initPayRun(item: PayRun | null) {
      this.setExchangeRate(item);
      if (!this.isEditMode || !this.selectedPayRun || !item) {
        return;
      }
      this.fullScreenMode = true;
      this.loadingPayrun = true;
      this.model.id = item.id;
      this.model.FromDate = item.FromDate;
      this.model.ToDate = item.ToDate;
      this.editModelHasPayment = item.PaymentDate && item.PaymentRefNo ? true : false;
      this.model.Payments = item.Payments;
      this.model.Items = item.Items;
      this.model.Adjustments = [...item.Adjustments];
      this.model.ExchangeRate = item.ExchangeRate;
      this.model.TMApproved = item.TMApproved;
      this.model.AdminApproved = item.AdminApproved;
      this.model.PaymentMethodType = item.PaymentMethodType;
      await this.initData(true);
      // set loans
      this.loanItems = item.LoanItems;
      this.loadingPayrun = false;
    },
    preparePayRunToSave(): PayRun {
      const payRun = {} as PayRun;
      payRun.id = this.model.id;
      if (this.isEditMode && !payRun.id) {
        this.snackbarMessage({
          message: "Cannot update Payrun, Because the Payrun is not valid",
          type: "error",
        });
        this.loading = false;
        this.$emit("setAddEditMode", this.isEditMode, true, false);
        return payRun;
      }

      payRun.FromDate = this.model.FromDate;
      payRun.ToDate = this.model.ToDate;
      payRun.Payments = this.model.Payments;
      payRun.PaymentMethodType = this.model.PaymentMethodType;
      payRun.CryptoCurrencyId = this.model.CryptoCurrencyId;
      payRun.BankAccountId = this.model.BankAccountId;
      payRun.WiseAccountId = this.model.WiseAccountId;
      payRun.PaypalId = this.model.PaypalId;
      payRun.Items = this.model.Items;
      payRun.Adjustments = this.model.Adjustments;
      payRun.ExchangeRate = this.model.ExchangeRate;
      payRun.ExchangeCurrency = this.exchangeCurrencyId;
      payRun.AdminApproved = this.model.AdminApproved;
      payRun.TMApproved = this.model.TMApproved;
      if (this.selectedTeammember) {
        payRun.TeamMemberId = this.selectedTeammember.Id;
        payRun.TeamMember = {
          id: this.selectedTeammember.Id,
          Name: `${this.selectedTeammember.FirstName} ${this.selectedTeammember.LastName}`,
        } as TeammemberOfPayRun;
      }

      payRun.AUDTotalPay = this.removeDecimals(this.totalPayment);
      payRun.LCTotalPay = this.removeDecimals(this.totalPaymentLC);
      payRun.LoanItems = this.loanItems;
      return payRun;
    },
    async save() {
      const totalPay = Number(this.GetFormatTwoDecimal(this.totalPayment));
      const totalPayIn = Number(this.GetFormatTwoDecimal(this.totalPaymentLC));
      if (totalPay < 0 || totalPayIn < 0) {
        store.dispatch("showErrorMessage", "You cannot proceed with a negative payment amount. Please review the values.");
      } else {
        if (this.selectedTeammember) {
          const isValid = await (this.$refs.frmPayRun as any).validate();
          if (isValid.valid) {
            this.loadingInfo = true;
            const payRun = this.preparePayRunToSave();
            PayRunService.set(payRun)
              .then((result: any) => {
                // loan installment payment
                this.model.id = result.id;
                var auditTrail = {
                  DateTime: new Date(),
                  RecordType: { Type: AuditTrailRecordTypes.Payrun, Id: result.id } as RecordType,
                  UserId: store.state.userInfo.id,
                } as AuditTrail;
                if (this.selectedPayRun?.AdminApproved !== this.model.AdminApproved && this.model.AdminApproved) {
                  var auditTrailModel = { ...auditTrail, Event: AuditTrailEvents.AdminApprove } as AuditTrail;
                  AuditTrailService.setForAdmin(auditTrailModel);
                }
                LoanService.loanInstallmentPayment(this.prepareLoanItemsForSubmit(payRun.LoanItems), this.model.id).then((result: any) => {
                  this.snackbarMessage({
                    message: "PayRun saved successfully!",
                    type: "success",
                  });
                  this.closeAddOrEditPayrunPopup(true);
                });
              })
              .finally(() => {
                this.loadingInfo = false;
              });
          }
        }
      }
    },
    prepareLoanItemsForSubmit(loanItems: LoanItem[]) {
      const preparedLoanItems = [] as LoanItem[];
      if (loanItems && loanItems != null && loanItems.length > 0) {
        loanItems.forEach((item) => {
          item.Amount *= -1;
          preparedLoanItems.push(item);
        });
      }
      return preparedLoanItems;
    },
    formatNumber(value: number): string {
      return Utils.numberToCurrency(value);
    },
    getTimesheetsOfWeek(w: WeekRange, commitmentId: string, allTimesheets: Timesheet[] | null): Timesheet[] {
      if (allTimesheets) {
        const startDate = Utils.vsDateToDatetime(w.from);
        const endDate = Utils.vsDateToDatetime(w.to);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        return allTimesheets.filter(
          (t) =>
            t.Commitment &&
            t.Date &&
            t.Commitment.id === commitmentId &&
            Utils.timestampToDate(t.Date) >= startDate &&
            Utils.timestampToDate(t.Date) <= endDate
        );
      } else {
        return [];
      }
    },
    formatDate(dt: Date | string): string {
      return Utils.formatDate(dt);
    },
    async fetchTeammemberPayableCharges(editMode: boolean = false) {
      if (this.selectedTeammember) {
        const teammemberPayableCharges = await PayRunService.GetTeammemberPayableCharges(
          this.selectedTeammember.Id,
          Utils.getDateString(this.model.FromDate),
          Utils.getDateString(this.model.ToDate)
        );

        if (teammemberPayableCharges) {
          this.billableItems = teammemberPayableCharges;
        } else {
          this.billableItems = [];
        }
        this.model.Items = this.allBillableCommitmentItemsV2;
      }
    },
    async fetchLastUnpaidItemOfLoans() {
      if (this.selectedTeammember) {
        var reqModel: UnpaidLoanItemRequestModel = {
          TeamMemberId: this.selectedTeammember.Id,
          StartDate: this.model.FromDate,
          EndDate: this.model.ToDate,
        };
        const items = await LoanService.GetLastUnpaidItemOfLoanByTeamMemberId(reqModel);
        items.forEach((item: any) => {
          item.Amount = -1 * item.Amount;
        });
        this.loanItems = items;
      }
    },
    getPasPayRunDates() {
      const prePayRunDates = [] as string[];
      if (this.pasPayRuns) {
        this.pasPayRuns.forEach((payRun) => {
          const fromDate = Utils.addTimezoneOffsetToStartDate(payRun.FromDate);
          const toDate = Utils.addTimezoneOffsetToEndDate(payRun.ToDate);
          if (fromDate && toDate) {
            while (fromDate <= toDate) {
              prePayRunDates.push(fromDate.toISOString().slice(0, 10));
              fromDate.setDate(fromDate.getDate() + 1);
            }
          }
        });
      }

      return prePayRunDates;
    },
    checkPayRunExist() {
      // Check if any date in the array falls within the range
      for (let index = 0; index < this.getPasPayRunDates().length; index++) {
        const dateStr = this.getPasPayRunDates()[index];
        const fromDateStr = Utils.addTimezoneOffsetToStartDate(this.model.FromDate)!.toISOString().slice(0, 10);
        const toDateStr = Utils.addTimezoneOffsetToEndDate(this.model.ToDate)!.toISOString().slice(0, 10);
        if (dateStr >= fromDateStr && dateStr <= toDateStr) {
          return true;
        }
      }
      return false;
    },
    async initData(editMode: boolean = false) {
      this.pageLoading = true;
      await this.fetchTeammemberPayableCharges(editMode);
      if (!editMode) {
        await this.fetchLastUnpaidItemOfLoans();
      }
      this.pageLoading = false;
    },
    async processRun(): Promise<void> {
      if (this.checkPayRunExist()) {
        this.snackbarMessage({
          message: "Date conflict with some past pay runs!",
          type: "error",
        });
        return;
      }
      if (this.selectedTeammember) {
        this.model.id = "";
        this.loadingInfo = true;

        await this.initData();
        if (this.loadingInfo) {
          if (this.model.Items.length) {
            this.fullScreenMode = true;
          } else {
            this.snackbarMessage({
              message: "There is no Timesheet for this Teammember in this date range",
              type: "error",
            });
          }
        }
        this.loadingInfo = false;
      } else {
        this.timesheets = [];
      }
    },
    getCommitmentName(cm: any) {
      return cm?.Name || cm?.name;
    },
    SumRowBackground: function (item: any) {
      return item.IsSum ? "sum-background" : "";
    },
    setFromDateAndToDate(date: string | Date) {
      // set from date
      if (typeof date === "string") {
        this.model.FromDate = date ? Utils.vsDateToDatetime(`${date}-01`) : Utils.vsDateToDatetime(new Date().toDateString());
      } else {
        this.model.FromDate = Utils.firstDayOfMonth(date);
      }

      // set to date
      this.model.ToDate = Utils.lastOfTime(Utils.lastDayOfMonth(this.model.FromDate));
    },
    getDaysOfWeek(week: WeekRange, commitmentId: string, timesheets: Timesheet[] | null): WeekDay[] {
      if (timesheets) {
        let days: {
          date: string;
          datails: WeekDay;
        }[] = [];
        let daysInWeek: Date[] = [];
        let fromDate = new Date(week.from);
        let toDate = new Date(week.to);
        while (fromDate <= toDate) {
          daysInWeek.push(JSON.parse(JSON.stringify(fromDate)));
          fromDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
        }
        daysInWeek.forEach((day) => {
          let convertedDay = new Date(day);

          days.push({
            date: convertedDay.toDateString() ?? "",
            datails: {
              DayOfWeek: this.dayNames[convertedDay.getDay()],
              TimesheetHuors: 0,
              AdjustmentHours: 0,
              IsLocked: false,
            },
          });

          timesheets
            .filter(
              (t) =>
                t.Commitment &&
                t.Date &&
                t.Commitment.id === commitmentId &&
                Utils.timestampToDate(t.Date).toDateString() == convertedDay.toDateString()
            )
            .forEach((t) => {
              if (t.Date) {
                let day = days.find((i) => i.date == t.Date?.toDateString());
                if (day) {
                  day.datails.TimesheetHuors += t.Effort;
                  day.datails.AdjustmentHours += t.Adjustment;
                  day.datails.IsLocked = t.isLocked;
                }
              }
            });
        });
        return days.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1)).map((item) => item.datails);
      }
      return [];
    },
    getSumTimesheetOfWeekV2(items: any[], index: number): number {
      if (items) {
        let retVal = 0;
        items.forEach((item) => {
          if (item.Weeks[index] && item.Weeks[index].Hours) {
            retVal += this.removeDecimals(item.Weeks[index].Hours);
          }
        });
        return retVal;
      } else {
        return 0;
      }
    },
    getTimesheetHoursOfWeekV2(w: WeekRange, commitmentId: string, timesheets: Timesheet[] | null): number {
      let retVal = 0;
      this.getTimesheetsOfWeek(w, commitmentId, timesheets).forEach(
        (t) => (retVal += t.Adjustment ? this.removeDecimals(t.Effort + parseFloat(`${t.Adjustment}`)) : this.removeDecimals(t.Effort))
      );
      return retVal;
    },
    getSplittedWeeksV2(cmtId: string, timesheets: Timesheet[] | null): any[] {
      const weeks = [] as any[];
      this.splitWeeks.forEach((w: any) => {
        const toDate = Utils.vsDateToDatetime(w.to);
        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);
        weeks.push({
          Days: this.getDaysOfWeek(w, cmtId, timesheets),
          Hours: this.getTimesheetHoursOfWeekV2(w, cmtId, timesheets),
          FromDate: Utils.vsDateToDatetime(w.from),
          ToDate: toDate,
        });
      });
      return weeks;
    },
    getCommitmentHeadersV2(): any[] {
      let commitmentsHeaders: any[] = [
        { title: "Teammembers", value: "Teammembers", sortable: false, align: "center" },
        { title: "Commitment Name", value: "CommitmentName", sortable: false, align: "center" },
      ];
      this.splitWeeks.forEach((item: any, index: any) => {
        let columnName = `Week ${index + 1}
        ${this.formatDate(item.from)}- ${this.formatDate(item.to)}`;
        commitmentsHeaders.push({ title: columnName, value: `Week${index}`, sortable: false, class: "break-line", align: "center" });
      });
      commitmentsHeaders.push({ title: "Total Hour", value: "TotalHours", sortable: false, align: "center" });
      commitmentsHeaders.push({ title: "Performance Coefficient", value: "Performance", sortable: false, align: "center" });
      commitmentsHeaders.push({ title: "Total Pay", value: "TotalPay", sortable: false, align: "center" });
      return commitmentsHeaders;
    },
    getBillableCommitmentItemsV2(billableItem: PayrunBillableItem | undefined, includeSum: boolean = false): any[] {
      if (billableItem && billableItem.commitmentBillableItems) {
        const items = billableItem.commitmentBillableItems;
        let sumTotalHour = 0;
        let sumTotalPay = 0;
        const mappedCommitments = [] as any;

        items.forEach((item) => {
          const commitmentId = item.commitment && item.commitment.id ? item.commitment.id : "";
          const teammemberName =
            item.commitment && item.commitment.TeamMembers && item.commitment.TeamMembers.length ? item.commitment.TeamMembers[0].TmFullName : "";
          const charges = item.commitmentCharges;
          const timesheets = item.timesheets ? (item.timesheets as Timesheet[]) : null;
          if (charges && charges.length) {
            charges.forEach((chargeItem) => {
              const fee = chargeItem.fee ? chargeItem.fee : 0;
              const quantity = chargeItem.quantity ? chargeItem.quantity : 0;
              const totalHour = quantity;
              sumTotalHour += totalHour;
              const totalPay = quantity * fee;
              sumTotalPay += totalPay;

              const mappedCharge = {
                Teammembers: teammemberName,
                CommitmentName: item.commitment?.Name,
                CommitmentId: commitmentId,
                TotalHours: this.removeDecimals(totalHour),
                TotalPay: this.removeDecimals(totalPay),
                AdjustmentHours: 0,
                SeniorityLevel: fee,
                PayrunItemType: billableItem.name?.replaceAll(/\s+/g, ""),
                IsSum: false,
                Commitment: {
                  id: item.commitment?.id,
                },
                Weeks: this.getSplittedWeeksV2(commitmentId, timesheets),
                CommitmentChargeId: chargeItem.id,
              };

              mappedCommitments.push(mappedCharge);
            });
          }
        });

        if (includeSum) {
          let sumItem = {
            CommitmentName: "Sum",
            CommitmentId: "Sum",
            TotalHours: this.removeDecimals(sumTotalHour),
            TotalPay: this.removeDecimals(sumTotalPay),
            AdjustmentHours: 0,
            SeniorityLevel: "-",
            IsSum: true,
          };
          let sumWeeks: WeekOfPayRunItem[] = [];
          this.splitWeeks.forEach((week: any, index: any) => {
            sumWeeks.push({
              Days: [],
              Hours: this.removeDecimals(this.getSumTimesheetOfWeekV2(mappedCommitments, index)),
              FromDate: new Date(),
              ToDate: new Date(),
            });
          });
          (sumItem as any)["Weeks"] = sumWeeks;
          mappedCommitments.push(sumItem as any);
        }

        return mappedCommitments;
      }

      return [];
    },
  },
  watch: {
    showAddOrEdit(newValue) {
      if (newValue) {
        this.initPayRun(this.selectedPayRun);
      }
    },
    selectedTeammember() {
      this.resetModel();
    },
    selectedMonth(newValue) {
      if (newValue) {
        this.setFromDateAndToDate(`${newValue.year}-${newValue.month + 1}`);
      }
    },
  },
  computed: {
    allBillableCommitmentItemsV2(): any[] {
      const mappedBillableItems = [] as any[];
      if (this.billableItems) {
        this.billableItems.forEach((billableItem) => {
          mappedBillableItems.push(...this.getBillableCommitmentItemsV2(billableItem));
        });
      }
      return mappedBillableItems;
    },
    exchangeCurrencyId() {
      return this.exchangeCurrency ? this.exchangeCurrency.id : "";
    },
    mainCurrencyId() {
      return this.mainCurrency ? this.mainCurrency.id : "";
    },
    isExchangeCurrencyIdIsSameAsMainCurrency(): boolean {
      return this.exchangeCurrencyId == this.mainCurrencyId;
    },
    audAdjustments(): Adjustment[] {
      return this.model.Adjustments.filter((ad) => ad.type === this.mainCurrencyId);
    },
    secondaryAdjustments(): Adjustment[] {
      return this.model.Adjustments.filter((ad) => ad.type !== this.mainCurrencyId && ad.type === this.exchangeCurrencyId);
    },
    isReadOnly(): boolean {
      return this.isEditMode && this.editModelHasPayment && this.model.Payments != null && this.model.Payments.length > 0;
    },
    sumTotalPay(): number {
      if (this.model.Items) {
        let retVal = 0;
        this.model.Items.forEach((item) => (retVal += this.removeDecimals(item.TotalPay)));
        return retVal;
      } else {
        return 0;
      }
    },
    selectedDate: {
      get(): Date {
        return this.model.FromDate;
      },
      set(date: string): void {
        this.setFromDateAndToDate(date);
      },
    },
    formatedPayMonth() {
      return this.model.ToDate ? Utils.getMonthName(this.model.ToDate) : null;
    },
    splitWeeks() {
      return Utils.getSplittedWeeks(this.model.FromDate, this.model.ToDate);
    },
    loanTotalPayment() {
      let adSum = 0;

      // loan adjustments
      if (this.loanItems) {
        this.loanItems
          .filter((c) => !c.IsDeleted)
          .forEach((li) => {
            adSum += li.Amount;
          });
      }
      return this.removeDecimals(adSum);
    },
    totalPayment() {
      let adSum = 0;
      this.model.Adjustments.filter((ad) => ad.type === this.mainCurrencyId).forEach((ad) => {
        adSum += ad.adjustment;
      });

      if (this.loanTotalPayment) {
        adSum += this.loanTotalPayment;
      }

      return this.removeDecimals(this.sumTotalPay + adSum);
    },
    totalPaymentLC() {
      let adSum = 0;
      this.model.Adjustments.filter((ad) => ad.type !== this.mainCurrencyId && ad.type === this.exchangeCurrencyId).forEach((ad) => {
        adSum += ad.adjustment;
      });
      return this.removeDecimals(this.totalPayment * this.model.ExchangeRate + adSum);
    },
  },
});
</script>
<style lang="scss" scoped>
.main-dialog {
  width: 700px;
}

.main-dialog.fullscreen {
  width: 100%;
}
.main-tr {
  text-align: center;
}

table.tbl {
  border: 1px solid silver;
  background: #888;
  border: 1px soliver silver;
  width: 100%;
  border-radius: 5px;
  thead {
    tr {
      th {
        padding: 5px;
        background: #fff;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        td {
          border-radius: 5px;
          cursor: pointer;
          background: #eee;
        }
      }
      td {
        padding: 5px;
        background: #fff;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}
</style>
