<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">Set Support Status as {{ newStatus }}</div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12"> <span class="font-weight-medium">Title</span>: {{ supportModel?.Title }} </v-col>
        <v-col cols="6"> <span class="font-weight-medium">Sender</span>: {{ titleCase(supportModel?.Sender) }} </v-col>
        <v-col cols="6"> <span class="font-weight-medium">Created At</span>: {{ formatDate(supportModel?.CreatedOn) }} </v-col>

        <v-col cols="6" v-if="supportModel">
          <v-switch
            :disabled="!supportModel.AllowedToSendReplyToSender"
            color="primary"
            v-model="sendToSender"
            hide-details
            label="Send reply to sender"
          ></v-switch>
          <v-switch
            :disabled="!supportModel.AllowedToSendReplyToClient"
            color="primary"
            v-model="sendToClient"
            hide-details
            label="Send message to client"
          ></v-switch>
        </v-col>
        <v-col cols="6" v-if="supportModel">
          <v-switch
            :disabled="!supportModel.AllowedToSendReplyToAdmin"
            color="primary"
            v-model="sendToAdmin"
            hide-details
            label="Send message to admin"
          ></v-switch>
          <v-switch
            :disabled="!supportModel.AllowedToSendReplyToSupport"
            color="primary"
            v-model="sendToSupport"
            hide-details
            label="Send message to support"
          ></v-switch>
        </v-col>
        <v-col cols="12"> <TextAreaField dense outlined v-model="message" label="Custome Message" placeholder="Custome Message" /> </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn color="secondary" class="mr-10" @click="setNewStatus" :loading="loading">Set as {{ newStatus }}</v-btn>
        <v-btn variant="text" color="primary" @click="close" :loading="loading"> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  AdminSupportNotificationActionRequestModel,
  AdminSupportNotificationItemModel,
  NotificationApi,
} from "shared-components/src/services/openApi/api";
import moment from "moment";
import ChatBox from "shared-components/src/components/ChatBox/ChatBox.vue";
import store from "@/store";

export default defineComponent({
  props: ["supportId", "newStatus"],
  components: { ChatBox },
  async mounted() {
    await this.fetchSupportItem();
  },
  data() {
    return {
      supportModel: null as AdminSupportNotificationItemModel | null,
      loading: false,
      sendToSender: false,
      sendToAdmin: false,
      sendToClient: false,
      sendToSupport: false,
      message: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async setNewStatus(event: any) {
      if (this.supportId) {
        try {
          this.loading = true;
          await new NotificationApi().setSupportTicketStatus({
            SupportTicketId: this.supportId,
            Message: this.message,
            NewStatus: this.newStatus,
            SendToAdmin: this.sendToAdmin,
            SendToClient: this.sendToClient,
            SendToSupport: this.sendToSupport,
            ReplyToSender: this.sendToSender,
          } as AdminSupportNotificationActionRequestModel);
          this.close();
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchSupportItem() {
      if (this.supportId) {
        try {
          this.loading = true;
          const result = (await new NotificationApi().getSupportNotificationById(this.supportId)).data;
          this.supportModel = result;
        } finally {
          this.loading = false;
        }
      }
    },
    formatDate(strDate: string | undefined) {
      if (strDate) {
        return moment(strDate).format("YYYY-MM-DD HH:MM:ss");
      }
      return "-";
    },
    titleCase(str: string | undefined) {
      if (str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      }
      return "-";
    },
  },
  computed: {
    getUserId() {
      return store.state.userInfo.id;
    },
  },
});
</script>
