import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CurrencyTextField = _resolveComponent("CurrencyTextField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode(" Loan Request For Team Member ")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmRegister",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "filters",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ComboBoxField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.selectedTeammember,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                        items: _ctx.teammemberList,
                        "item-title": "FullName",
                        "item-value": "Id",
                        outlined: "",
                        dense: "",
                        label: "Team Member"
                      }, {
                        item: _withCtx(({ item, index, props }) => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, _mergeProps(props, {
                                class: { 'txt-color': item.raw.isFirstInactive },
                                title: item.raw.FullName
                              }), null, 16, ["class", "title"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }, 8, ["rules", "modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CurrencyTextField, {
                        rules: [_ctx.rules.required, _ctx.rules.currency],
                        dense: "",
                        outlined: "",
                        label: "Amount AUD",
                        modelValue: _ctx.model.Amount,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.Amount) = $event)),
                        readonly: _ctx.isReadOnly
                      }, null, 8, ["rules", "modelValue", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        dense: "",
                        rules: [_ctx.rules.required, _ctx.rules.int, _ctx.isValidInstallments],
                        outlined: "",
                        label: "Number Of Installments",
                        modelValue: _ctx.model.Installment,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.Installment) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_menu, {
                        ref: "startMenu",
                        modelValue: _ctx.startMenu,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.startMenu) = $event)),
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_TextField, _mergeProps({
                            rules: [_ctx.rules.required],
                            modelValue: _ctx.formatedProposedDate,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formatedProposedDate) = $event)),
                            label: "Requested Start Date",
                            readonly: "",
                            dense: "",
                            outlined: ""
                          }, props), null, 16, ["rules", "modelValue"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_date_picker, {
                            "first-day-of-week": "1",
                            ref: "picker",
                            modelValue: _ctx.model.ProposedPaymentDate,
                            "onUpdate:modelValue": [
                              _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.ProposedPaymentDate) = $event)),
                              _cache[5] || (_cache[5] = ($event: any) => (_ctx.startMenu = false))
                            ],
                            min: _ctx.minDate
                          }, null, 8, ["modelValue", "min"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        modelValue: _ctx.model.Description,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.Description) = $event)),
                        outlined: "",
                        label: "Description"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "primary",
                class: "mr-10",
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("mdi-check")
                    ])),
                    _: 1
                  }),
                  _cache[11] || (_cache[11] = _createTextVNode(" Save"))
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                class: "secondary_btn",
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" cancel")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}