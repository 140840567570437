<template>
  <v-card v-if="commitment">
    <v-card-title>
      <v-btn prepend-icon="mdi-link" variant="text" :href="getCommitmentUrl()" target="_blank" rel="noopener noreferrer">{{ commitment.Name }}</v-btn>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('StartAndEnd') }">
        <v-col cols="6">
          Start Date: <b>{{ formatDate(commitment.StartDate) }} </b></v-col
        >
        <v-col cols="6">
          End Date: <b>{{ formatDate(commitment.EndDate) }}</b>
        </v-col>
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('Customer') }">
        <v-col cols="12">
          Customer: <b>{{ commitment.Customers[0].CuFullName }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('Project') }">
        <v-col cols="12">
          Project: <b>{{ commitment.ProjectDetails[0].ProjectName }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('Teammember') }">
        <v-col cols="12">
          Teammember: <b>{{ commitment.TeamMembers[0].TmFullName }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('PublicHolidayZone') }">
        <v-col cols="12">
          Public Holiday Zone: <b>{{ commitment.PublicHolidayZoneName ?? "-" }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('ProjectLead') }">
        <v-col cols="12">
          Project Lead: <b>{{ mergeName(commitment.ProjectLeadDetail) }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('PortfolioManager') }">
        <v-col cols="12">
          Portfolio Manager: <b>{{ mergeName(commitment.PortfolioManagerDetail) }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('HoursPerWeek') }">
        <v-col cols="12">
          Hours per week: <b>{{ commitment.HoursPerWeekRadio ? "Casual" : commitment.HoursPerWeekMax ? commitment.HoursPerWeekMax : "-" }} </b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('LocationType') }">
        <v-col cols="12">
          Location Type: <b>{{ commitment.LocationType ?? "-" }} </b></v-col
        >
        <v-row no-gutters class="pl-2">
          <v-col cols="12" class="pa-0">
            Timezone: <b>{{ commitment.TimeZone ?? "-" }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            Days of week: <b>{{ commitment.WokingDays ?? "-" }} </b></v-col
          >
          <v-col cols="6" class="pa-0">
            From: <b>{{ commitment.StartWorkClock ?? "-" }} </b></v-col
          >
          <v-col cols="6" class="pa-0">
            To: <b>{{ commitment.EndWorkClock ?? "-" }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            Office Location: <b>{{ commitment.OfficeLocationName ?? "-" }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            No. Of Office Days Per Week: <b>{{ commitment.OfficeDaysPerWeek ?? "-" }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            Days Of Week In The Office: <b>{{ commitment.DaysOfWeekInOffice ?? "-" }} </b></v-col
          >
        </v-row>
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row class="pt-5 pb-1">
        <v-col cols="12"> <b>Addons:</b> </v-col>
      </v-row>
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('TimesheetProcessing') }">
        <v-col cols="12">
          Timesheet Processing: <b>{{ commitment.TimesheetProcessing ? "Enable" : "Disable" }}</b></v-col
        >
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('Payroll') }">
        <v-col cols="12">
          Payroll: <b>{{ commitment.SalaryCurrency ? "Enable" : "Disable" }}</b></v-col
        >
        <v-row no-gutters class="pl-2">
          <v-col cols="4" class="pa-0">
            Salary: <b>{{ commitment.SeniorityLevel && commitment.SeniorityLevel != 0 ? commitment.SeniorityLevel : "-" }} </b></v-col
          >
          <v-col cols="4" class="pa-0">
            Amount: <b>{{ commitment.SalaryMonthly ?? "-" }} </b></v-col
          >
          <v-col cols="4" class="pa-0">
            Currency: <b>{{ commitment.SalaryCurrency ?? "-" }} </b></v-col
          >
        </v-row>
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('Contract') }">
        <v-col cols="12">
          Contract:
          <b>{{ commitment.ContractManagement || commitment.ContactVerification || commitment.IDVerification ? "Enable" : "Disable" }}</b></v-col
        >
        <v-row no-gutters class="pl-2">
          <v-col cols="12" class="pa-0">
            Contract Management: <b>{{ commitment.ContractManagement }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            Contact Information Verification: <b>{{ commitment.ContactVerification }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            ID Verification: <b>{{ commitment.IDVerification }} </b></v-col
          >
        </v-row>
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('VirtualDesktop') }">
        <v-col cols="12">
          Virtual Desktop:
          <b>{{ commitment.VirtualDesktopSpec ? "Enable" : "Disable" }}</b></v-col
        >
        <v-row no-gutters class="pl-2">
          <v-col cols="4" class="pa-0">
            Cpu: <b>{{ commitment.VirtualDesktopSpec ? commitment.VirtualDesktopSpec.Cpu : "-" }} </b></v-col
          >
          <v-col cols="4" class="pa-0">
            Memory: <b>{{ commitment.VirtualDesktopSpec ? commitment.VirtualDesktopSpec.Ram : "-" }} </b></v-col
          >
          <v-col cols="4" class="pa-0">
            Disk: <b>{{ commitment.VirtualDesktopSpec ? commitment.VirtualDesktopSpec.Disk : "-" }} </b></v-col
          >
          <v-col cols="12" class="pa-0">
            Location:
            <b
              >{{ commitment.VirtualDesktopSpec && commitment.VirtualDesktopSpec.Location ? commitment.VirtualDesktopSpec.Location : "-" }}
            </b></v-col
          >
          <v-col cols="12" class="pa-0">
            Operating System : <b>{{ commitment.VirtualDesktopSpec ? commitment.VirtualDesktopSpec.OperatingSystem : "-" }} </b></v-col
          >
        </v-row>
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('OfficeSpace') }">
        <v-col cols="12">
          Office Space:
          <b>{{ commitment.OfficeSpaceId ? "Enable" : "Disable" }}</b></v-col
        >
        <v-row no-gutters class="pl-2">
          <v-col cols="4" class="pa-0">
            Name: <b>{{ commitment.OfficeSpaceName ?? "-" }} </b></v-col
          >
        </v-row>
      </v-row>
      <v-divider class="border-opacity-50" />
      <v-row no-gutters :class="{ 'bg-difference': hasDifference('LineManager') }">
        <v-col cols="12">
          Line Manager:
          <b>{{ commitment.LineManagerId ? "Enable" : "Disable" }}</b></v-col
        >
        <v-row no-gutters class="pl-2">
          <v-col cols="4" class="pa-0">
            Name: <b>{{ mergeName(commitment.LineManagerDetail) }} </b></v-col
          >
        </v-row>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { CommitmentModel } from "shared-components/src/services/openApi/api";
import moment from "moment";
import Utils from "@/utils/Utils";

export default defineComponent({
  props: ["reviewModel", "isNewCommitment"],
  data() {
    return {
      commitment: null as CommitmentModel | null,
    };
  },
  mounted() {
    this.commitment = this.isNewCommitment == true ? this.reviewModel.ToCommitment : this.reviewModel.FromCommitment;
  },
  methods: {
    hasDifference(type: string) {
      if (this.reviewModel.FromCommitment) {
        const fromCommitment = this.reviewModel.FromCommitment;
        const toCommitment = this.reviewModel.ToCommitment;
        if (type == "StartAndEnd") {
          return fromCommitment.StartDate != toCommitment.StartDate || fromCommitment.EndDate != toCommitment.EndDate;
        }
        if (type == "Customer") {
          return fromCommitment.Customers[0].CuFullName != toCommitment.Customers[0].CuFullName;
        }
        if (type == "Project") {
          return fromCommitment.ProjectDetails[0].ProjectName != toCommitment.ProjectDetails[0].ProjectName;
        }
        if (type == "Teammember") {
          return fromCommitment.TeamMembers[0].TmFullName != toCommitment.TeamMembers[0].TmFullName;
        }
        if (type == "PublicHolidayZone") {
          return fromCommitment.PublicHolidayZoneName != toCommitment.PublicHolidayZoneName;
        }
        if (type == "ProjectLead") {
          return fromCommitment.ProjectLeadId != toCommitment.ProjectLeadId;
        }
        if (type == "PortfolioManager") {
          return fromCommitment.PortfolioManagerId != toCommitment.PortfolioManagerId;
        }

        if (type == "HoursPerWeek") {
          return fromCommitment.HoursPerWeekRadio != toCommitment.HoursPerWeekRadio || fromCommitment.HoursPerWeekMax != toCommitment.HoursPerWeekMax;
        }
        if (type == "LocationType") {
          return (
            fromCommitment.LocationType != toCommitment.LocationType ||
            fromCommitment.TimeZone != toCommitment.TimeZone ||
            fromCommitment.WokingDays.toString() != toCommitment.WokingDays.toString() ||
            fromCommitment.StartWorkClock != toCommitment.StartWorkClock ||
            fromCommitment.EndWorkClock != toCommitment.EndWorkClock ||
            fromCommitment.OfficeLocationName != toCommitment.OfficeLocationName ||
            fromCommitment.OfficeDaysPerWeek != toCommitment.OfficeDaysPerWeek ||
            fromCommitment.DaysOfWeekInOffice.toString() != toCommitment.DaysOfWeekInOffice.toString()
          );
        }
        if (type == "TimesheetProcessing") {
          return fromCommitment.TimesheetProcessing != toCommitment.TimesheetProcessing;
        }
        if (type == "Payroll") {
          return (
            fromCommitment.SalaryCurrency != toCommitment.SalaryCurrency ||
            fromCommitment.SalaryMonthly != toCommitment.SalaryMonthly ||
            fromCommitment.SeniorityLevel != toCommitment.SeniorityLevel
          );
        }
        if (type == "Contract") {
          return (
            fromCommitment.ContractManagement != toCommitment.ContractManagement ||
            fromCommitment.ContactVerification != toCommitment.ContactVerification ||
            fromCommitment.IDVerification != toCommitment.IDVerification
          );
        }
        if (type == "VirtualDesktop") {
          return (
            fromCommitment.VirtualDesktopSpec?.Cpu != toCommitment.VirtualDesktopSpec?.Cpu ||
            fromCommitment.VirtualDesktopSpec?.Ram != toCommitment.VirtualDesktopSpec?.Ram ||
            fromCommitment.VirtualDesktopSpec?.Disk != toCommitment.VirtualDesktopSpec?.Disk ||
            fromCommitment.VirtualDesktopSpec?.Location != toCommitment.VirtualDesktopSpec?.Location ||
            fromCommitment.VirtualDesktopSpec?.OperatingSystem != toCommitment.VirtualDesktopSpec?.OperatingSystem
          );
        }
        if (type == "Salary") {
          return (
            fromCommitment.SalaryCurrency != toCommitment.SalaryCurrency ||
            fromCommitment.SalaryMonthly != toCommitment.SalaryMonthly ||
            fromCommitment.SeniorityLevel != toCommitment.SeniorityLevel
          );
        }
        if (type == "OfficeSpace") {
          return fromCommitment.OfficeSpaceId != toCommitment.OfficeSpaceId;
        }
        if (type == "LineManager") {
          return fromCommitment.LineManagerId != toCommitment.LineManagerId;
        }
      }
      return false;
    },
    mergeName(model: any) {
      return model ? `${model.FirstName} ${model.LastName}` : "-";
    },
    formatDate(date: Date): string {
      return Utils.toVsDateFormat(moment(date).toDate());
    },
    getCommitmentUrl() {
      return `#id=${this.commitment?.id}&action=view`;
    },
    cancel() {
      (this.$refs.frmCommitment as any).reset();
      this.$emit("close");
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";
.bg-difference {
  background-color: $c_orange;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
}
</style>
