import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mx-auto" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "text-h5" }, {
        default: _withCtx(() => [
          _createTextVNode(" Invoice " + _toDisplayString(_ctx.invoice.invoiceNumber), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.logoUrl,
                    style: {"max-width":"45%"}
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mb-2" }, [
                    _createElementVNode("strong", null, "Bill To:")
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.invoice.customer.CuFullName) + " for " + _toDisplayString(_ctx.formatDate(_ctx.invoice.fromDate)) + " to " + _toDisplayString(_ctx.formatDate(_ctx.invoice.toDate)), 1),
                  _createElementVNode("p", _hoisted_2, [
                    _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Invoice Number:", -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.invoice.invoiceNumber), 1)
                  ]),
                  _createElementVNode("p", _hoisted_3, [
                    _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Due Date:", -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.invoice.dueDate)), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_data_table, {
            headers: _ctx.headers,
            items: _ctx.itemsWithTotal,
            "item-key": "chargeId",
            "items-per-page": -1,
            "hide-default-footer": "",
            class: "mt-7 mb-10"
          }, {
            "item.title": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: _normalizeClass({ 'font-weight-bold': item.isTotal })
              }, _toDisplayString(item.title), 3)
            ]),
            "item.quantity": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: _normalizeClass({ 'font-weight-bold': item.isTotal })
              }, _toDisplayString(item.isTotal ? "" : item.quantity), 3)
            ]),
            "item.fee": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: _normalizeClass({ 'font-weight-bold': item.isTotal })
              }, _toDisplayString(item.isTotal ? "" : `$${_ctx.GetFormatTwoDecimal(item.fee)}`), 3)
            ]),
            "item.total": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: _normalizeClass({ 'font-weight-bold': item.isTotal })
              }, " $" + _toDisplayString(_ctx.GetFormatTwoDecimal(item.total)), 3)
            ]),
            _: 1
          }, 8, ["headers", "items"]),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "6" }, {
                default: _withCtx(() => [
                  (!_ctx.invoice.id)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        color: "primary",
                        onClick: _ctx.saveInvoice,
                        loading: _ctx.loading
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("mdi-check")
                            ])),
                            _: 1
                          }),
                          _cache[4] || (_cache[4] = _createTextVNode(" Save "))
                        ]),
                        _: 1
                      }, 8, ["onClick", "loading"]))
                    : _createCommentVNode("", true),
                  _cache[7] || (_cache[7] = _createTextVNode("   ")),
                  _createVNode(_component_v_btn, {
                    color: "error",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      }),
                      _cache[6] || (_cache[6] = _createTextVNode(" Close "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}