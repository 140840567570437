<template>
  <div>
    <wazuh-dashboard :vmId="vmId" @Close="Close()" />
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import WazuhDashboard from "shared-components/src/components/Reports/Wazuh/Dashboard.vue";

export default defineComponent({
  props:["vmId"],
  components: {
    WazuhDashboard,
  },
  methods:{
    Close(){
      this.$emit("close");
    }
  }
});
</script>
