import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.pageLoading,
    disabled: _ctx.pageLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[5] || (_cache[5] = _createElementVNode("span", null, " Reset password", -1)),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (!_ctx.resetLinkSent && !_ctx.token)
            ? (_openBlock(), _createBlock(_component_v_form, {
                key: 0,
                ref: "frmResetPass",
                modelValue: _ctx.valid,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
                "lazy-validation": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        md: "3",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextField, {
                            rules: [_ctx.rules.required, _ctx.rules.email],
                            modelValue: _ctx.email,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                            label: "Email",
                            placeholder: "Email",
                            outlined: ""
                          }, null, 8, ["rules", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        md: "3",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            loading: _ctx.loading,
                            class: "primary pt-3 mt-9 pb-7",
                            onClick: _ctx.reset
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("mdi-email")
                                ])),
                                _: 1
                              }),
                              _cache[7] || (_cache[7] = _createTextVNode(" Reset"))
                            ]),
                            _: 1
                          }, 8, ["loading", "onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.token && _ctx.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_v_form, {
                  ref: "frmNewPass",
                  modelValue: _ctx.valid,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valid) = $event)),
                  "lazy-validation": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          md: "3",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              rules: [_ctx.rules.required, _ctx.rules.email],
                              modelValue: _ctx.email,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                              label: "Email",
                              placeholder: "Email",
                              outlined: "",
                              readonly: ""
                            }, null, 8, ["rules", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          md: "3",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PasswordField, {
                              rules: [_ctx.rules.required],
                              modelValue: _ctx.newPassword,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newPassword) = $event)),
                              label: "Enter New Password",
                              placeholder: "Enter New Password",
                              outlined: ""
                            }, null, 8, ["rules", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          md: "3",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              loading: _ctx.loading,
                              class: "primary pt-7 pb-7",
                              onClick: _ctx.saveNewPassword
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode("mdi-email")
                                  ])),
                                  _: 1
                                }),
                                _cache[9] || (_cache[9] = _createTextVNode(" Save "))
                              ]),
                              _: 1
                            }, 8, ["loading", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.token && !_ctx.email && !_ctx.pageLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "There is an error on Validating Token"))
            : _createCommentVNode("", true),
          (_ctx.resetLinkSent)
            ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "12" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, "Please check your email (" + _toDisplayString(_ctx.email) + "), reset password link was sent to you mail.", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}