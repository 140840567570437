import ClientModel from "shared-components/src/models/ClientModel";
import ApiService from "./ApiService";

export default class ClientService {
  public static async getById(id: string): Promise<ClientModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/${id}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as ClientModel);
          } else {
            const item = result.data as ClientModel;
            resolve(item);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getList(): Promise<ClientModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/client", "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { ...item } as ClientModel;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: ClientModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/client", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(model: ClientModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put("/client", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
