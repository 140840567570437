import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "mr-4" }
const _hoisted_2 = {
  key: 1,
  class: "mr-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_NewCommitmentV2 = _resolveComponent("NewCommitmentV2")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_clone_commitment = _resolveComponent("clone-commitment")!
  const _component_ReviewCommitment = _resolveComponent("ReviewCommitment")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_v_col, { md: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            loading: _ctx.loading,
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _cache[20] || (_cache[20] = _createTextVNode(" Commitment List ")),
                      _createVNode(_component_v_divider, {
                        class: "mx-4",
                        inset: "",
                        vertical: ""
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        dark: "",
                        onClick: _ctx.newItem
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode("mdi-plus")
                            ])),
                            _: 1
                          }),
                          _cache[19] || (_cache[19] = _createTextVNode(" New "))
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      (_ctx.showNewItem)
                        ? (_openBlock(), _createBlock(_component_v_dialog, {
                            key: 0,
                            modelValue: _ctx.showNewItem,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showNewItem) = $event)),
                            persistent: "",
                            fullscreen: "",
                            onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.showNewItem = false), ["esc"]))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_NewCommitmentV2, {
                                ref: "newCommitment",
                                customers: _ctx.customers,
                                teammembers: _ctx.teammembers,
                                officeSpaces: _ctx.officeSpaces,
                                lineManagers: _ctx.lineManagers,
                                portfolioManagers: _ctx.portfolioManagers,
                                projectLeads: _ctx.projectLeads,
                                publicHolidayZones: _ctx.publicHolidayZones,
                                tdms: _ctx.tdms,
                                commitments: _ctx.desserts,
                                commitmentModel: _ctx.editItem,
                                onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewItem = false)),
                                onSaved: _ctx.commitmentSaved
                              }, null, 8, ["customers", "teammembers", "officeSpaces", "lineManagers", "portfolioManagers", "projectLeads", "publicHolidayZones", "tdms", "commitments", "commitmentModel", "onSaved"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.CustomerList,
                            "item-title": "Name",
                            "item-value": "id",
                            modelValue: _ctx.selectedCustomer,
                            "onUpdate:modelValue": [
                              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCustomer) = $event)),
                              _ctx.onChangeCustomer
                            ],
                            label: "Customer",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            loading: _ctx.projectLoading,
                            "hide-details": "",
                            items: _ctx.ProjectList,
                            "item-title": "Name",
                            "item-value": "id",
                            modelValue: _ctx.selectedProject,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedProject) = $event)),
                            label: "Project",
                            outlined: "",
                            dense: ""
                          }, null, 8, ["loading", "items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.TeammemberList,
                            "item-title": "Name",
                            "item-value": "id",
                            modelValue: _ctx.selectedTeammember,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                            label: "Team Member",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.LinemanagerList,
                            "item-title": "Name",
                            "item-value": "id",
                            modelValue: _ctx.selectedLineManager,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedLineManager) = $event)),
                            label: "Line Manager",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.statusItems,
                            "item-title": "title",
                            "item-value": "value",
                            modelValue: _ctx.status,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.status) = $event)),
                            label: "Status",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "secondary",
                            class: "mr-4",
                            onClick: _ctx.clearFilters
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { left: "" }, {
                                default: _withCtx(() => _cache[21] || (_cache[21] = [
                                  _createTextVNode("mdi-close")
                                ])),
                                _: 1
                              }),
                              _cache[22] || (_cache[22] = _createTextVNode(" Clear "))
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _ctx.fetchCommitments
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { left: "" }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createTextVNode("mdi-check")
                                ])),
                                _: 1
                              }),
                              _cache[24] || (_cache[24] = _createTextVNode(" Filter "))
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_data_table_server, {
                    "item-key": "Id",
                    class: "mt-5",
                    items: _ctx.tempDesserts,
                    headers: _ctx.headers,
                    loading: _ctx.loading,
                    "custom-sort": _ctx.customSort,
                    "items-per-page": _ctx.numberOfPages,
                    "items-length": _ctx.totalItemsCount,
                    "onUpdate:options": _cache[8] || (_cache[8] = (event) => _ctx.tableOptions = event)
                  }, {
                    item: _withCtx(({ item }) => [
                      _createElementVNode("tr", {
                        class: _normalizeClass({ 'inactive-row': _ctx.checkCommitmentStatus(item.StatusV2)['index'] === 2 })
                      }, [
                        _createElementVNode("td", null, [
                          (_ctx.checkDateInRange(item.EndDate))
                            ? _withDirectives((_openBlock(), _createBlock(_component_v_badge, {
                                key: 0,
                                color: "error",
                                content: _ctx.remainingDaysTillCommitmentExpiry(item.EndDate),
                                inline: ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("strong", _hoisted_1, _toDisplayString(item.Name), 1)
                                ]),
                                _: 2
                              }, 1032, ["content"])), [
                                [_directive_tooltip, 'Remained Days']
                              ])
                            : (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(item.Name), 1))
                        ]),
                        _createElementVNode("td", null, _toDisplayString(_ctx.formatCustomers(item)), 1),
                        _createElementVNode("td", null, _toDisplayString(_ctx.formatProjects(item)), 1),
                        _createElementVNode("td", null, _toDisplayString(_ctx.formatTeammembers(item)), 1),
                        _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(item.StartDate)), 1),
                        _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(item.EndDate)), 1),
                        _createElementVNode("td", null, [
                          _createVNode(_component_v_chip, {
                            class: "ma-2",
                            color: _ctx.checkCommitmentStatus(item.StatusV2)['color'],
                            variant: "flat"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.checkCommitmentStatus(item.StatusV2)["value"]), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        _createElementVNode("td", null, [
                          _createVNode(_component_v_tooltip, {
                            text: _ctx.checkApprovalStatus(item.AdminApproval)['value']
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_icon, _mergeProps(props, {
                                color: _ctx.checkApprovalStatus(item.AdminApproval)['color']
                              }), {
                                default: _withCtx(() => _cache[25] || (_cache[25] = [
                                  _createTextVNode("mdi-radiobox-marked")
                                ])),
                                _: 2
                              }, 1040, ["color"])
                            ]),
                            _: 2
                          }, 1032, ["text"])
                        ]),
                        _createElementVNode("td", null, [
                          _createVNode(_component_v_tooltip, {
                            text: _ctx.checkApprovalStatus(item.CustomerApproval)['value']
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_icon, _mergeProps(props, {
                                color: _ctx.checkApprovalStatus(item.CustomerApproval)['color']
                              }), {
                                default: _withCtx(() => _cache[26] || (_cache[26] = [
                                  _createTextVNode("mdi-radiobox-marked")
                                ])),
                                _: 2
                              }, 1040, ["color"])
                            ]),
                            _: 2
                          }, 1032, ["text"])
                        ]),
                        _createElementVNode("td", null, [
                          _createVNode(_component_v_tooltip, {
                            text: _ctx.checkApprovalStatus(item.TeamMemberApproval)['value']
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_icon, _mergeProps(props, {
                                color: _ctx.checkApprovalStatus(item.TeamMemberApproval)['color']
                              }), {
                                default: _withCtx(() => _cache[27] || (_cache[27] = [
                                  _createTextVNode("mdi-radiobox-marked")
                                ])),
                                _: 2
                              }, 1040, ["color"])
                            ]),
                            _: 2
                          }, 1032, ["text"])
                        ]),
                        _createElementVNode("td", null, [
                          _createVNode(_component_v_menu, {
                            "offset-y": "",
                            location: "bottom",
                            "scroll-strategy": "close"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_btn, _mergeProps({
                                size: "small",
                                variant: "text",
                                color: "black"
                              }, props, { icon: "mdi-dots-vertical" }), null, 16)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.edit(item)),
                                    title: "Edit"
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.remove(item)),
                                    title: "Delete"
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.clone(item)),
                                    title: "Clone"
                                  }, null, 8, ["onClick"]),
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.showReview(item.id)),
                                    title: "Review"
                                  }, null, 8, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ], 2)
                    ]),
                    _: 1
                  }, 8, ["items", "headers", "loading", "custom-sort", "items-per-page", "items-length"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showConfirm,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showConfirm) = $event)),
        "max-width": "500",
        onKeydown: _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_confirm_dialog, {
            message: _ctx.confirmModel,
            onConfirm: _ctx.confirmRemove,
            onCancel: _ctx.cancelRemove
          }, null, 8, ["message", "onConfirm", "onCancel"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.ShowClone)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            modelValue: _ctx.ShowClone,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ShowClone) = $event)),
            persistent: "",
            "max-width": "500",
            onKeydown: _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.ShowClone = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_clone_commitment, {
                ref: "cloneCommitment",
                commitment: _ctx.cloningItem,
                onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.ShowClone = false)),
                onClone: _ctx.cloneCommitment
              }, null, 8, ["commitment", "onClone"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showReviewModal)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 1,
            modelValue: _ctx.showReviewModal,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showReviewModal) = $event)),
            persistent: "",
            "max-width": "1000",
            onKeydown: _cache[17] || (_cache[17] = _withKeys(($event: any) => (_ctx.ShowClone = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ReviewCommitment, {
                commitmentId: _ctx.selectedCommitmentId,
                onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeReview())),
                onUpdated: _cache[15] || (_cache[15] = ($event: any) => (_ctx.closeReview()))
              }, null, 8, ["commitmentId"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}