<template>
  <div>
    <search-list></search-list>
  </div>
</template>

<script lang="ts">
import SearchList from "./SearchList.vue";
import Vue, { defineComponent } from "vue";

export default defineComponent({
  components: {
    SearchList
  }
});
</script>
