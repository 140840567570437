<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col class="pb-5">
          <span class="headline">{{ selectedRow.TaskTitle }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn icon @click="closeDialog" variant="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-container>
        <v-row gutter="4">
          <v-col cols="12" class="d-flex align-center">
            <v-avatar class="mr-1" :color="getStatusColor(item.Status)" size="12"></v-avatar>
            <span class="task-status">{{ item.Status }}</span>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Job Opening</span>
            <p>{{ selectedRow.PositionTitle }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Hiring Manager</span>
            <p>{{ selectedRow.HiringManagerName }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Date</span>
            <p>{{ item.Date }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Priority</span>
            <p>{{ item.OrderNumber }}</p>
          </v-col>

          <v-col cols="12" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Description</span>
            <p>{{ item.Description }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <ChatBox title="Messages" :buttons="chatButtons" :messages="chatMessages" @sendMessage="sendMessage" :userId="getUserId" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  PositionApi,
  SendNotificationRequestModel,
  NotificationApi,
  PositionTaskDetailResponseModel,
} from "shared-components/src/services/openApi/api";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";
import ChatBox from "shared-components/src/components/ChatBox/ChatBox.vue";
import ChatBoxButton from "shared-components/src/models/ChatBoxButton";
import ChatBoxMessage from "shared-components/src/models/ChatBoxMessage";
import { NotificationModel } from "shared-components/src/services/openApi";
import store from "@/store";

const positionApi = new PositionApi();
const notificationApi = new NotificationApi();
export default defineComponent({
  components: { TextAreaField, ChatBox },
  props: {
    item: {
      type: Object as () => PositionTaskDetailResponseModel,
      default: () => ({} as PositionTaskDetailResponseModel),
    },
    selectedRow: {
      type: Object as () => PositionTaskDetailResponseModel,
      default: null,
    },
    isHiringmanager: {
      type: Boolean,
    },
  },
  mounted() {
    this.getcomments();
  },
  data() {
    return {
      message: "",
      commentData: {} as SendNotificationRequestModel,
      comments: [] as Array<{ id: any; Text: string }>,
      chatMessages: [] as ChatBoxMessage[],
      chatButtons: [
        {
          Icon: "mdi-send-circle",
          Key: "sendCM",
          Text: "Send",
        },
      ] as ChatBoxButton[],
    };
  },
  computed: {
    getUserId() {
      return store.state.userInfo.id;
    },
  },
  methods: {
    getStatusColor(status: string | undefined) {
      if (status === "New") {
        return "blue";
      } else if (status === "Active") {
        return "green";
      } else if (status === "Resolved") {
        return "orange";
      } else if (status === "Closed") {
        return "red";
      } else if (status === "Archived") {
        return "grey";
      } else {
        return "default";
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    async getcomments() {
      try {
        const response = await notificationApi.getPositionTaskNotifications(this.item.Id as string);
        if (response.data && Array.isArray(response.data)) {
          this.comments = response.data.map((comment: any) => ({
            id: comment.id,
            Text: comment.Text,
          }));
          this.chatMessages = response.data.map((notification: NotificationModel) => this.generateChatMessageItem(notification));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async sendMessage(event: any) {
      if (event.Message) {
        this.message = event.Message;
        this.commentData = { message: this.message };

        try {
          const response = await positionApi.sendPositionTaskNotification(this.item.Id as string, this.commentData);
          const newComment = { id: response.data.id, Text: this.message };
          this.comments.push(newComment);
          this.chatMessages.push(this.generateChatMessageItem(response.data));
        } catch (error) {
          console.error("Failed to save comment:", error);
        }
        setTimeout(() => {
          const messageBodyElement = this.$refs.messageBody as HTMLElement | undefined;
          if (messageBodyElement) {
            const lastChildElement = messageBodyElement.lastElementChild;
            lastChildElement?.scrollIntoView({ behavior: "smooth" });
          } else {
            console.error("messageBody ref is undefined");
          }
        }, 200);
      }
    },
    generateChatMessageItem(notification: NotificationModel) {
      return {
        CreatedOn: notification.CreationDate ?? "",
        CreatorUserId: notification.CreatorUserId,
        Message: notification.Text,
        SentBy: notification.CreatorUser?.UserFullName,
        ReceivedBy: notification.Users && notification.Users.length > 0 ? notification.Users.map((item: any) => item.UserFullName) : [],
      } as ChatBoxMessage;
    },
    isOwner(message: NotificationModel) {
      if (message.CreatorUserId == store.state.userInfo.id) {
        return true;
      } else {
        return false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
@import "node_modules/shared-components/assets/style/color.scss";
@import "node_modules/shared-components/assets/style/style.scss";

.headline {
  font-weight: $bold-l;
  font-size: $size-m;
}

.task-status {
  font-weight: $bold-l;
}

.task-title {
  font-weight: $bold-l;
  color: $text_color;
}
.task-section {
  box-shadow: 0 2px 8px $c_platinum;
  background-color: $c_white_smoke;
}
</style>
