<template>
  <div><activity-watch-report :vmId="vmId" @Close="goBack" /></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue";

export default defineComponent({
  props:["vmId"],
  components: {
    ActivityWatchReport,
  },
  methods: {
    goBack(){
      this.$emit("close");
    }
  }
});
</script>
