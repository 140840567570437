import ManagerModel from "shared-components/src/models/ManagerModel";
import ApiService from "./ApiService";

export default class TDMService {
  public static async getById(id: string): Promise<ManagerModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tdm/${id}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as ManagerModel);
          } else {
            const item = result.data as ManagerModel;
            resolve(item);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getList(): Promise<ManagerModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/tdm", "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { ...item } as ManagerModel;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: ManagerModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/tdm", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(model: ManagerModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put("/tdm", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
