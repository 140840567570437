import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "week-parent" }
const _hoisted_2 = { class: "sum-hour" }
const _hoisted_3 = {
  class: "week-days-table",
  cellspacing: "0"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.removeDecimals(_ctx.Week.Hours)), 1),
    _createElementVNode("div", null, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Week.Days, (i, index) => {
              return (_openBlock(), _createElementBlock("th", {
                key: index + 100
              }, _toDisplayString(i.DayOfWeek), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Week.Days, (i, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: index + 1000,
                class: "time-span"
              }, _toDisplayString(i.TimesheetHuors), 1))
            }), 128))
          ]),
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Week.Days, (i, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: index + 10000,
                class: "time-span"
              }, [
                (i.TimesheetHuors)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      (i.IsLocked)
                        ? (_openBlock(), _createBlock(_component_v_icon, {
                            key: 0,
                            color: "success",
                            "x-small": ""
                          }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("mdi-lock")
                            ])),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_v_icon, {
                            key: 1,
                            color: "error",
                            "x-small": ""
                          }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode("mdi-lock-open")
                            ])),
                            _: 1
                          }))
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
              ]))
            }), 128))
          ]),
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Week.Days, (i, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: index + 100000,
                class: "time-span adjustment"
              }, _toDisplayString(i.AdjustmentHours), 1))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}