import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "operation text-center" }
const _hoisted_2 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_LoanRegister = _resolveComponent("LoanRegister")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_LoanApproval = _resolveComponent("LoanApproval")!
  const _component_LoanPayment = _resolveComponent("LoanPayment")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _cache[19] || (_cache[19] = _createTextVNode(" Loan List ")),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "primary",
                class: "mr-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = true))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("mdi-plus")
                    ])),
                    _: 1
                  }),
                  _cache[18] || (_cache[18] = _createTextVNode(" Loan Request"))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                class: "filters",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ComboBoxField, {
                    loading: _ctx.tmLoading,
                    modelValue: _ctx.selectedTeammember,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                    items: _ctx.teammembers,
                    firstInactiveId: _ctx.firstInactiveTeammemberId,
                    "item-title": "FullName",
                    "item-value": "Id",
                    outlined: "",
                    dense: "",
                    label: "Team Member",
                    placeholder: "Select Team Member"
                  }, null, 8, ["loading", "modelValue", "items", "firstInactiveId"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                class: "filters",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ComboBoxField, {
                    modelValue: _ctx.selectedStatus,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedStatus) = $event)),
                    items: _ctx.statuses,
                    "item-title": "FullName",
                    "item-value": "Id",
                    outlined: "",
                    dense: "",
                    label: "Status",
                    placeholder: "Select Status"
                  }, null, 8, ["modelValue", "items"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                class: "filters",
                md: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "secondary mt-9",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearFilters()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      }),
                      _cache[21] || (_cache[21] = _createTextVNode(" Clear "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_data_table_server, {
            items: _ctx.items,
            headers: _ctx.headers,
            loading: _ctx.loading,
            "show-expand": "",
            page: _ctx.page,
            "items-per-page": _ctx.numberOfPages,
            "onUpdate:options": _cache[4] || (_cache[4] = (event) => _ctx.tableOptions = event),
            "items-length": _ctx.totalItemsCount
          }, {
            "item.CreatedAt": _withCtx(({ item }) => [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formatDate(item.CreatedDate)), 1)
            ]),
            "item.ProposedPaymentDate": _withCtx(({ item }) => [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formatDate(item.ProposedPaymentDate)), 1)
            ]),
            "item.TeamMember": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.TeamMemberName), 1)
            ]),
            "item.Amount": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatNumber(item.Amount)), 1)
            ]),
            "item.UnPaidAmount": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatNumber(item.UnPaidAmount)), 1)
            ]),
            "item.Installment": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.Installment) + " [" + _toDisplayString(_ctx.formatNumber(_ctx.getInstallmentAmount(item))) + "]", 1)
            ]),
            "item.PaymentReference": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.PaymentReference), 1)
            ]),
            "item.StartPaymentDate": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.StartPaymentDate)), 1)
            ]),
            "item.Description": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.Description + " [" + item.Reference + "]"), 1)
            ]),
            "item.Status": _withCtx(({ item }) => [
              (item.Status == _ctx.loanStatuses.Pending)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 0,
                    class: "ma-2",
                    color: "orange"
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode(" Pending ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (item.Status == _ctx.loanStatuses.Approved)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 1,
                    class: "ma-2",
                    color: "success"
                  }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode(" Approved ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (item.Status == _ctx.loanStatuses.Rejected)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 2,
                    class: "ma-2",
                    color: "error"
                  }, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode(" Rejected ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (item.Status == _ctx.loanStatuses.FullyPaid)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 3,
                    class: "ma-2",
                    color: "green"
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode(" Fully Re-Paid ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            "item.Actions": _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_1, [
                (_ctx.canBeDeleted(item) || _ctx.canBeApproved(item) || _ctx.canBePaid(item))
                  ? (_openBlock(), _createBlock(_component_v_menu, {
                      key: 0,
                      "offset-y": "",
                      left: "",
                      attach: ""
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({
                          "x-small": "",
                          icon: "",
                          color: "black"
                        }, props), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                _createTextVNode("mdi-dots-vertical")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (_ctx.canBeDeleted(item))
                              ? (_openBlock(), _createBlock(_component_v_list_item, {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.confirmDelete(item)),
                                  "prepend-icon": "mdi-delete",
                                  title: "Delete"
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.canBeApproved(item))
                              ? (_openBlock(), _createBlock(_component_v_list_item, {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.edit(item)),
                                  "prepend-icon": "mdi-pencil",
                                  title: "Approve / Reject"
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.canBePaid(item))
                              ? (_openBlock(), _createBlock(_component_v_list_item, {
                                  key: 2,
                                  onClick: ($event: any) => (_ctx.enterPayment(item)),
                                  "prepend-icon": "mdi-pencil",
                                  title: "Enter Payment"
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ]),
            "expanded-row": _withCtx(({ columns, item }) => [
              (item.PaymentOverview && item.PaymentOverview.length > 0)
                ? (_openBlock(), _createElementBlock("td", {
                    key: 0,
                    colspan: columns.length,
                    class: "custom-timesheet-row"
                  }, [
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "2" }, {
                          default: _withCtx(() => _cache[27] || (_cache[27] = [
                            _createTextVNode(" Amount ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode(" Description ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { md: "4" }, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode(" Payment Date ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.PaymentOverview, (overviewItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: overviewItem.Description,
                        class: _normalizeClass([`timesheet-row`, overviewItem.IsPaid ? 'b-light-green text-white' : ''])
                      }, [
                        _createVNode(_component_v_row, { class: "ma-0" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { md: "2" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatNumber(overviewItem.Amount)), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { md: "6" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(overviewItem.Description), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { md: "4" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatDate(overviewItem.PaidDate)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ], 2))
                    }), 128))
                  ], 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"]),
          (_ctx.totalAmountAUD)
            ? (_openBlock(), _createBlock(_component_v_alert, {
                key: 0,
                type: "info",
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _cache[30] || (_cache[30] = _createElementVNode("strong", null, "Amount AUD: ", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.formatNumber(_ctx.totalAmountAUD)) + " ", 1),
                  _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                  _cache[32] || (_cache[32] = _createElementVNode("strong", null, "Remaining Amount AUD: ", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.formatNumber(_ctx.totalRemainingAmountAUD)), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        width: "660",
        modelValue: _ctx.showDialog,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDialog) = $event)),
        onKeypress: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.showDialog = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          (_ctx.showDialog)
            ? (_openBlock(), _createBlock(_component_LoanRegister, {
                key: 0,
                id: _ctx.selectedId,
                loading: _ctx.loading,
                teammembers: _ctx.teammembers,
                onCancel: _cache[5] || (_cache[5] = ($event: any) => {
          _ctx.selectedId = null;
          _ctx.showDialog = false;
        }),
                onUpsert: _ctx.upsert
              }, null, 8, ["id", "loading", "teammembers", "onUpsert"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        width: "600",
        modelValue: _ctx.showStatusDialog,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showStatusDialog) = $event)),
        onKeypress: _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.showStatusDialog = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          (_ctx.showStatusDialog)
            ? (_openBlock(), _createBlock(_component_LoanApproval, {
                key: 0,
                id: _ctx.selectedId,
                ProposedPaymentDate: _ctx.selectedProposedStartDate,
                value: _ctx.selectedLoanApprovalModel,
                loading: _ctx.loading,
                onCancel: _cache[8] || (_cache[8] = ($event: any) => {
          _ctx.selectedId = null;
          _ctx.showStatusDialog = false;
        }),
                onApproveLoan: _ctx.approveLoan
              }, null, 8, ["id", "ProposedPaymentDate", "value", "loading", "onApproveLoan"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        width: "600",
        modelValue: _ctx.showPaymentDialog,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showPaymentDialog) = $event)),
        onKeypress: _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.showPaymentDialog = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          (_ctx.showPaymentDialog)
            ? (_openBlock(), _createBlock(_component_LoanPayment, {
                key: 0,
                id: _ctx.selectedId,
                ProposedPaymentDate: _ctx.selectedProposedStartDate,
                value: _ctx.selectedLoanApprovalModel,
                loading: _ctx.loading,
                onCancel: _cache[11] || (_cache[11] = ($event: any) => {
          _ctx.selectedId = null;
          _ctx.showPaymentDialog = false;
        }),
                onPayLoan: _ctx.payLoan
              }, null, 8, ["id", "ProposedPaymentDate", "value", "loading", "onPayLoan"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        persistent: "",
        modelValue: _ctx.showDeleteConfirmDialog,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showDeleteConfirmDialog) = $event)),
        onKeydown: _cache[16] || (_cache[16] = _withKeys(($event: any) => (_ctx.showDeleteConfirmDialog = false), ["esc"])),
        "max-width": "400px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_confirm_dialog, {
            loading: _ctx.loading,
            onConfirm: _ctx.deleteItem,
            onCancel: _cache[14] || (_cache[14] = ($event: any) => {
          _ctx.showDeleteConfirmDialog = false;
          _ctx.loading = false;
        }),
            message: _ctx.confirmModel
          }, null, 8, ["loading", "onConfirm", "message"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}