import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "pb-0" }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createTextVNode(" Audit Trail List ")),
                  _createVNode(_component_v_divider, {
                    class: "mx-4",
                    inset: "",
                    vertical: ""
                  }),
                  _createVNode(_component_v_spacer)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        class: "filters pb-0",
                        cols: "12",
                        md: "4",
                        sm: "3",
                        xs: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ComboBoxField, {
                            items: _ctx.users,
                            "item-title": "displayName",
                            "item-value": "id",
                            modelValue: _ctx.selectedUsers,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUsers) = $event)),
                            label: "Users",
                            loading: _ctx.userLoading,
                            multiple: "",
                            outlined: "",
                            dense: ""
                          }, null, 8, ["items", "modelValue", "loading"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        class: "filters pb-0",
                        cols: "12",
                        md: "3",
                        sm: "3",
                        xs: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DateInputField, {
                            "first-day-of-week": "1",
                            modelValue: _ctx.startDate,
                            "onUpdate:modelValue": [
                              _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startDate) = $event)),
                              _cache[2] || (_cache[2] = ($event: any) => (_ctx.startDatePickerMenu = false))
                            ],
                            label: "From Date",
                            "hide-actions": "",
                            placeholder: "Select Date",
                            "prepend-icon": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        class: "filters pb-0",
                        cols: "12",
                        md: "3",
                        sm: "3",
                        xs: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DateInputField, {
                            "first-day-of-week": "1",
                            modelValue: _ctx.endDate,
                            "onUpdate:modelValue": [
                              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.endDate) = $event)),
                              _cache[4] || (_cache[4] = ($event: any) => (_ctx.endDatePickerMenu = false))
                            ],
                            "hide-actions": "",
                            placeholder: "Select Date",
                            "prepend-icon": "",
                            label: "To Date"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        class: "filters pb-0 mt-9",
                        md: "1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            class: "secondary",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearFilters()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("mdi-close")
                                ])),
                                _: 1
                              }),
                              _cache[8] || (_cache[8] = _createTextVNode(" Clear "))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    class: "pt-0",
                    items: _ctx.filteredAuditTrail,
                    loading: _ctx.auditLoading,
                    headers: _ctx.headers
                  }, {
                    "item.DateTime": _withCtx(({ item }) => [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.getDate(item.DateTime)), 1)
                    ]),
                    "item.Event": _withCtx(({ item }) => [
                      _createElementVNode("span", null, _toDisplayString(item.Event), 1)
                    ]),
                    "item.RecordType": _withCtx(({ item }) => [
                      _createElementVNode("span", null, _toDisplayString(item.RecordType.Type), 1)
                    ]),
                    "item.UserId": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.getUserName(item.UserId)), 1)
                    ]),
                    _: 1
                  }, 8, ["items", "loading", "headers"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}