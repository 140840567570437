import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_CustomerInvoiceForm = _resolveComponent("CustomerInvoiceForm")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_SetPayment = _resolveComponent("SetPayment")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, { class: "overflow-visible" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _cache[10] || (_cache[10] = _createTextVNode(" Revenue ")),
            _createVNode(_component_v_divider, {
              class: "mx-4",
              inset: "",
              vertical: ""
            }),
            _createVNode(_component_v_spacer)
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "filters ml-2",
              md: "3"
            }, {
              default: _withCtx(() => [
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "input-label" }, "Date", -1)),
                _createVNode(_component_VueDatePicker, {
                  modelValue: _ctx.selectedMonth,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMonth) = $event)),
                  "month-picker": "",
                  "auto-apply": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "filters",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SelectField, {
                  items: _ctx.customers,
                  "item-title": "text",
                  "item-value": "id",
                  modelValue: _ctx.model.selectedCustomer,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.selectedCustomer) = $event)),
                  label: "Customer",
                  placeholder: "Select Customer",
                  outlined: ""
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "filters mt-11",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("mdi-sync")
                      ])),
                      _: 1
                    }),
                    _cache[13] || (_cache[13] = _createTextVNode(" Process "))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  color: "secondary",
                  class: "ml-2",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearFilters()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    }),
                    _cache[15] || (_cache[15] = _createTextVNode(" Clear "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.items,
              headers: _ctx.headers,
              loading: _ctx.loading,
              "custom-sort": _ctx.customSort,
              "items-per-page": -1,
              "hide-default-footer": ""
            }, {
              "item.Index": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_1))
                  : (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(item.Index), 1))
              ]),
              "item.customerName": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "Total Payments"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.customerName), 1))
              ]),
              "item.fromDate": _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getMonthName(item)), 1)
              ]),
              "item.amount": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_5, _toDisplayString(_ctx.getTotalAudOfReport(_ctx.items)), 1))
                  : (item.customerInvoice && item.customerInvoice.audTotalAmount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.moneyFormat(item.customerInvoice.audTotalAmount)), 1))
                    : _createCommentVNode("", true)
              ]),
              "item.paymentAmount": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_7, _toDisplayString(_ctx.getTotalAudPaymentOfReport(_ctx.items)), 1))
                  : (item.customerInvoice && item.customerInvoice.totalPaymentAmount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.moneyFormat(item.customerInvoice.totalPaymentAmount)), 1))
                    : _createCommentVNode("", true)
              ]),
              "item.Action": _withCtx(({ item }) => [
                (item.customerInvoice)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createVNode(_component_v_icon, {
                        color: "orange",
                        onClick: ($event: any) => (_ctx.setReferece(item))
                      }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("mdi-information")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]),
                      (item.customerInvoice)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            innerHTML: _ctx.getPaymentRefAndDateDescription(item.customerInvoice.payments)
                          }, null, 8, _hoisted_10))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              "item.customerInvoice": _withCtx(({ item }) => [
                (item.customerInvoice)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      _createVNode(_component_v_btn, {
                        class: "primary",
                        small: "",
                        onClick: ($event: any) => (_ctx.showCustomerInvoice(item.customerInvoice))
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode(" Invoice Details ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : (!item.isSum)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                        _createVNode(_component_v_icon, {
                          color: "green",
                          onClick: ($event: any) => (_ctx.newCustomerInvoice(item))
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("mdi-plus-circle")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["items", "headers", "loading", "custom-sort"])
          ]),
          _: 1
        }),
        (_ctx.showCustomerInvoiceDialog)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 0,
              persistent: "",
              modelValue: _ctx.showCustomerInvoiceDialog,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showCustomerInvoiceDialog) = $event)),
              onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showCustomerInvoiceDialog = false), ["esc"]))
            }, {
              default: _withCtx(() => [
                (_ctx.selectedCustomerInvoice)
                  ? (_openBlock(), _createBlock(_component_CustomerInvoiceForm, {
                      key: 0,
                      invoice: _ctx.selectedCustomerInvoice,
                      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCustomerInvoiceDialog = false)),
                      onConfirmed: _ctx.showCustomerInvoiceConfirmed
                    }, null, 8, ["invoice", "onConfirmed"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.showSetRefrence)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 1,
              persistent: "",
              modelValue: _ctx.showSetRefrence,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showSetRefrence) = $event)),
              "max-width": "1200",
              onKeydown: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.showSetRefrence = false), ["esc"]))
            }, {
              default: _withCtx(() => [
                (_ctx.selectedCustomerInvoice)
                  ? (_openBlock(), _createBlock(_component_SetPayment, {
                      key: 0,
                      selectedInvoice: _ctx.selectedCustomerInvoice,
                      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showSetRefrence = false)),
                      onConfirmed: _ctx.setPaymentConfirmed
                    }, null, 8, ["selectedInvoice", "onConfirmed"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}