import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_virtual = _resolveComponent("v-data-table-virtual")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_v_card, {
    title: "Skills",
    flat: ""
  }, {
    text: _withCtx(() => [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
        label: "Search",
        placeholder: "Search",
        "prepend-inner-icon": "mdi-magnify",
        variant: "outlined",
        "hide-details": "",
        "single-line": "",
        clearable: ""
      }, null, 8, ["modelValue"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_data_table_virtual, {
        disable: _ctx.tableLoading,
        loading: _ctx.tableLoading,
        headers: _ctx.headers,
        items: _ctx.skills,
        search: _ctx.searchQuery,
        "multi-sort": ""
      }, {
        "item.actions": _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createVNode(_component_v_btn, {
              class: "text-uppercase",
              size: "small",
              color: item.IsRequested ? 'red' : 'green',
              text: item.IsRequested ? 'Requested' : 'Approved',
              disabled: item.IsRequested ? false : true,
              onClick: ($event: any) => (_ctx.approveSkilByAdmin(item))
            }, null, 8, ["color", "text", "disabled", "onClick"]), [
              [_directive_tooltip, item.IsRequested ? 'Approve requested Skill' : 'Already approved']
            ])
          ])
        ]),
        _: 1
      }, 8, ["disable", "loading", "headers", "items", "search"])
    ]),
    _: 1
  }))
}