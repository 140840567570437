<template>
  <contribution-report
    ref="cntbnReport"
    :customers="customers"
    :projects="projects"
    :timesheets="timesheets"
    @createReport="createReport"
    @customerSelected="customerSelected"
  ></contribution-report>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ContributionReport from "shared-components/src/components/Reports/Contribution/ContributionReport.vue";
import Project from "shared-components/src/models/Project";
import ProjectService from "shared-components/src/services/ProjectService";
import TimesheetService from "shared-components/src/services/TimesheetService";
import ContributionReportService from "shared-components/src/services/ContributionReportService";
import Timesheet from "shared-components/src/models/Timesheet";
import Commitment from "shared-components/src/models/Commitment";

export default defineComponent({
  components: {
    ContributionReport,
  },
  data() {
    return {
      customers: [] as Array<{ id: string; text: string }>,
      projects: [] as Project[],
      timesheets: [] as Timesheet[],
      allCommitments: [] as Commitment[],
    };
  },
  methods: {
    async customerSelected($customerId: string) {
      if ($customerId) {
        this.projects = await ProjectService.getByCustomerId($customerId);
      }
    },
    async createReport($model: { customerId: string; projectIds: string[]; dateRange: Date[] }) {
      const teammemberIds = [] as string[];
      const commitments = this.allCommitments.filter((cmtmnt) => {
        let retVal = $model.projectIds.findIndex((prjId) => cmtmnt.ProjectIds.indexOf(prjId) > -1) > -1;
        if (retVal) {
          if (!cmtmnt.StartDate || cmtmnt.StartDate > $model.dateRange[1] || (cmtmnt.EndDate || $model.dateRange[0]) < $model.dateRange[0]) {
            retVal = false;
          } else {
            retVal = true;
          }
        }
        return cmtmnt;
      });
      commitments.map((cmtmn) => cmtmn.TeamMemberIds).forEach((ids) => teammemberIds.push(...ids));
      if (teammemberIds && teammemberIds.length > 0) {
        const tmTimesheets = await TimesheetService.getListInDateRange("", $model.dateRange[0], $model.dateRange[1]);
        this.timesheets = tmTimesheets;
        if (tmTimesheets && tmTimesheets.length > 0) {
          this.timesheets = tmTimesheets.filter(
            (ts: any) => ts.TeamMemberId && teammemberIds.includes(ts.TeamMemberId) && commitments.findIndex((c) => c.id === ts.CommitmentId) > -1
          );
        }
      }
      (this.$refs.cntbnReport as any).showList();
    },
  },
  async mounted() {
    var datas = await ContributionReportService.getDatas();
    if (datas) {
      this.customers = datas.Customers.filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim())).map(
        (item: any) => {
          return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
        }
      );
      this.allCommitments = datas.Commitments;
    }
  },
});
</script>
