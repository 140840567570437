import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "d-flex justify-end" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: _ctx.closeDialog,
            variant: "text"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.taskTitle), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_form, {
            class: "form-inputs",
            ref: "frmTaskTemplate"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { class: "py-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6",
                        xs: "12",
                        sm: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextField, {
                            rules: [_ctx.validations.required],
                            id: "Task",
                            clearable: "",
                            label: "Title",
                            placeholder: "Title",
                            showRequired: "",
                            outlined: "",
                            dense: "",
                            "item-title": "Name",
                            "item-value": "Id",
                            modelValue: _ctx.newTask.Name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTask.Name) = $event))
                          }, null, 8, ["rules", "modelValue"])
                        ]),
                        _: 1
                      }),
                      (_ctx.isEditing)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "12",
                            md: "6",
                            xs: "12",
                            sm: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AutoCompleteField, {
                                rules: [_ctx.validations.required],
                                readonly: _ctx.isHiringmanager,
                                modelValue: _ctx.selectedStatus,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedStatus) = $event)),
                                items: _ctx.suggestedStatuses,
                                dense: "",
                                outlined: "",
                                label: "Status",
                                showRequired: "",
                                placeholder: "Status"
                              }, null, 8, ["rules", "readonly", "modelValue", "items"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "12",
                        xs: "12",
                        sm: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextAreaField, {
                            rules: [_ctx.validations.required],
                            readonly: _ctx.isHiringmanager,
                            modelValue: _ctx.newTask.Description,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newTask.Description) = $event)),
                            dense: "",
                            outlined: "",
                            label: "Description",
                            showRequired: "",
                            placeholder: "Description"
                          }, null, 8, ["rules", "readonly", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "12",
                        xs: "12",
                        sm: "12",
                        class: "add-button"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, { onClick: _ctx.closeDialog }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { left: "" }, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("mdi-close")
                                ])),
                                _: 1
                              }),
                              _cache[5] || (_cache[5] = _createTextVNode(" Cancel "))
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            onClick: _ctx.AddTaskPosition
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { left: "" }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("mdi-check")
                                ])),
                                _: 1
                              }),
                              _createTextVNode(" " + _toDisplayString(_ctx.isEditing ? "Update" : "Add"), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}